import {
	computed, observable, toJS, makeObservable,
} from 'mobx';

class LegendBuilder {
	constructor(name, legendData, form) {
		makeObservable(this, {
			reactPropsMap: observable,
			controlId: computed,
			reactProps: computed,
		});

		this.name = name;
		this.legendData = legendData;
		this.form = form;
		this.reactPropsMap = observable.map({
			'id': this.legendId,
			'key': this.legendId,
			'className': this.legendId,
			'data-qa': this.legendId,
			...this.legendData.reactProps,
		});
	}

	get legendId() {
		// toJS needed for IE.
		return `${this.name}-legend-${toJS(this.form.id)}`;
	}

	reactPropsMap = null;

	get controlId() {
		return this?.form?.fields?.[this?.name]?.control?.reactPropsMap?.get?.('id') || null;
	}

	get reactProps() {
		const obj = Object.fromEntries(toJS(this.reactPropsMap));

		if (this.controlId) {
			return {
				htmlFor: this.controlId,
				...obj,
			};
		}
		return obj;
	}

	static BUILDER_KEY = 'legend'
}

export { LegendBuilder };
