import { TopMenu } from '~/global/top-menu/Models/Menu.model';
import { SubMenuFactory } from '~/global/top-menu/Models/SubMenu.model';
import { SubMenuItem } from '~/global/top-menu/Models/SubMenuItem.model';
import { ContentPromoFactory } from '~/global/top-menu/Models/ContentPromo.model';

export const TopMenuProcessor = {
	processData: (data: any) => {
		return data.map((menu: any) => {
			const {
				children = [],
				contentPromos = [],
				...menuData
			} = menu;

			const subMenus = children.map((subMenu: any) => {
				const {
					children: subMenuChildren = [],
					...subMenuData
				} = subMenu;

				const subMenuItems = subMenuChildren.map((subMenuChildData: any) => {
					return new SubMenuItem(subMenuChildData);
				});

				return SubMenuFactory.create(subMenuData, subMenuItems);
			});

			const menuContentPromos = contentPromos.map((contentPromo: any) => {
				return ContentPromoFactory.create(contentPromo);
			});

			return new TopMenu(menuData, subMenus, menuContentPromos);
		});
	},
};
