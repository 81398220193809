import { isOnServer } from '~/global/global.constants';

interface Options {
	title?: string
}

export const apiConsoleLogging = (enabledFeatures: string[], data: any, options: Options = {}) => {
	const config = { title: '', ...options };
	const title = config.title.length ? `${config.title} — ` : '';
	const consoleStyles = {
		'background': '#666',
		'color': '#fff',
		'padding': '3px 10px',
		'border-radius': '10px',
	};
	const stringifyStyles = (styles: any) => {
		return Object.entries(styles).reduce((prevValue, [key, val]) => {
			return `${prevValue} ${key}: ${val};`;
		}, '').trim();
	};
	const logTitle = `${title}NextJS server data`;
	const errorLogTitle = `Failed to log ${logTitle}`;
	const enableLoggingClient = enabledFeatures.includes('NEXTJS_API_LOGGING_CLIENT') && !isOnServer;
	const enableLoggingServer = enabledFeatures.includes('NEXTJS_API_LOGGING_SERVER') && isOnServer;
	const enableLogging = enableLoggingClient || enableLoggingServer;

	try {
		if (!data || !enableLogging) {
			return;
		}
		if (enableLoggingServer) {
			console.log(`🪵 BEGIN ${logTitle} 🪵`);
			console.log(JSON.stringify(data, undefined, 4));
			console.log(`🪵 END ${logTitle} 🪵`);
		} else if (enableLoggingClient) {
			console.groupCollapsed(`%c${logTitle}`, stringifyStyles(consoleStyles));
			console.log(data);
			console.groupEnd();
		}
	} catch (error) {
		if (enableLoggingServer) {
			console.log(errorLogTitle);
		} else if (enableLoggingClient) {
			consoleStyles.background = '#900';
			console.log(`%c${errorLogTitle}`, stringifyStyles(consoleStyles));
		}
	}
};
