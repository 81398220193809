import React from 'react';
import { observer } from 'mobx-react';

export const Validation = observer(({ field, showValidationMessage = true }) => {
	if (!field?.plugins?.validationMessage?.errorMessage || !showValidationMessage) {
		return null;
	}
	const { validationMessage } = field.plugins;

	const {
		key,
		...validationMessageProps
	} = validationMessage.reactProps;

	const theProps = {
		...validationMessageProps,
		'data-qa': validationMessage.reactProps.id,
		...(validationMessage.isServerError) && {
			'data-is-server-error': '',
		},
	};
	const ErrorMessage = typeof validationMessage.errorMessage === 'function'
		? validationMessage.errorMessage : null;

	return (
		<div key={key} {...theProps}>
			{
				ErrorMessage
					?
					<ErrorMessage />
					:
					validationMessage.errorMessage
			}
		</div>
	);
});
