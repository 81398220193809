import React from 'react';

interface IProps {
	subscriptionFrequencyText: string;
	subscriptionTypeText: string;
}

export const SubscriptionCheckboxLabel = (props: IProps) => {
	return (
		<span className={'tw-font-semibold sm:tw-leading-snug'}>
			{props.subscriptionTypeText} <span className={'tw-font-normal'}> — {props.subscriptionFrequencyText}</span>
		</span>
	);
};
