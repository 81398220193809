export enum DIRECTION {
	NEXT = 'NEXT',
	PREV = 'PREV',
}

export enum DISPLAY_ON {
	CART = 'CART',
	SITEWIDE = 'SITEWIDE',
}

export enum INPUT_TYPE {
	MOUSE = 'mouse',
	POINTER = 'pointer',
	TOUCH = 'touch',
}

export enum MOVE_TYPE {
	FREE_SCROLL = 'freeScroll',
	SNAP = 'snap',
	STRICT = 'strict',
}

export enum TEMPLATE {
	BANNER_BLACK = 'BANNER_BLACK',
	BANNER_RED = 'BANNER_RED',
}

export const SITE_MARKETING_MESSAGE_NAME_COOKIE = 'rnb.SITE_MARKETING_MESSAGE_NAME';
