export function debounce(func, wait, immediate) {
	let timeout;

	const debouncedFunction = function () {
		const context = this;
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		}, wait);
		if (immediate && !timeout) func.apply(context, args);
	};

	debouncedFunction.cancel = function () {
		clearTimeout(timeout);
	};

	return debouncedFunction;
}

// EXPERIMENTAL
export function debounceImmediateExperimental(func, wait, immediate) {
	let timeout;
	let immediateTimeout;
	let callImmediately = immediate;
	return function () {
		const context = this;
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;
		const callNow = callImmediately && !timeout;

		clearTimeout(timeout);
		timeout = setTimeout(function () {
			timeout = null;
			if (!callImmediately) {
				func.apply(context, args);
				if (immediate) {
					clearTimeout(immediateTimeout);
					immediateTimeout = setTimeout(function () {
						callImmediately = true;
					}, wait);
				}
			}
		}, wait);
		if (callNow) {
			func.apply(context, args);
			callImmediately = false;
		}
	};
}
