import { FormSubmitEvent } from '~/tracking/form-submit-event/Models/FormSubmitEvent.tracking';
import { isEngage } from '~/global/global.constants';
import { IFormSubmitJSON } from '~/tracking/form-submit-event/Interfaces/IFormSubmitEvent.tracking';

export class FormSubmitEventTrackingStore {
	tsWindow = window as any;

	trackFormSubmit(id = '', allowInEngage = false) {
		if ((isEngage && !allowInEngage) || !this.tsWindow.eventDataLayer) {
			return;
		}

		const formSubmitTrackingModel = new FormSubmitEvent(
			id,
		);

		this.pushToEventDataLayer(formSubmitTrackingModel.trackingJson);
	}

	pushToEventDataLayer(json: IFormSubmitJSON) {
		this.tsWindow.eventDataLayer.push(json);
	}
}
