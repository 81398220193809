import { observable, makeObservable, action } from 'mobx';

export class AccountNudgeModel {
	showAccountNudge: boolean = false;

	constructor() {
		makeObservable(this, {
			showAccountNudge: observable,
			setShowAccountNudge: action.bound,
		});

		this.setShowAccountNudge(false);
	}

	setShowAccountNudge(showAccountNudge: boolean) {
		this.showAccountNudge = showAccountNudge;
	}
}
