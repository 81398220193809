'use client';

import { useEffect } from 'react';

import { loggedOutAction } from '~/account/sign-in/Actions/loggedOut.action';
import { redirectToLogin } from '~/util/redirect';

export const useLogoutRedirect = (onAuthorizedPage = false) => {
	useEffect(() => {
		const leaveAuthorizedPage = () => {
			if (!onAuthorizedPage) {
				return;
			}
			redirectToLogin();
		};
		loggedOutAction?.add(leaveAuthorizedPage);
		return () => {
			loggedOutAction?.remove(leaveAuthorizedPage);
		};
	}, []);
};
