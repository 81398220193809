import { action } from 'mobx';
import { debounce } from '~/util/debounce';

import { FieldValidationMessage } from '~/util/formz/plugins/formValidator/FieldValidationMessage';

const addFieldForValidation = (field, name, form, validatorPlugin) => {
	const { control } = field;
	const settings = field?.settings || {};

	if (settings.onBlurDebounceWait === undefined) {
		settings.onBlurDebounceWait = 0;
	}
	if (control) {
		// blur handler
		control.addOrWrapControlEventHandler('onBlur', action(`${name} onBlur`, debounce(() => {
			if (settings.lazyValidation) {
				settings.lazyValidation = false;
			} else {
				validatorPlugin.fieldBlurValidate(name);
			}
		}, settings.onBlurDebounceWait)));
		// change handler
		if (settings.lazyValidation) {
			control.addOrWrapControlEventHandler('onChange', action(`${name} onChange`, () => {
				settings.lazyValidation = false;
			}));
		}
	}
	// add the field level plugin
	field.plugins = field.plugins || {};
	field.plugins.validationMessage = new FieldValidationMessage(name, validatorPlugin, form);
};

export { addFieldForValidation };
