import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

export const Spinner = observer(class Spinner extends React.Component {
	render() {
		const bars = [];
		const { props } = this;
		const { store } = props;
		const { model } = store;
		const { spinnerColor } = model;
		const spinnerStyleProps = {
			opacity: this.props.store.model.shouldShowSpinner ? 1 : 0.01,
			...(this.props.store.model.spinnerCssPosY !== null && {
				top: this.props.store.model.spinnerCssPosY,
			}),
		};

		for (let i=0; i<12; i++) {
			const barStyle = {};

			barStyle.animationDelay = `${(i - 12) / 10}s`;
			barStyle.WebkitAnimationDelay = barStyle.animationDelay;
			barStyle.transform = `rotate(${i * 30}deg) translate(146%)`;
			barStyle.WebkitTransform = barStyle.transform;
			barStyle.backgroundColor = spinnerColor;
			bars.push(
				<div
					style={barStyle}
					className="Spinner-bar"
					key={`Spinner-bar-${i}-${Math.random()}`}
				/>
			);
		}
		return (
			<div
				className={classNames('Spinner', {
					[props.className]: Boolean(props.className),
					'is-global': this.props.store.model.isPositionGlobal,
				})}
				style={spinnerStyleProps}
				ref={(elem) => { this.elem = elem; }}
			>
				{bars}
			</div>
		);
	}

	onMount(callback) {
		callback?.();
	}
});
