export const deliveryAddress = (value) => {
	let validationMessage = null;
	const poBoxValues = ['po ', 'po.', 'p.o ', 'p.o. ', 'pobox ', 'po box ', 'pob ', 'box ', 'post office box ', '# '];
	const theValue = value?.toLowerCase?.()?.trim?.() || null;

	poBoxValues.forEach((poBoxValue) => {
		if (theValue.startsWith(poBoxValue)) {
			validationMessage = '^We are not able to ship to post office boxes';
		}
	});
	return validationMessage;
};
