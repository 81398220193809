import { SiteMarketingMessage } from '~/site-marketing-messaging/Models/SiteMarketingMessage.model';
import { SiteMarketingMessaging } from '~/site-marketing-messaging/Models/SiteMarketingMessaging.model';

import type { ISiteMarketingMessageData } from '~/site-marketing-messaging/Types/SiteMarketingMessage.types';

export const createSiteMarketingMessagingModel = ({
	defaultMessageName = '',
	isOnCartPage,
	paused,
	siteMarketingMessagesData,
}: {
	defaultMessageName?: string
	isOnCartPage: boolean,
	paused: boolean,
	siteMarketingMessagesData: ISiteMarketingMessageData[],
}) => {
	const siteMarketingMessages = siteMarketingMessagesData.map(siteMarketingMessageData => SiteMarketingMessage.create(siteMarketingMessageData));

	return SiteMarketingMessaging.create({
		activeMessageIndex: 0,
		defaultMessageName,
		isOnCartPage,
		paused,
		siteMarketingMessages,
	});
};
