import styles from '#/general/header/topmenu.module.scss';
import subMenuStyles from '#/general/header/subMenuPromos.module.scss';
import listStyles from '#/mobile/global/menuList.module.scss';

import { s7ImagePath } from '~/global/global.constants';

export const topMenuData = [
	{
		id: '15',
		url: '/catalog/living',
		title: 'Living',
		classes: styles['subMenu-cols-8-4'],
		promoClasses: subMenuStyles['subMenuPromos_grid_1over2'],
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '151',
				url: '/catalog/living/sofas-and-sectionals',
				title: 'Sofas & Sectionals',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'living-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '1,511',
						url: '/catalog/living/sofas-and-loveseats',
						title: 'Sofas & Loveseats',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,512',
						url: '/catalog/living/sofas-with-chaise',
						title: 'Sofas with Chaise',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,513',
						url: '/catalog/living/sectionals',
						title: 'Sectionals',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,515',
						url: '/catalog/living/sleeper-sofas',
						title: 'Sleeper Sofas',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,516',
						url: '/catalog/living/chaises-and-daybeds',
						title: 'Chaises & Daybeds',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '152',
				url: '/catalog/living/chairs-and-ottomans',
				title: 'Chairs & Ottomans',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'living-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '1,521',
						url: '/catalog/living/chairs',
						title: 'Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,522',
						url: '/catalog/living/recliners',
						title: 'Recliners',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,525',
						url: '/catalog/living/ottomans',
						title: 'Ottomans',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,526',
						url: '/catalog/living/benches-and-stools',
						title: 'Benches & Stools',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '153',
				url: '/catalog/living/accent-tables',
				title: 'Accent Tables',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'living-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '1,531',
						url: '/catalog/living/coffee-tables',
						title: 'Coffee Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,532',
						url: '/catalog/living/end-tables',
						title: 'End Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,543',
						url: '/catalog/living/consoles',
						title: 'Consoles',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '154',
				url: '/catalog/living/living-storage-furniture',
				title: 'Storage',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'living-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '1,541',
						url: '/catalog/living/media-cabinets',
						title: 'Media Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,542',
						url: '/catalog/living/storage-cabinets',
						title: 'Storage Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,543',
						url: '/catalog/living/bookcases-and-wall-units',
						title: 'Bookcases & Wall Units',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,545',
						url: '/catalog/living/bar-cabinets-and-carts',
						title: 'Bar Cabinets & Carts',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '160',
				url: '/catalog/living/custom-living-room-furniture',
				title: 'Custom',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'living-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '1,601',
						url: '/catalog/custom/fabric-sectionals',
						title: 'Fabric Sectionals',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,602',
						url: '/catalog/custom/leather-sectionals',
						title: 'Leather Sectionals',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,603',
						url: '/catalog/custom/tables-by-the-inch',
						title: 'Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,604',
						url: '/catalog/custom/cabinets',
						title: 'Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,605',
						url: '/catalog/custom/bookcases',
						title: 'Bookcases',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '155',
				url: '/catalog/living/living-room-decor',
				title: 'Decor',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'living-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '1,551',
						url: '/catalog/home-decor/throw-pillows',
						title: 'Throw Pillows',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,552',
						url: '/catalog/home-decor/throw-blankets',
						title: 'Throw Blankets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,554',
						url: '/catalog/home-decor/wall-art',
						title: 'Wall Art',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '1,555',
						url: '/catalog/home-decor/shelves-and-ledges',
						title: 'Shelves & Ledges',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '156',
				url: '/#',
				title: '',
				classes: 'clearance',
				dataKey: '',
				hideFull: false,
				columnKey: 'living-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '1,561',
						url: '/clearance/living',
						title: 'Living Clearance',
						classes: 'clearance-item',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '157',
				promoTitle: 'Inspiring Living Rooms',
				url: '/ideas-advice/get-inspired/living-room-ideas',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_living_01_1023`,
				imgSrcSm: `${s7ImagePath}/navPromo_living_01_sm_1023`,
				imgWidth: '493',
				imgWidthSm: '250',
			},
			{
				id: '158',
				promoTitle: 'Seating Ideas for a Small Living Room',
				url: '/ideas-advice/design-your-space/living/seating-ideas-small-living-room',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_living_02_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_living_sm_02`,
				imgWidth: '234',
				imgWidthSm: '250',
			},
			{
				id: '159',
				promoTitle: 'Design with Color',
				url: '/catalog/living/design-with-color',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_living_03_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_living_sm_03_1122`,
				imgWidth: '234',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '20',
		url: '/catalog/dining-and-kitchen',
		title: 'Dining & Kitchen',
		classes: styles['subMenu-hardLeft'],
		promoClasses: subMenuStyles['subMenuPromos_grid_1over1'],
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '201',
				url: '/catalog/dining-and-kitchen/dining-tables',
				title: 'Tables',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'dining-and-kitchen-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,011',
						url: '/catalog/dining-and-kitchen/tables',
						title: 'Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,012',
						url: '/catalog/dining-and-kitchen/counter-and-bar-tables',
						title: 'Counter & Bar Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,013',
						url: '/catalog/dining-and-kitchen/kitchen-islands',
						title: 'Kitchen Islands',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '202',
				url: '/catalog/dining-and-kitchen/dining-seating',
				title: 'Seating',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'dining-and-kitchen-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,021',
						url: '/catalog/dining-and-kitchen/chairs',
						title: 'Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,022',
						url: '/catalog/dining-and-kitchen/counter-and-bar-stools',
						title: 'Counter & Bar Stools',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,023',
						url: '/catalog/dining-and-kitchen/benches',
						title: 'Benches',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '203',
				url: '/catalog/dining-and-kitchen/dining-and-kitchen-storage',
				title: 'Storage',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'dining-and-kitchen-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,031',
						url: '/catalog/dining-and-kitchen/storage-cabinets',
						title: 'Storage Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,032',
						url: '/catalog/dining-and-kitchen/bar-cabinets-and-carts',
						title: 'Bar Cabinets & Carts',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,033',
						url: '/catalog/dining-and-kitchen/bookcases-and-wall-units',
						title: 'Bookcases & Wall Units',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '211',
				url: '/catalog/dining-and-kitchen/custom-dining-and-kitchen-furniture',
				title: 'Custom',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'dining-and-kitchen-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,111',
						url: '/catalog/custom/tables-by-the-inch',
						title: 'Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,112',
						url: '/catalog/custom/cabinets',
						title: 'Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,113',
						url: '/catalog/custom/bookcases',
						title: 'Bookcases',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '204',
				url: '/catalog/dining-and-kitchen/kitchen-and-organization',
				title: 'Kitchen & Organization',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'dining-and-kitchen-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,041',
						url: '/catalog/dining-and-kitchen/kitchen-tools',
						title: 'Kitchen Tools',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,042',
						url: '/catalog/dining-and-kitchen/tabletop',
						title: 'Tabletop',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,043',
						url: '/catalog/dining-and-kitchen/table-linens',
						title: 'Table Linens',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,044',
						url: '/catalog/home-decor/baskets-and-bins',
						title: 'Baskets & Bins',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,045',
						url: '/catalog/dining-and-kitchen/care-and-cleaning',
						title: 'Care & Cleaning',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '207',
				url: '/catalog/dining-and-kitchen/dining-and-kitchen-decor',
				title: 'Decor',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'dining-and-kitchen-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,071',
						url: '/catalog/lighting/pendant-lights-and-chandeliers',
						title: 'Pendant Lights & Chandeliers',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,072',
						url: '/catalog/rugs/dining-room-rugs',
						title: 'Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,075',
						url: '/catalog/home-decor/vases-and-bowls',
						title: 'Vases & Bowls',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,076',
						url: '/catalog/home-decor/candles-and-candle-holders',
						title: 'Candles & Candle Holders',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,078',
						url: '/catalog/home-decor/shelves-and-ledges',
						title: 'Shelves & Ledges',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,077',
						url: '/catalog/home-decor/cabinet-hardware',
						title: 'Cabinet Hardware',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '208',
				url: '/#',
				title: '',
				classes: 'clearance',
				dataKey: '',
				hideFull: false,
				columnKey: 'dining-and-kitchen-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,081',
						url: '/clearance/dining-and-kitchen',
						title: 'Dining & Kitchen Clearance',
						classes: 'clearance-item',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '209',
				promoTitle: 'Inspiring Dining & Kitchen Spaces',
				url: '/ideas-advice/get-inspired/dining-room-ideas',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_dining_01_1024`,
				imgSrcSm: `${s7ImagePath}/navPromo_dining_sm_01_1024`,
				imgWidth: '493',
				imgWidthSm: '250',
			},
			{
				id: '210',
				promoTitle: 'Table & Chair Pairings',
				url: '/catalog/dining-and-kitchen/table-and-chair-pairings',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_dining_02_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_dining_sm_02`,
				imgWidth: '493',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '25',
		url: '/catalog/bedroom',
		title: 'Bedroom',
		classes: '',
		promoClasses: subMenuStyles['subMenuPromos_grid_2over1'],
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '251',
				url: '/catalog/bedroom/beds-and-mattresses',
				title: 'Beds & Mattresses',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'bedroom-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,511',
						url: '/catalog/bedroom/beds',
						title: 'Beds',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,512',
						url: '/catalog/bedroom/mattresses',
						title: 'Mattresses',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,513',
						url: '/catalog/bedroom/adjustable-beds-and-platform-board',
						title: 'Adjustable Beds & Platform Board',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '252',
				url: '/catalog/bedroom/bedding',
				title: 'Bedding',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'bedroom-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,522',
						url: '/catalog/bedroom/sheets-and-sheet-sets',
						title: 'Sheets & Pillowcases',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,525',
						url: '/catalog/bedroom/blankets-and-coverlets',
						title: 'Blankets & Coverlets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,526',
						url: '/catalog/bedroom/duvet-covers-and-shams',
						title: 'Duvet Covers & Shams',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,527',
						url: '/catalog/bedroom/bedding-essentials',
						title: 'Bedding Essentials',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '253',
				url: '/catalog/bedroom/bedroom-furniture',
				title: 'Furniture',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'bedroom-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,531',
						url: '/catalog/bedroom/dressers',
						title: 'Dressers',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,532',
						url: '/catalog/bedroom/nightstands',
						title: 'Nightstands',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,533',
						url: '/catalog/bedroom/armoires-and-cabinets',
						title: 'Armoires & Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,534',
						url: '/catalog/bedroom/bookcases-and-wall-units',
						title: 'Bookcases & Wall Units',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,535',
						url: '/catalog/bedroom/benches-and-stools',
						title: 'Benches & Stools',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '259',
				url: '/catalog/bedroom/kids',
				title: 'Kids',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'bedroom-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,592',
						url: '/catalog/bedroom/bunk-and-loft-beds',
						title: 'Bunk & Loft Beds',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,593',
						url: '/catalog/bedroom/kids-beds',
						title: 'Beds',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,594',
						url: '/catalog/bedroom/kids-dressers-and-nightstands',
						title: 'Dressers & Nightstands',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,595',
						url: '/catalog/bedroom/nursery',
						title: 'Nursery',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '254',
				url: '/catalog/bedroom/bedroom-decor',
				title: 'Decor',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'bedroom-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,546',
						url: '/catalog/home-decor/throw-blankets',
						title: 'Throw Blankets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,547',
						url: '/catalog/home-decor/throw-pillows',
						title: 'Throw Pillows',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,541',
						url: '/catalog/home-decor/mirrors',
						title: 'Mirrors',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,542',
						url: '/catalog/home-decor/wall-art',
						title: 'Wall Art',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,544',
						url: '/catalog/home-decor/shelves-and-ledges',
						title: 'Shelves & Ledges',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '2,548',
						url: '/catalog/home-decor/cabinet-hardware',
						title: 'Cabinet Hardware',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '255',
				url: '/#',
				title: '',
				classes: 'clearance',
				dataKey: '',
				hideFull: false,
				columnKey: 'bedroom-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '2,551',
						url: '/clearance/bedroom',
						title: 'Bedroom Clearance',
						classes: 'clearance-item',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '256',
				promoTitle: 'How to Style a Bed',
				url: '/ideas-advice/design-your-space/bedroom/how-to-style-a-bed',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_bedroom_01_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_bedroom_sm_01_1122`,
				imgWidth: '234',
				imgWidthSm: '250',
			},
			{
				id: '257',
				promoTitle: 'Customer Photos',
				url: '/ideas-advice/get-inspired/modern-style-gallery?filter=bedroom',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_bedroom_02_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_bedroom_sm_02_1122`,
				imgWidth: '234',
				imgWidthSm: '250',
			},
			{
				id: '258',
				promoTitle: 'Inspiring Kids Rooms',
				url: '/ideas-advice/get-inspired/creative-kids-rooms',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_bedroom_03_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_bedroom_sm_03_0523`,
				imgWidth: '493',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '30',
		url: '/catalog/bath',
		title: 'Bath',
		classes: '',
		promoClasses: subMenuStyles['subMenuPromos_grid_1over1'],
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '301',
				url: '/catalog/bath/bath-furniture',
				title: 'Furniture',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'bath-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '3,011',
						url: '/catalog/bath/vanities',
						title: 'Vanities',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,012',
						url: '/catalog/bath/mirrors-and-medicine-cabinets',
						title: 'Mirrors & Medicine Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,013',
						url: '/catalog/bath/bath-storage',
						title: 'Storage',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,014',
						url: '/catalog/bath/benches-and-stools',
						title: 'Benches & Stools',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '302',
				url: '/catalog/bath/lighting',
				title: 'Lighting',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'bath-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '3,022',
						url: '/catalog/bath/wall-sconces',
						title: 'Wall Sconces',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,023',
						url: '/catalog/bath/ceiling-lights',
						title: 'Ceiling Lights',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,024',
						url: '/catalog/bath/pendant-lights',
						title: 'Pendant Lights',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '303',
				url: '/catalog/bath/hardware-and-organization',
				title: 'Hardware & Organization',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'bath-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '3,032',
						url: '/catalog/bath/bath-hardware',
						title: 'Bath Hardware',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,033',
						url: '/catalog/bath/bath-organization',
						title: 'Organization',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,034',
						url: '/catalog/bath/hooks-shelves-and-ledges',
						title: 'Hooks, Shelves & Ledges',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,035',
						url: '/catalog/bath/cabinet-hardware',
						title: 'Cabinet Hardware',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '304',
				url: '/catalog/bath/bath-accessories',
				title: 'Accessories',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'bath-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '3,041',
						url: '/catalog/bath/bath-linens',
						title: 'Bath Linens',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,042',
						url: '/catalog/bath/laundry',
						title: 'Laundry',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,043',
						url: '/catalog/bath/self-care-and-wellness',
						title: 'Self Care & Wellness',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,044',
						url: '/catalog/rugs/bath-rugs',
						title: 'Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '305',
				url: '/#',
				title: '',
				classes: 'clearance',
				dataKey: '',
				hideFull: false,
				columnKey: 'bath-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '3,051',
						url: '/clearance/bath',
						title: 'Bath Clearance',
						classes: 'clearance-item',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '307',
				promoTitle: 'Inspiring Bathrooms',
				url: '/ideas-advice/get-inspired/bath-ideas',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_bath_01_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_bath_sm_01_0523`,
				imgWidth: '493',
				imgWidthSm: '250',
			},
			{
				id: '308',
				promoTitle: 'Customer Photos',
				url: '/ideas-advice/get-inspired/modern-style-gallery?filter=bath',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_bath_02_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_bath_sm_02_0523`,
				imgWidth: '493',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '35',
		url: '/catalog/storage-and-entryway',
		title: 'Storage',
		classes: '',
		promoClasses: subMenuStyles['subMenuPromos_grid_1over2'],
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '350',
				url: '/catalog/storage-and-entryway/storage-by-type',
				title: 'By Type',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'storage-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '3,501',
						url: '/catalog/storage-and-entryway/storage-cabinets',
						title: 'Storage Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,502',
						url: '/catalog/storage-and-entryway/media-cabinets',
						title: 'Media Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,503',
						url: '/catalog/storage-and-entryway/bookcases-and-wall-units',
						title: 'Bookcases & Wall Units',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,504',
						url: '/catalog/storage-and-entryway/bar-cabinets-and-carts',
						title: 'Bar Cabinets & Carts',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,505',
						url: '/catalog/storage-and-entryway/custom-and-modular-storage',
						title: 'Custom & Modular Storage',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '351',
				title: 'By Room',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'storage-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '3,511',
						url: '/catalog/living/living-storage-furniture',
						title: 'Living Room Storage',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,512',
						url: '/catalog/dining-and-kitchen/dining-and-kitchen-storage',
						title: 'Dining & Kitchen Storage',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,513',
						url: '/catalog/bedroom/bedroom-furniture',
						title: 'Bedroom Storage',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,514',
						url: '/catalog/storage-and-entryway/bath-storage-and-organization',
						title: 'Bath Storage & Organization',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,515',
						url: '/catalog/office/office-storage-and-organization',
						title: 'Office Storage & Organization',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,516',
						url: '/catalog/storage-and-entryway/entryway-storage-and-decor',
						title: 'Entryway Storage & Decor',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '352',
				url: '/catalog/storage-and-entryway/storage-organization',
				title: 'Organization',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'storage-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '3,522',
						url: '/catalog/home-decor/baskets-and-bins',
						title: 'Baskets & Bins',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,523',
						url: '/catalog/home-decor/trays-and-small-storage',
						title: 'Trays & Small Storage',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,524',
						url: '/catalog/home-decor/hooks-and-wall-organizers',
						title: 'Hooks & Wall Organizers',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,525',
						url: '/catalog/home-decor/shelves-and-ledges',
						title: 'Shelves & Ledges',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,526',
						url: '/catalog/storage-and-entryway/coat-racks',
						title: 'Coat Racks',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,527',
						url: '/catalog/office/power-outlets-and-chargers',
						title: 'Power Outlets & Chargers',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '354',
				url: '/catalog/storage-and-entryway/custom-storage-furniture',
				title: 'Custom',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'storage-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '3,541',
						url: '/catalog/custom/cabinets',
						title: 'Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '3,542',
						url: '/catalog/custom/bookcases',
						title: 'Bookcases',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '355',
				url: '/#',
				title: '',
				classes: 'clearance',
				dataKey: '',
				hideFull: false,
				columnKey: 'storage-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '3,551',
						url: '/clearance/storage',
						title: 'Storage Clearance',
						classes: 'clearance-item',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '356',
				promoTitle: 'Inspiring Storage Spaces',
				url: '/ideas-advice/get-inspired/storage-ideas',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_storage_01_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_storage_sm_01`,
				imgWidth: '493',
				imgWidthSm: '250',
			},
			{
				id: '357',
				promoTitle: 'How to Style a Bookcase',
				url: '/ideas-advice/get-inspired/style-a-bookcase',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_storage_02_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_storage_sm_02`,
				imgWidth: '234',
				imgWidthSm: '250',
			},
			{
				id: '358',
				promoTitle: 'Customer Photos',
				url: '/ideas-advice/get-inspired/modern-style-gallery?filter=storage',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_storage_03_1023?cb`,
				imgSrcSm: `${s7ImagePath}/navPromo_storage_sm_03_1023?cb`,
				imgWidth: '234',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '45',
		url: '/catalog/office',
		title: 'Office',
		classes: styles['subMenu-centered'],
		promoClasses: subMenuStyles['subMenuPromos_grid_1and2'],
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '451',
				url: '/catalog/office/desks-and-chairs',
				title: 'Desks & Chairs',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'office-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '4,511',
						url: '/catalog/office/desks',
						title: 'Desks',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,512',
						url: '/catalog/office/office-armoires',
						title: 'Office Armoires',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,513',
						url: '/catalog/office/office-chairs',
						title: 'Office Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '452',
				url: '/catalog/office/office-systems',
				title: 'Office Systems',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'office-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '4,521',
						url: '/catalog/office/benching-systems',
						title: 'Benching Systems',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,522',
						url: '/catalog/office/conference-room-tables',
						title: 'Conference Room Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,523',
						url: '/catalog/office/conference-room-chairs',
						title: 'Conference Room Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '453',
				url: '/catalog/office/office-storage-and-organization',
				title: 'Storage & Organization',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'office-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '4,531',
						url: '/catalog/office/storage-cabinets',
						title: 'Storage Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,532',
						url: '/catalog/office/bookcases-and-wall-units',
						title: 'Bookcases & Wall Units',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,533',
						url: '/catalog/office/coat-racks',
						title: 'Coat Racks',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,534',
						url: '/catalog/home-decor/hooks-and-wall-organizers',
						title: 'Hooks & Wall Organizers',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,535',
						url: '/catalog/office/desktop-organizers',
						title: 'Desktop Organizers',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,536',
						url: '/catalog/home-decor/baskets-and-bins',
						title: 'Baskets & Bins',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,537',
						url: '/catalog/office/power-outlets-and-chargers',
						title: 'Power Outlets & Chargers',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '454',
				url: '/catalog/office/custom-office-furniture',
				title: 'Custom',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'office-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '4,541',
						url: '/catalog/custom/desks-by-the-inch',
						title: 'Desks',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,542',
						url: '/catalog/custom/cabinets',
						title: 'Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,543',
						url: '/catalog/custom/bookcases',
						title: 'Bookcases',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '455',
				url: '/catalog/office/office-decor',
				title: 'Decor',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'office-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '4,551',
						url: '/catalog/lighting/task-lighting',
						title: 'Task Lighting',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,552',
						url: '/catalog/home-decor/wall-art',
						title: 'Wall Art',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,553',
						url: '/catalog/home-decor/clocks',
						title: 'Clocks',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,554',
						url: '/catalog/home-decor/shelves-and-ledges',
						title: 'Shelves & Ledges',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '4,555',
						url: '/catalog/home-decor/cabinet-hardware',
						title: 'Cabinet Hardware',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '456',
				url: '/#',
				title: '',
				classes: 'clearance',
				dataKey: '',
				hideFull: false,
				columnKey: 'office-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '4,561',
						url: '/clearance/office',
						title: 'Office Clearance',
						classes: 'clearance-item',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '457',
				promoTitle: 'Inspiring Home Offices',
				url: '/ideas-advice/get-inspired/home-office-ideas',
				imgHeight: '386',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_office_01_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_office_sm_01_1122`,
				imgWidth: '366',
				imgWidthSm: '250',
			},
			{
				id: '458',
				promoTitle: 'Customer Photos',
				url: '/ideas-advice/get-inspired/modern-style-gallery?filter=office',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_office_02_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_office_sm_02`,
				imgWidth: '366',
				imgWidthSm: '250',
			},
			{
				id: '459',
				promoTitle: 'Home Office Combinations',
				url: '/catalog/office/home-office-combinations',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_office_03_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_office_sm_03_0523`,
				imgWidth: '366',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '50',
		url: '/catalog/outdoor',
		title: 'Outdoor',
		classes: styles['subMenu-shiftLeft'],
		promoClasses: subMenuStyles['subMenuPromos_grid_1over2'],
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '501',
				url: '/catalog/outdoor/outdoor-lounge-furniture',
				title: 'Lounge',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'outdoor-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '5,011',
						url: '/catalog/outdoor/sofas',
						title: 'Sofas',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,012',
						url: '/catalog/outdoor/sectionals',
						title: 'Sectionals',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,013',
						url: '/catalog/outdoor/chairs',
						title: 'Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,014',
						url: '/catalog/outdoor/chaises',
						title: 'Chaises',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,015',
						url: '/catalog/outdoor/benches',
						title: 'Benches',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,016',
						url: '/catalog/outdoor/ottomans',
						title: 'Ottomans',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '502',
				url: '/catalog/outdoor/all-outdoor-accent-tables',
				title: 'Accent Tables',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'outdoor-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '5,021',
						url: '/catalog/outdoor/coffee-tables',
						title: 'Coffee Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,022',
						url: '/catalog/outdoor/side-tables',
						title: 'Side Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,023',
						url: '/catalog/outdoor/fire-tables',
						title: 'Fire Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '503',
				url: '/catalog/outdoor/dining-and-outdoor-kitchen',
				title: 'Dining & Outdoor Kitchen',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'outdoor-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '5,031',
						url: '/catalog/outdoor/tables',
						title: 'Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,032',
						url: '/catalog/outdoor/dining-chairs',
						title: 'Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,033',
						url: '/catalog/outdoor/dining-benches',
						title: 'Benches',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,034',
						url: '/catalog/outdoor/counter-and-bar-tables',
						title: 'Counter & Bar Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,035',
						url: '/catalog/outdoor/counter-and-bar-stools',
						title: 'Counter & Bar Stools',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,036',
						url: '/catalog/outdoor/bar-carts',
						title: 'Bar Carts',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,037',
						url: '/catalog/outdoor/outdoor-kitchens',
						title: 'Kitchens',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '507',
				url: '/catalog/outdoor/outdoor-custom',
				title: 'Custom',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'outdoor-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '5,071',
						url: '/catalog/custom/outdoor-sectionals',
						title: 'Sectionals',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,072',
						url: '/catalog/custom/outdoor-tables-by-the-inch',
						title: 'Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,073',
						url: '/catalog/custom/outdoor-rugs-by-the-inch',
						title: 'Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '504',
				url: '/catalog/outdoor/all-outdoor-accessories',
				title: 'Accessories',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'outdoor-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '5,041',
						url: '/catalog/outdoor/patio-umbrellas',
						title: 'Patio Umbrellas',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,042',
						url: '/catalog/outdoor/cushions',
						title: 'Cushions',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,043',
						url: '/catalog/outdoor/furniture-covers',
						title: 'Furniture Covers',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,044',
						url: '/catalog/outdoor/outdoor-storage',
						title: 'Storage',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,045',
						url: '/catalog/outdoor/outdoor-lighting',
						title: 'Lighting',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,046',
						url: '/catalog/outdoor/outdoor-care-products',
						title: 'Care Products',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '505',
				url: '/catalog/outdoor/outdoor-decor',
				title: 'Decor',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'outdoor-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '5,051',
						url: '/catalog/outdoor/outdoor-pillows',
						title: 'Pillows',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,052',
						url: '/catalog/outdoor/outdoor-pillow-ensembles',
						title: 'Pillow Ensembles',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,053',
						url: '/catalog/outdoor/outdoor-rugs',
						title: 'Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,054',
						url: '/catalog/outdoor/planters',
						title: 'Planters',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,055',
						url: '/catalog/outdoor/outdoor-decor-and-entertaining',
						title: 'Decor & Entertaining',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '506',
				url: '/#',
				title: '',
				classes: 'clearance',
				dataKey: '',
				hideFull: false,
				columnKey: 'outdoor-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '5,061',
						url: '/clearance/outdoor',
						title: 'Outdoor Clearance',
						classes: 'clearance-item',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '507',
				promoTitle: 'How to Create an Outdoor Living Space',
				url: '/ideas-advice/design-your-space/outdoor/how-to-create-an-outdoor-living-space',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_outdoor_01_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_outdoor_sm_03_0323`,
				imgWidth: '493',
				imgWidthSm: '250',
			},
			{
				id: '508',
				promoTitle: 'Outdoor Dining Combos',
				url: '/catalog/outdoor/favorite-outdoor-dining-combos',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_outdoor_02_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_outdoor_sm_02_0323`,
				imgWidth: '234',
				imgWidthSm: '250',
			},
			{
				id: '509',
				promoTitle: 'Customer Photos',
				url: '/ideas-advice/get-inspired/modern-style-gallery?filter=outdoor',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_outdoor_03_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_outdoor_sm_01`,
				imgWidth: '234',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '55',
		url: '/catalog/lighting',
		title: 'Lighting',
		classes: styles['subMenu-hardRight'],
		promoClasses: subMenuStyles['subMenuPromos_grid_2and1'],
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '551',
				url: '/catalog/lighting/lighting-by-product-type',
				title: 'By Product Type',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: '',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '5,512',
						url: '/catalog/lighting/table-lamps',
						title: 'Table Lamps',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,513',
						url: '/catalog/lighting/floor-lamps',
						title: 'Floor Lamps',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,514',
						url: '/catalog/lighting/pendant-lights-and-chandeliers',
						title: 'Pendant Lights & Chandeliers',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,515',
						url: '/catalog/lighting/ceiling-lights',
						title: 'Ceiling Lights',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,516',
						url: '/catalog/lighting/desk-lamps',
						title: 'Desk Lamps',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,517',
						url: '/catalog/lighting/wall-sconces',
						title: 'Wall Sconces',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,518',
						url: '/catalog/lighting/ceiling-fans',
						title: 'Ceiling Fans',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,519',
						url: '/catalog/lighting/outdoor-lighting',
						title: 'Outdoor Lights',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,520',
						url: '/catalog/lighting/led-light-bulbs',
						title: 'LED Light Bulbs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '552',
				url: '/catalog/lighting/lighting-by-room',
				title: 'By Room',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: '',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '5,521',
						url: '/catalog/lighting/lighting-for-living-rooms',
						title: 'Living Room Lighting',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,522',
						url: '/catalog/lighting/lighting-for-dining-room-and-kitchen',
						title: 'Dining Room & Kitchen Lighting',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,523',
						url: '/catalog/lighting/lighting-for-bedroom',
						title: 'Bedroom Lighting',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,524',
						url: '/catalog/lighting/lighting-for-bath',
						title: 'Bath Lighting',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,525',
						url: '/catalog/lighting/lighting-for-entryway',
						title: 'Entryway Lighting',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,526',
						url: '/catalog/lighting/lighting-for-office',
						title: 'Office Lighting',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '553',
				url: '/catalog/lighting/by-lighting-type',
				title: 'By Lighting Type',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'lighting-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '5,531',
						url: '/catalog/lighting/primary-lighting',
						title: 'Primary Lighting',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,532',
						url: '/catalog/lighting/accent-lighting',
						title: 'Accent Lighting',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '5,533',
						url: '/catalog/lighting/task-lighting',
						title: 'Task Lighting',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '554',
				url: '/#',
				title: '',
				classes: 'clearance',
				dataKey: '',
				hideFull: false,
				columnKey: 'lighting-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '5,541',
						url: '/clearance/lighting',
						title: 'Lighting Clearance',
						classes: 'clearance-item',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '555',
				promoTitle: 'Customer Photos',
				url: '/ideas-advice/get-inspired/modern-style-gallery?filter=lighting',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_lighting_01_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_lighting_sm_01`,
				imgWidth: '366',
				imgWidthSm: '250',
			},
			{
				id: '556',
				promoTitle: 'How to Light a Room',
				url: '/ideas-advice/design-your-space/ideas-for-any-room/how-to-light-your-room',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_lighting_02_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_lighting_sm_02`,
				imgWidth: '366',
				imgWidthSm: '250',
			},
			{
				id: '557',
				promoTitle: 'Inspiring Lighting',
				url: '/ideas-advice/get-inspired/lighting-ideas',
				imgHeight: '386',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_lighting_03_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_lighting_sm_03_1122`,
				imgWidth: '366',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '60',
		url: '/catalog/rugs',
		title: 'Rugs',
		classes: '',
		promoClasses: subMenuStyles['subMenuPromos_grid_4pack'],
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '601',
				url: '/catalog/rugs/rugs-by-style',
				title: 'By Style',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'rugs-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '6,011',
						url: '/catalog/rugs/all-rugs',
						title: 'All Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,012',
						url: '/catalog/rugs/solid-rugs',
						title: 'Solid Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,013',
						url: '/catalog/rugs/patterned-rugs',
						title: 'Patterned Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,014',
						url: '/catalog/rugs/rug-pads',
						title: 'Rug Pads',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '602',
				url: '/catalog/rugs/rugs-by-room',
				title: 'By Room',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'rugs-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '6,021',
						url: '/catalog/rugs/living-room-rugs',
						title: 'Living Room Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,022',
						url: '/catalog/rugs/dining-room-rugs',
						title: 'Dining Room Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,023',
						url: '/catalog/rugs/kitchen-rugs',
						title: 'Kitchen Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,024',
						url: '/catalog/rugs/bedroom-rugs',
						title: 'Bedroom Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,025',
						url: '/catalog/rugs/bath-rugs',
						title: 'Bath Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,026',
						url: '/catalog/rugs/entryway-rugs',
						title: 'Entryway Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,027',
						url: '/catalog/rugs/outdoor-rugs',
						title: 'Outdoor Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '603',
				url: '/catalog/rugs/rugs-by-size',
				title: 'By Size',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'rugs-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '6,031',
						url: '/catalog/rugs/10x14',
						title: '10\'x14\'',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,032',
						url: '/catalog/rugs/9x12',
						title: '9\'x12\'',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,033',
						url: '/catalog/rugs/8x10',
						title: '8\'x10\'',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,034',
						url: '/catalog/rugs/6x9',
						title: '6\'x9\'',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,035',
						url: '/catalog/rugs/5x8',
						title: '5\'x8\'',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,036',
						url: '/catalog/rugs/3x5-and-under',
						title: '3\'x5\' & Under',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,037',
						url: '/catalog/rugs/runners',
						title: 'Runners',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,038',
						url: '/catalog/custom/rugs-by-the-inch',
						title: 'Custom Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '604',
				url: '/#',
				title: '',
				classes: 'clearance',
				dataKey: '',
				hideFull: false,
				columnKey: 'rugs-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '6,041',
						url: '/clearance/rugs',
						title: 'Rugs Clearance',
						classes: 'clearance-item',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '605',
				promoTitle: 'Inspiring Rugs',
				url: '/ideas-advice/get-inspired/rug-ideas',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_rugs_01_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_rugs_sm_01_1122`,
				imgWidth: '366',
				imgWidthSm: '250',
			},
			{
				id: '606',
				promoTitle: 'Customer Photos',
				url: '/ideas-advice/get-inspired/modern-style-gallery?filter=rugs',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_rugs_03_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_rugs_sm_02`,
				imgWidth: '366',
				imgWidthSm: '250',
			},
			{
				id: '607',
				promoTitle: 'How to Choose a Rug Size',
				url: '/ideas-advice/design-your-space/ideas-for-any-room/how-to-choose-a-rug-size',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_rugs_02_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_rugs_sm_03`,
				imgWidth: '366',
				imgWidthSm: '250',
			},
			{
				id: '608',
				promoTitle: 'How to Choose a Rug Style',
				url: '/ideas-advice/design-your-space/ideas-for-any-room/how-to-choose-a-rug-style',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_rugs_04_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_rugs_sm_04_0523`,
				imgWidth: '366',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '65',
		url: '/catalog/home-decor',
		title: 'Home Decor',
		classes: styles['subMenu-hardRight'],
		promoClasses: subMenuStyles['subMenuPromos_grid_1over2'],
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '651',
				url: '/catalog/home-decor/throw-pillows-and-blankets',
				title: 'Throw Pillows & Blankets',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'home-decor-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '6,511',
						url: '/catalog/home-decor/throw-pillows',
						title: 'All Throw Pillows',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,512',
						url: '/catalog/home-decor/patterned-pillows',
						title: 'Patterned Pillows',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,513',
						url: '/catalog/home-decor/solid-pillows',
						title: 'Solid Pillows',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,514',
						url: '/catalog/home-decor/pillows-by-color',
						title: 'Pillows by Color',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,515',
						url: '/catalog/home-decor/pillow-ensembles',
						title: 'Pillow Ensembles',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,516',
						url: '/catalog/home-decor/throw-blankets',
						title: 'Throw Blankets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '652',
				url: '/catalog/home-decor/wall-decor',
				title: 'Wall Decor',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'home-decor-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '6,521',
						url: '/catalog/home-decor/mirrors',
						title: 'Mirrors',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,522',
						url: '/catalog/home-decor/picture-frames',
						title: 'Picture Frames',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,523',
						url: '/catalog/home-decor/shelves-and-ledges',
						title: 'Shelves & Ledges',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,524',
						url: '/catalog/home-decor/hooks-and-wall-organizers',
						title: 'Hooks & Wall Organizers',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,525',
						url: '/catalog/home-decor/clocks',
						title: 'Clocks',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '653',
				url: '/catalog/home-decor/wall-art',
				title: 'Wall Art',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'home-decor-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '6,532',
						url: '/catalog/home-decor/prints-and-silkscreens',
						title: 'Prints & Silkscreens',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,533',
						url: '/catalog/home-decor/limited-edition-wall-art',
						title: 'Limited Edition',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,534',
						url: '/catalog/home-decor/vintage-and-art-decor',
						title: 'Vintage & Art Decor',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,535',
						url: '/catalog/home-decor/photography',
						title: 'Photography',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '654',
				url: '/catalog/home-decor/decor-and-organization',
				title: 'Decor & Organization',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'home-decor-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '6,541',
						url: '/catalog/home-decor/vases-and-bowls',
						title: 'Vases & Bowls',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,542',
						url: '/catalog/home-decor/baskets-and-bins',
						title: 'Baskets & Bins',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,543',
						url: '/catalog/home-decor/trays-and-small-storage',
						title: 'Trays & Small Storage',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,544',
						url: '/catalog/home-decor/planters',
						title: 'Planters',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,545',
						url: '/catalog/home-decor/candles-and-candle-holders',
						title: 'Candles & Candle Holders',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,546',
						url: '/catalog/home-decor/entertainment-and-novelty',
						title: 'Entertainment & Novelty',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '6,546',
						url: '/catalog/home-decor/cabinet-hardware',
						title: 'Cabinet Hardware',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '655',
				url: '',
				title: 'Gifting',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'home-decor-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '6,571',
						url: '/catalog/home-decor/gift-ideas',
						title: 'Gift Ideas',
						classes: '',
						hideFull: false,
						hideMobile: false,
						linkMobile: false,
					},
					{
						id: '6,572',
						url: '/catalog/home-decor/gifts-under-100',
						title: 'Gifts Under $100',
						classes: '',
						hideFull: false,
						hideMobile: false,
						linkMobile: false,
					},
					{
						id: '6,573',
						url: '/gift-cards',
						title: 'Gift Cards',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '656',
				url: '/#',
				title: '',
				classes: 'clearance',
				dataKey: '',
				hideFull: false,
				columnKey: 'home-decor-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '6,591',
						url: '/clearance/home-decor',
						title: 'Home Decor Clearance',
						classes: 'clearance-item',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '659',
				promoTitle: 'Inspiring Home Decor',
				url: '/ideas-advice/get-inspired/home-decor-ideas',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_decor_01_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_decor_sm_01`,
				imgWidth: '493',
				imgWidthSm: '250',
			},
			{
				id: '660',
				promoTitle: 'Seasonal Favorites',
				url: '/catalog/home-decor/seasonal-favorites',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_decor_02_0424`,
				imgSrcSm: `${s7ImagePath}/navPromo_decor_sm_02_0424`,
				imgWidth: '234',
				imgWidthSm: '250',
			},
			{
				id: '661',
				promoTitle: 'How to Pair Throw Pillows',
				url: '/ideas-advice/design-your-space/ideas-for-any-room/how-to-pair-throw-pillows',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_decor_03_0923`,
				imgSrcSm: `${s7ImagePath}/navPromo_decor_sm_03_0923`,
				imgWidth: '234',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '70',
		url: '/catalog/custom',
		title: 'Custom',
		classes: styles['subMenu-hardRight'],
		promoClasses: `${subMenuStyles['subMenuPromos_grid_1and2']} ${subMenuStyles['subMenuPromos_grid_1and2_xl']}`,
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '701',
				url: '/catalog/custom/custom-furniture',
				title: 'Furniture',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'custom-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,011',
						url: '/catalog/custom/fabric-sectionals',
						title: 'Fabric Sectionals',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,012',
						url: '/catalog/custom/leather-sectionals',
						title: 'Leather Sectionals',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,013',
						url: '/catalog/custom/cabinets',
						title: 'Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,014',
						url: '/catalog/custom/bookcases',
						title: 'Bookcases',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,015',
						url: '/catalog/custom/tables-by-the-inch',
						title: 'Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,016',
						url: '/catalog/custom/desks-by-the-inch',
						title: 'Desks',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '703',
				url: '/catalog/custom/custom-outdoor',
				title: 'Outdoor',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'custom-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,031',
						url: '/catalog/custom/outdoor-sectionals',
						title: 'Sectionals',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,032',
						url: '/catalog/custom/outdoor-tables-by-the-inch',
						title: 'Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,033',
						url: '/catalog/custom/outdoor-rugs-by-the-inch',
						title: 'Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '702',
				url: '/catalog/custom/custom-home-decor',
				title: 'Home Decor',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'custom-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,021',
						url: '/catalog/custom/rugs-by-the-inch',
						title: 'Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,022',
						url: '/catalog/custom/mirrors-by-the-inch',
						title: 'Mirrors',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,023',
						url: '/catalog/custom/bath-mirrors-by-the-inch',
						title: 'Bath Mirrors',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '703',
				promoTitle: 'Inspiring Custom Furniture',
				url: '/ideas-advice/get-inspired/custom-furniture-ideas',
				imgHeight: '386',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_custom_01_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_custom_sm_01_0523`,
				imgWidth: '498',
				imgWidthSm: '250',
			},
			{
				id: '704',
				promoTitle: 'Customer Photos',
				url: '/ideas-advice/get-inspired/modern-style-gallery?filter=custom',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_custom_02_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_custom_sm_02_0523`,
				imgWidth: '498',
				imgWidthSm: '250',
			},
			{
				id: '705',
				promoTitle: 'Design Your Own Custom Sectional',
				url: '/catalog/custom/fabric-sectionals',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_custom_03_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_custom_sm_03_1122`,
				imgWidth: '498',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '75',
		url: '/catalog/commercial',
		title: 'Commercial',
		classes: styles['subMenu-hardRight'],
		promoClasses: subMenuStyles['subMenuPromos_grid_1over1'],
		hideFull: false,
		hideMobile: false,
		submenuVertical: true,
		children: [
			{
				id: '781',
				url: '/catalog/commercial/commercial-office-furniture',
				title: 'Office',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'commercial-1',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,811',
						url: '/catalog/commercial/conference-room-tables',
						title: 'Conference Room Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,812',
						url: '/catalog/commercial/conference-room-chairs',
						title: 'Conference Room Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,813',
						url: '/catalog/commercial/desks',
						title: 'Desks',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,814',
						url: '/catalog/commercial/benching-systems',
						title: 'Benching Systems',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,815',
						url: '/catalog/commercial/file-cabinets',
						title: 'File Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,816',
						url: '/catalog/commercial/office-chairs',
						title: 'Office Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '782',
				url: '/catalog/commercial/commercial-dining-and-kitchen-furniture',
				title: 'Dining & Kitchen',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'commercial-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,821',
						url: '/catalog/commercial/tables',
						title: 'Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,822',
						url: '/catalog/commercial/counter-and-bar-tables',
						title: 'Counter & Bar Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,823',
						url: '/catalog/commercial/kitchen-islands',
						title: 'Kitchen Islands',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,824',
						url: '/catalog/commercial/dining-chairs',
						title: 'Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,825',
						url: '/catalog/commercial/dining-benches',
						title: 'Benches',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,826',
						url: '/catalog/commercial/counter-and-bar-stools',
						title: 'Counter & Bar Stools',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,827',
						url: '/catalog/commercial/bar-cabinets-and-carts',
						title: 'Bar Cabinets & Carts',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '783',
				url: '/catalog/commercial/commercial-bedroom-and-bath-furniture',
				title: 'Bedroom & Bath',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'commercial-2',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,831',
						url: '/catalog/commercial/beds',
						title: 'Beds',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,832',
						url: '/catalog/commercial/dressers',
						title: 'Dressers',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,833',
						url: '/catalog/commercial/nightstands',
						title: 'Nightstands',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,834',
						url: '/catalog/commercial/bathroom-vanities',
						title: 'Vanities',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,835',
						url: '/catalog/commercial/bathroom-storage',
						title: 'Storage',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '784',
				url: '/catalog/commercial/commercial-seating',
				title: 'Seating',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'commercial-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,841',
						url: '/catalog/commercial/sofas-and-loveseats',
						title: 'Sofas & Loveseats',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,842',
						url: '/catalog/commercial/sectionals',
						title: 'Sectionals',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,843',
						url: '/catalog/commercial/sleeper-sofas',
						title: 'Sleeper Sofas',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,844',
						url: '/catalog/commercial/chairs',
						title: 'Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,845',
						url: '/catalog/commercial/ottomans',
						title: 'Ottomans',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,846',
						url: '/catalog/commercial/chaises-and-daybeds',
						title: 'Chaises & Daybeds',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,847',
						url: '/catalog/commercial/benches',
						title: 'Benches',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '785',
				url: '/catalog/commercial/commercial-storage-and-accent-tables',
				title: 'Storage & Accent Tables',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'commercial-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,861',
						url: '/catalog/commercial/bookcases-and-wall-units',
						title: 'Bookcases & Wall Units',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,862',
						url: '/catalog/commercial/storage-cabinets',
						title: 'Storage Cabinets',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,863',
						url: '/catalog/commercial/consoles',
						title: 'Consoles',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,864',
						url: '/catalog/commercial/coffee-tables',
						title: 'Coffee Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,865',
						url: '/catalog/commercial/end-tables',
						title: 'End Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '792',
				url: '',
				title: 'Rugs',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'commercial-3',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,921',
						url: '/catalog/commercial/commercial-rugs',
						title: 'All Rugs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '787',
				url: '/catalog/commercial/commercial-outdoor-lounge-furniture',
				title: 'Outdoor Lounge',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'commercial-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,871',
						url: '/catalog/commercial/outdoor-sofas',
						title: 'Sofas',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,872',
						url: '/catalog/commercial/outdoor-sectionals',
						title: 'Sectionals',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,873',
						url: '/catalog/commercial/outdoor-chairs',
						title: 'Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,874',
						url: '/catalog/commercial/outdoor-chaises',
						title: 'Chaises',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,875',
						url: '/catalog/commercial/outdoor-ottomans',
						title: 'Ottomans',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,876',
						url: '/catalog/commercial/outdoor-accent-tables',
						title: 'Accent Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '788',
				url: '/catalog/commercial/commercial-outdoor-dining-and-kitchen-furniture',
				title: 'Outdoor Dining & Kitchen',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'commercial-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,881',
						url: '/catalog/commercial/outdoor-dining-tables',
						title: 'Dining Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,882',
						url: '/catalog/commercial/outdoor-counter-and-bar-tables',
						title: 'Counter & Bar Tables',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,883',
						url: '/catalog/commercial/outdoor-dining-chairs',
						title: 'Dining Chairs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,884',
						url: '/catalog/commercial/outdoor-counter-and-bar-stools',
						title: 'Counter & Bar Stools',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,885',
						url: '/catalog/commercial/outdoor-dining-benches',
						title: 'Benches',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,886',
						url: '/catalog/commercial/bar-carts',
						title: 'Bar Carts',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,887',
						url: '/catalog/commercial/outdoor-kitchens',
						title: 'Kitchens',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
			{
				id: '789',
				url: '/catalog/commercial/commercial-outdoor-decor',
				title: 'Outdoor Decor',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: 'commercial-4',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '7,891',
						url: '/catalog/commercial/patio-umbrellas',
						title: 'Patio Umbrellas',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '7,892',
						url: '/catalog/commercial/outdoor-accessories',
						title: 'Outdoor Accessories',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
		contentPromos: [
			{
				id: '790',
				promoTitle: 'Inspiring Commercial Spaces',
				url: '/ideas-advice/get-inspired/commercial-hospitality-ideas',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_commercial_01_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_commercial_sm_01`,
				imgWidth: '493',
				imgWidthSm: '250',
			},
			{
				id: '791',
				promoTitle: 'Connect with Room & Board for Business',
				url: '/business',
				imgHeight: '168',
				imgHeightSm: '180',
				imgSrc: `${s7ImagePath}/navPromo_commercial_02_0523`,
				imgSrcSm: `${s7ImagePath}/navPromo_commercial_sm_02_0423`,
				imgWidth: '493',
				imgWidthSm: '250',
			},
		],
	},
	{
		id: '80',
		url: '/clearance',
		title: 'Clearance',
		classes: listStyles.clearance,
		hideFull: true,
		hideMobile: false,
		submenuVertical: false,
		secondaryMenu: false,
	},
	{
		id: '90',
		url: '/free-design-services',
		title: 'Free Design Services',
		classes: '',
		hideFull: true,
		hideMobile: false,
		submenuVertical: false,
		dataKey: 'free-design-services',
		secondaryMenu: true,
	},
	{
		id: '100',
		url: '/ideas-advice',
		title: 'Ideas & Advice',
		classes: '',
		hideFull: true,
		hideMobile: false,
		submenuVertical: false,
		dataKey: 'ideas-and-advice',
		secondaryMenu: true,
		children: [
			{
				id: '1001',
				url: '/#',
				title: 'Ideas & Advice Overview',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: '',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '10,011',
						url: '/ideas-advice/get-inspired',
						title: 'Get Inspired',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '10,012',
						url: '/ideas-advice/explore-materials',
						title: 'Explore Materials',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '10,013',
						url: '/ideas-advice/design-your-space',
						title: 'Design Your Space',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '10,014',
						url: '/ideas-advice/product-care',
						title: 'Product Care, Assembly & Safety Information',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '10,015',
						url: '/digital-catalogs',
						title: 'Digital Catalogs',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '10,016',
						url: '/ideas-advice/videos',
						title: 'Videos',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
	},
	{
		id: '110',
		url: '/small-space',
		title: 'Small Space',
		classes: '',
		hideFull: true,
		hideMobile: false,
		submenuVertical: false,
		dataKey: 'small-space',
		secondaryMenu: true,
	},
	{
		id: '120',
		url: '/business',
		title: 'For Business',
		classes: '',
		hideFull: true,
		hideMobile: false,
		submenuVertical: false,
		dataKey: 'business-interiors',
		secondaryMenu: true,
	},
	{
		id: '130',
		url: '/sustainability',
		title: 'Sustainability',
		classes: '',
		hideFull: true,
		hideMobile: false,
		submenuVertical: false,
		dataKey: 'sustainability',
		secondaryMenu: true,
		children: [
			{
				id: '1301',
				url: '/#',
				title: 'Sustainability Overview',
				classes: '',
				dataKey: '',
				hideFull: false,
				columnKey: '',
				hideMobile: false,
				promoUiKey: '',
				children: [
					{
						id: '13,011',
						url: '/sustainability/better-products',
						title: 'Better Products',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '13,012',
						url: '/sustainability/better-for-people',
						title: 'Better for People',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '13,013',
						url: '/sustainability/better-for-the-planet',
						title: 'Better for the Planet',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '13,014',
						url: '/sustainability/guidelines-policies',
						title: 'Guidelines & Policies',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '13,015',
						url: '/sustainability/impact-report',
						title: 'Impact Report',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
					{
						id: '13,016',
						url: '/sustainability/certified-b-corp',
						title: 'Certified B Corp',
						classes: '',
						hideFull: false,
						hideMobile: false,
					},
				],
			},
		],
	},
	{
		id: '140',
		url: '/gift-cards',
		title: 'Gift Cards',
		classes: '',
		hideFull: true,
		hideMobile: false,
		submenuVertical: false,
		dataKey: 'gift-cards',
		secondaryMenu: true,
	},
];
