import React from 'react';
import { observer } from 'mobx-react';

import { Mask } from '~/util/formz/Components/plugins/Mask';
import { BaseField } from '~/util/formz/Components/fields/BaseField';

const MaskedField = observer(class MaskedField extends React.Component {
	render() {
		return (
			<BaseField {...this.props}>
				<Mask {...this.props} />
			</BaseField>
		);
	}
});

export { MaskedField };
