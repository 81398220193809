import { isObservableArray } from 'mobx';

import { isEmpty } from '~/util/isEmpty';
import { isFunction } from '~/util/isFunction';
import { getMessageText } from '~/lib/validatejs/custom-validators/getMessageText';

export const dependentPhoneUS = (value, optionsArray, key, attributes) => {
	let validationMessage = null;
	const theOptions = !Array.isArray(optionsArray) && isObservableArray(optionsArray) ? [optionsArray] : optionsArray;

	theOptions.forEach((options) => {
		const {
			dependentModelKey,
			dependentModelValue: testModelValue,
		} = options;
		const dependentModelValue = attributes[dependentModelKey];
		let shouldValidate = false;
		const modelObject = {};
		const validationSettingsObject = {};

		if (isFunction(testModelValue)) {
			shouldValidate = testModelValue(dependentModelValue);
		} else if (isEmpty(testModelValue)) {
			// we aren't checking for a specific testModelValue, just presence
			shouldValidate = !isEmpty(dependentModelValue);
		} else if (testModelValue === dependentModelValue) {
			// check the testModelValue for a specific value
			shouldValidate = true;
		}
		if (shouldValidate) {
			modelObject[key] = value;
			if (options.message) {
				validationSettingsObject[key] = {
					phoneUS: true,
				};
			} else {
				validationSettingsObject[key] = {
					phoneUS: true,
				};
			}
			validationMessage = getMessageText(modelObject, validationSettingsObject, key);
			validationMessage = validationMessage && `^${validationMessage}`;
		}
	});
	return validationMessage;
};
