import { observable, toJS, makeObservable } from 'mobx';

import { FormzBuilders } from '~/util/formz/builders/FormzBuilders';
import { formzPlugins } from '~/util/formz/builders/formzPlugins';

export class FieldBuilder {
	constructor(name, fieldData, form) {
		makeObservable(this, {
			field: observable,
		});

		const self = this;

		this.name = name;
		this.fieldData = fieldData;
		this.form = form;
		// toJS needed for IE.
		this.fieldId = [
			'field-group',
			`${this.name}-field-group`,
			`${this.name}-field-group-${toJS(this.form.id)}`,
		];
		this.field = observable({
			reactPropsMap: observable.map({
				className: this.fieldId.join(' '),
				key: this.fieldId.join(' '),
				...this.fieldData.reactProps,
			}),
			get reactProps() {
				return Object.fromEntries(toJS(self.field.reactPropsMap));
			},
		});

		const builderList = new FormzBuilders();
		const {
			settings,
			...theFieldData
		} = this.fieldData;

		Object.entries(theFieldData).forEach(([key, value]) => {
			const SelectedBuilder = builderList.getBuilderByKey(key, value);

			if (!SelectedBuilder) {
				console.warn(`Please be sure you have a form model builder for: ${key}`);
			} else if (SelectedBuilder.BUILDER_KEY === 'radios') {
				const radiosBuilder = new SelectedBuilder(this.name, value, this.form);
				const { radiosData = [] } = radiosBuilder;

				radiosData.forEach((radioData) => {
					radiosBuilder.radios.push(new FieldBuilder(...radioData));
				});
				this[key] = radiosBuilder.radios;
			} else {
				this[key] = new SelectedBuilder(this.name, value, this.form);
			}
		});
		if (this.fieldData.settings) {
			this.settings = observable(this.fieldData.settings);
			if (typeof this.settings.shouldValidate === 'undefined') {
				this.settings.shouldValidate = true;
			}
			if (this?.settings?.plugins?.length) {
				this.settings.plugins.forEach((value) => {
					const SelectedPlugin = formzPlugins?.[value.pluginId];

					if (SelectedPlugin) {
						this.plugins = this.plugins || {};
						this.plugins[value.pluginId] = new SelectedPlugin(this.name, this, this.form, value.settings);
					}
				});
			}
		} else {
			this.settings = observable({
				shouldValidate: true,
			});
		}
	}

	fieldId = [];

	field = {};
}
