'use client';

import { useEffect, useState } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';

export const useMagicOverlayPageTransition = () => {
	const { clientSidePageTransition } = useGlobalContext();
	const [transitionReady, setTransitionReady] = useState(false);

	useEffect(() => {
		if (clientSidePageTransition) {
			setTransitionReady(true);
		}
	}, [transitionReady]);
	return {
		clientSidePageTransition,
		transitionReady,
	};
};
