import {
	action, computed, observable, toJS, makeObservable,
} from 'mobx';

import { BaseControlBuilder } from '~/util/formz/builders/controls/BaseControlBuilder';

class RadiosControlBuilder {
	constructor(name, controlData, form) {
		makeObservable(this, {
			radios: observable,
			setupRadios: action,
		});

		this.name = name;
		this.controlData = controlData;
		this.form = form;
		this.setupRadios();
	}

	radios = [];

	radiosData = [];

	setupRadios() {
		// setup the fields
		this?.controlData?.forEach?.((field) => {
			// we need to make these fields more unique
			// add the value to the id
			const value = field?.control?.reactProps?.value;

			// NextJS HMR bombs when messing with this loop for reasons unknown.
			// Very bad times ahead. You have been warned.
			Object.entries(field).forEach(([type, fieldData]) => {
				if (type === 'settings' || type === 'reactProps') {
					return;
				}
				const inputId = `${value}-${this.name}-${type}-${this.form.id}`;

				if (type === 'control') {
					fieldData.reactProps.type = 'radio';
				} else if (type === 'label') {
					fieldData.reactProps.htmlFor = `${value}-${this.name}-control-${this.form.id}`;
				}
				// Not sure if this does anything
				fieldData.reactProps = {
					...fieldData.reactProps,
					id: inputId,
					key: inputId,
					className: `${inputId} ${fieldData?.reactProps?.className}`,
				};
			});

			this.radiosData.push([
				this.name,
				field,
				this.form,
			]);
		});
	}

	static BUILDER_KEY = 'radios'
}

class RadioControlBuilder extends BaseControlBuilder {
	constructor(name, controlData, form) {
		super(name, controlData, form);

		makeObservable(this, {
			isChecked: computed,
			reactProps: computed,
		});

		this.name = name;
		this.controlData = controlData;
		this.form = form;

		// default control events
		this.addOrWrapControlEventHandler('onChange', action(`${this.name} onChange`, (event) => {
			if (event.target.checked) {
				this.form.updateModelValue(this.name, this.reactProps.value);
			}
		}));
	}

	get isChecked() {
		return this.value === this.reactPropsMap.get('value');
	}

	get reactProps() {
		return {
			checked: this.isChecked,
			...Object.fromEntries(toJS(this.reactPropsMap)),
		};
	}

	static BUILDER_KEY = 'radio';
}

export {
	RadiosControlBuilder,
	RadioControlBuilder,
};
