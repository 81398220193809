import React from 'react';

export const ContactUsError = ({ parameters }) => {
	const {
		error: {
			message = '',
		} = {},
	} = parameters;
	return (
		<>
			<p className="Heading Heading--4 tw-text-brown tw-mb-1">There was an error processing your request.</p>
			<p className="tw-text-red">{message}</p>
		</>
	);
};
