import { action, observable, makeObservable, toJS } from 'mobx';

import { isOnServer } from '~/global/global.constants';
import { ViewStateConfig, ViewStateOptions, ViewStateView } from '~/util/viewState/ViewState.interface';

export class ViewStateStore {
	constructor(store: any, viewSettings: ViewStateConfig, options?: ViewStateOptions) {
		makeObservable(this, {
			currentView: observable.ref,
			previousView: observable.ref,
			clearCurrentView: action,
			goTo: action,
			reload: action.bound,
		});

		this.store = store;
		this.viewSettings = viewSettings;
		this.options = {
			clearBeforeTransition: true,
			scrollToTopOfModal: true,
			...options,
		};
	}

	store: any | null = null

	options: ViewStateOptions | null = null

	viewSettings: ViewStateConfig | null = null

	viewProps: object | undefined

	currentView: ViewStateView | null = null

	previousView: ViewStateView | null = null

	clearCurrentView() {
		this.currentView = null;
	}

	goTo<TParameters extends object = {}>(viewKey: string, parameters: TParameters = {} as TParameters) {
		const viewToTransitionTo = this.viewSettings?.[viewKey];
		const viewProps = {
			viewState: this,
			parameters,
		};

		if (!viewToTransitionTo) {
			return;
		}
		viewToTransitionTo.key = viewKey;
		// beforeExit
		if (typeof this.currentView?.onBeforeExit === 'function') {
			if (!this.currentView.onBeforeExit(this, this.store, viewKey)) {
				return;
			}
		}
		// onExit
		if (typeof this.currentView?.onExit === 'function') {
			this.currentView.onExit(this, this.store, viewKey);
		}
		// onEnter
		if (typeof viewToTransitionTo.onEnter === 'function') {
			Object.assign(viewProps, viewToTransitionTo.onEnter(viewToTransitionTo, this.store, parameters));
			// onAfterEnterEach
			if (typeof this.options?.onAfterEnterEach === 'function') {
				this.options.onAfterEnterEach(viewToTransitionTo, this.store, parameters);
			}
		}

		if (!isOnServer && this.options?.scrollToTopOfModal) {
			let event;

			if (typeof (Event) === 'function') {
				event = new Event('scroll-to-top-of-modal');
			} else {
				event = document.createEvent('Event');
				event.initEvent('scroll-to-top-of-modal', true, true);
			}
			document?.querySelector?.('body')?.dispatchEvent?.(event);
		}

		this.previousView = this.currentView;
		if (this.options?.clearBeforeTransition) {
			this.clearCurrentView();
		}
		this.currentView = viewToTransitionTo;
		this.viewProps = viewProps;
	}

	reload(params: object | null = null) {
		// onReload
		if (typeof this.currentView?.onReload === 'function') {
			this.currentView.onReload(this, this.store, params);
		}
	}
}
