import {
	action, computed, extendObservable, makeObservable, toJS,
} from 'mobx';

import { FieldBuilder } from '~/util/formz/builders/fieldBuilders';
import { addFieldForValidation } from '~/util/formz/plugins/formValidator/addFieldForValidation';
import { isNumber } from '~/util/isNumber';

export class MultiFieldPlugin {
	field

	form

	id = 'multiField'

	name

	settings

	get multiFieldKeyPrefix() {
		return `${this.name}-${this.settings.segment}-`;
	}

	get multiFields() {
		return Object.entries(this.form.fields).map(([key, val]) => {
			if (key.includes(this.multiFieldKeyPrefix)) {
				return val;
			}
			return null;
		}).filter(item => item !== null);
	}

	get numFields() {
		return Object.keys(this.form.fields).filter((field) => {
			return field.includes(this.multiFieldKeyPrefix);
		}).length;
	}

	addField(defaultValue = '') {
		let keyWithIndex;
		let iterator = this.numFields;

		// Get the next unused index based on current number of fields.
		while (keyWithIndex === undefined) {
			const key = (this.multiFieldKeyPrefix + iterator).toString();

			if (this.form.fields[key]) {
				iterator++;
			} else {
				keyWithIndex = key;
			}
		}
		const newModel = {
			[keyWithIndex]: defaultValue || this.field.control.defaultValue || '',
		};
		const newField = {
			[keyWithIndex]: new FieldBuilder(keyWithIndex, this.field.fieldData, this.form),
		};

		extendObservable(this.form.model, newModel);
		extendObservable(this.form.fields, newField);
		if (this.form.plugins?.formValidator) {
			addFieldForValidation(
				this.form.fields[keyWithIndex],
				keyWithIndex,
				this.form,
				this.form.plugins.formValidator,
			);
		}
		return this.form.fields[keyWithIndex];
	}

	applySettings(settings) {
		return {
			segment: 'INDEX',
			/**
			 * The size property is the initial amount of the same field to create.
			 *
			 * You may choose to use 0, for example, as the initial size in cases where you have a button that will add
			 * fields. If size is "auto", it will calculate the length of the fields to display based on the form model.
			 *
			 * Additionally, "auto" will populate the default values also based on the form model.
			 */
			size: 1,
			initialSize: 1,
			...settings,
		};
	}

	init() {
		let arrayLength = this.settings.size;
		const defaultValues = this.form.model[this.name].map((value) => {
			if (this.settings.size === 'auto') {
				return value;
			}
			return '';
		});

		if (this.settings.size === 'auto') {
			arrayLength = this.form.model[this.name].length || this.settings.initialSize;
		}
		this.normalize();
		[...Array(arrayLength).keys()].forEach((index) => {
			this.addField(defaultValues[index] || '');
		});
	}

	normalize() {
		const self = this;
		const filteredPlugins = this.field.fieldData.settings.plugins.filter(plugin => plugin.pluginId !== this.id);

		// Remove own plugin settings to prevent recursion
		this.field.fieldData.settings.plugins = filteredPlugins;
		// Convert field model to getter that grabs all multi-fields as an array.
		Object.defineProperty(this.form.model, this.name, {
			get() {
				return Object.entries(this).map(([key, val]) => {
					if (key === self.name) {
						return null;
					}
					return key.includes(self.multiFieldKeyPrefix) ? val : null;
				}).filter(item => item !== null);
			},
		});
	}

	/**
	 * target can be a number of the index of the field, a field object, or the full name of the field.
	 * @param target
	 */
	removeField(target) {
		let key;

		if (!target && !isNumber(target)) {
			return;
		}
		if (target instanceof FieldBuilder) {
			key = target.name;
		} else if (isNumber(target)) {
			key = (this.multiFieldKeyPrefix + target).toString();
		} else {
			key = target;
		}

		delete this.form.fields[key];
		delete this.form.model[key];
	}

	constructor(name, field, form, settings) {
		this.name = name;
		this.field = toJS(field);
		this.form = form;
		this.settings = this.applySettings(settings);

		// global.plugin = this;
		makeObservable(this, {
			normalize: action.bound,
			applySettings: action.bound,
			multiFieldKeyPrefix: computed,
			multiFields: computed,
			numFields: computed,
			init: action.bound,
			removeField: action.bound,
		});
		this.init();
	}
}
