'use client';

import React from 'react';
import { observer } from 'mobx-react';

import { BaseField } from '~/util/formz/Components/fields/BaseField';
import { Input } from '~/util/formz/Components/controls/Input';
import { MaxLengthRemainingCountdown } from '~/util/formz/Components/plugins/MaxLengthRemainingCountdown';

export const Field = observer((props) => {
	const {
		field: {
			control: { inputId }
		}
	} = props;

	const ariaDescribedBy = `${inputId}-descriptor`;
	return (
		<BaseField {...props}>
			<Input {...props} />
			<MaxLengthRemainingCountdown ariaDescribedById={ariaDescribedBy} {...props} />
		</BaseField>
	);
});
