export const match = (value, options, key, attributes) => {
	const {
		matchModelName,
		matchModelKey,
		message = null,
	} = options;
	const matchModelValue = attributes[matchModelKey];

	if (matchModelValue !== value) {
		if (message) {
			return message;
		}
		return `^Please match ${matchModelName}`;
	}
	return null;
};
