export class SignInEvent {
	checkForBackToBackDuplicates: boolean

	customerTrackingKey: string

	constructor(customerTrackingKey: string) {
		this.checkForBackToBackDuplicates = false;
		this.customerTrackingKey = customerTrackingKey;
	}

	get trackingJson(): any {
		return {
			event: 'signin',
			vendor: {
				reflektion: {
					trackingKey: this.customerTrackingKey,
				},
			},
		};
	}

	equalTo(): boolean {
		return false;
	}
}
