import axios from 'axios';
import {
	action, autorun, observable, makeObservable,
} from 'mobx';
// import debouncePromise from 'debounce-promise';

import { TransZone } from '~/global/global-dynamic/Models/TransZone.model';
import { isOnServer } from '~/global/global.constants';

class TransZoneStore {
	constructor(model) {
		makeObservable(this, {
			model: observable.ref,
			initiallyLoaded: observable,
			fetchData: action.bound,
			processData: action.bound,
			setTransZone: action.bound,
			getTransZone: action.bound,
			init: action.bound,
		});

		this.model = model;
	}

	model = {};

	initiallyLoaded = false;

	observers = []

	fetchData() {
		return axios.get(this.model.currentTransZoneLink).then((response) => {
			this.processData(response.data);
			this.hasInitiallyLoaded = true;
			return response;
		}, (error) => {
			console.error(error);
		});
	}

	processData(data) {
		if (!data) {
			return;
		}
		this.model.transportationZoneKey = data.transportationZoneKey;
		this.model.deliveryPrice = data.deliveryPrice;
		this.model.contentAreaKey = data.contentAreaKey;
		this.model.contentAreaValue = data.contentAreaValue;
		this.model.zipCode = data.zipCode;
		this.model.nationalUnconfirmedContentArea = data.nationalUnconfirmedContentArea;
		this.model.forbidden = data.forbidden;
	}

	setTransZone(zipCode) {
		if (!zipCode && !this.model.setTransZoneLink && !this.model.currentTransZoneLink) {
			return Promise.reject();
		}
		// No ZIP code provided to set with, just get their current trans zone if they have one.
		if (!zipCode && this.model.currentTransZoneLink) {
			return this.getTransZone();
		}
		return axios.post(this.model.setTransZoneLink, { zipCode }).then((response) => {
			this.processData(response.data);
			return response;
		});
	}

	getTransZone() {
		if (!this.model.hasTransZone) {
			return Promise.reject();
		}
		return this.fetchData();
	}

	init() {
		if (!isOnServer) {
			this.observers.push(
				autorun(() => {
					if (this.model.hasTransZone) {
						this.fetchData();
					}
				}),
			);
		}
	}
}

const TransZoneStoreFactory = {
	create(setTransZoneLink, currentTransZoneLink) {
		const store = new TransZoneStore(new TransZone(setTransZoneLink, currentTransZoneLink));

		store.init();

		return store;
	},
	destroy(store) {
		store.observers?.forEach?.(dispose => dispose());
	},
};

export { TransZoneStoreFactory };
