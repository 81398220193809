import { intercept } from 'mobx';

class FieldInterceptorPlugin {
	constructor(name, fieldObs, form, settings) {
		this.name = name;
		this.fieldObs = fieldObs;
		this.form = form;
		this.settings = settings;

		intercept(this.form.model, this.name, (change) => {
			if (this.settings.onIntercept === undefined) {
				return change;
			}
			return this.settings.onIntercept(change, {
				name: this.name,
				fieldObs: this.fieldObs,
				form: this.form,
			});
		});
		console.info(`interceptor plugin has been applied to ${name}`);
	}
}

export { FieldInterceptorPlugin };
