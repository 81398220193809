import { deliveryAddress } from '~/lib/validatejs/custom-validators/deliveryAddress';
import { dependentLength } from '~/lib/validatejs/custom-validators/dependentLength';
import { dependentPhoneUS } from '~/lib/validatejs/custom-validators/dependentPhoneUs';
import { dependentPresence } from '~/lib/validatejs/custom-validators/dependentPresence';
import { match } from '~/lib/validatejs/custom-validators/match';
import { zipCode } from '~/lib/validatejs/custom-validators/zipCode';

export {
	deliveryAddress,
	dependentLength,
	dependentPhoneUS,
	dependentPresence,
	zipCode,
	match,
};
