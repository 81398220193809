import React from 'react';
import { observer } from 'mobx-react';

import { BaseField } from '~/util/formz/Components/fields/BaseField';
import { Textarea } from '~/util/formz/Components/controls/Textarea';

const TextareaField = observer(class TextareaField extends React.Component {
	render() {
		return (
			<BaseField {...this.props}>
				<Textarea {...this.props} />
			</BaseField>
		);
	}
});

export { TextareaField };
