import { isEmpty } from '~/util/isEmpty';
import { getMessageText } from '~/lib/validatejs/custom-validators/getMessageText';

export const dependentPresence = (value, optionsArray, key, attributes) => {
	let validationMessage = null;
	const theOptions = !Array.isArray(optionsArray) ? [optionsArray] : optionsArray;

	theOptions.forEach((options) => {
		const {
			dependentModelKey,
			dependentModelValue: testModelValue,
			validateIfDependentModelEmpty,
		} = options;
		const dependentModelValue = attributes[dependentModelKey];
		let shouldValidate = false;
		const modelObject = {};
		const validationSettingsObject = {};

		if (validateIfDependentModelEmpty) {
			// if we set this option to true, we're validating one field if the dependant field IS empty
			if (isEmpty(testModelValue)) {
				shouldValidate = isEmpty(dependentModelValue);
			}
		} else if (isEmpty(testModelValue)) {
			// we aren't checking for a specific testModelValue, just presence
			shouldValidate = !isEmpty(dependentModelValue);
		} else if (testModelValue === dependentModelValue) {
			// check the testModelValue for a specific value
			shouldValidate = true;
		}
		if (shouldValidate) {
			modelObject[key] = value;
			if (options.message) {
				validationSettingsObject[key] = {
					presence: {
						message: options.message,
					},
				};
			} else {
				validationSettingsObject[key] = {
					presence: true,
				};
			}
			validationMessage = getMessageText(modelObject, validationSettingsObject, key);
			validationMessage = validationMessage && `^${validationMessage}`;
		}
	});
	return validationMessage;
};
