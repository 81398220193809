import { computed, makeObservable, observable } from 'mobx';

import type { SubMenu } from '~/global/top-menu/Models/SubMenu.model';
import type { SubMenuItem } from '~/global/top-menu/Models/SubMenuItem.model';
import type { ContentPromo } from '~/global/top-menu/Models/ContentPromo.model';
import { groupByFunction } from '~/util/groupBy';

export class TopMenu {
	data: any = {};

	subMenus: SubMenu[] = [];

	contentPromos: ContentPromo[] = [];

	isActive = false;

	// eslint-disable-next-line default-param-last
	constructor(data: any = {}, subMenus: Array<SubMenu>, contentPromos: Array<ContentPromo>) {
		makeObservable(this, {
			isActive: observable,
			subMenuColumns: computed,
			columnCount: computed,
		});

		this.data = data;
		this.subMenus = subMenus;
		this.contentPromos = contentPromos;
	}

	get id() {
		return this.data.id;
	}

	get url() {
		return this.data.url;
	}

	get title() {
		return this.data.title;
	}

	get dataKey() {
		return this.data.dataKey;
	}

	get subMenuColumns() {
		// Group menus into columns by columnKeys and remove hidden menu
		const visibleCollMenus = this.subMenus.filter((subMenu: SubMenu) => !subMenu.hiddenOnFull);
		const groupedCollMenus = groupByFunction(visibleCollMenus, (visibleItem: any) => visibleItem.columnKey || visibleItem.id);
		const mappedCollMenus = Object.entries(groupedCollMenus).map(([collMenuKey, collMenuValue = []]) => {
			return {
				columnKey: collMenuKey,
				columnMenus: collMenuValue,
				id: (collMenuValue as Array<SubMenuItem>)[0].id,
			};
		});

		return mappedCollMenus;
	}

	get columnCount() {
		return this.subMenuColumns.filter((column: any) => {
			return column.columnKey !== 'bottomMenu' && !column.hiddenOnFull;
		}).length;
	}

	get hasSubMenus() {
		return this.subMenus.length > 0;
	}

	get classes() {
		return this.data.classes;
	}

	get promoClasses() {
		return this.data.promoClasses;
	}

	get hiddenOnFull() {
		return this.data.hideFull;
	}

	get hiddenOnMobile() {
		return this.data.hideMobile;
	}

	get isSubMenuVertical() {
		return this.data.url;
	}

	get isSecondaryMenu() {
		return Boolean(this.data.secondaryMenu);
	}
}
