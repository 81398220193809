import validate from '../../../node_modules/validate.js';

import * as customValidators from '~/lib/validatejs/customValidators';

validate.options = {
	format: 'detailed',
};
Object.entries(customValidators).forEach((entry) => {
	const [name, validator] = entry;

	validate.validators[name] = validator;
});

export default validate; // eslint-disable-line import/no-default-export
