import React from 'react';
import {
	action, computed, observable, makeObservable
} from 'mobx';
import axios from 'axios';
import dynamic from 'next/dynamic';

import { MagicSpinner } from '~/components/MagicSpinner';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { noop } from '~/util/noop';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

const FeatureToggles = dynamic(
	() => import('~/util/feature-toggles/Components/FeatureToggles').then(mod => mod.FeatureToggles),
);

class FeatureToggleStore {
	constructor(link = null) {
		makeObservable(this, {
			clipboardMessage: observable,
			copyToggles: action.bound,
			fetchCopyTogglesBySystem: action.bound,
			pasteToggles: action.bound,
			toggles: observable.ref,
			standardToggles: computed,
			internalToggles: computed,
			supportToggles: computed,
			uiDebugToggles: computed,
			hasActiveLocations: action.bound,
			hasJiraNumbers: action.bound,
			jiraNumber: action.bound,
			stripJiraNumbers: action.bound,
			showBasicAuthForm: observable,
			formError: observable,
			magicModal: observable,
			globalStaticModel: observable,
			authFormModel: observable,
			copyTogglesFormModel: observable,
			fetchToggles: action.bound,
			attemptToFetchToggles: action.bound,
			errorHandler: action.bound,
			setToggle: action.bound,
			setDefaultToggle: action.bound,
			openModal: action.bound
		});

		this.link = link;
	}

	link = null;

	links = '';

	clipboardMessage = undefined;

	copyToggles(json = []) {
		const onClipboardWriteSuccess = () => {
			this.clipboardMessage = 'Successfully copied to clipboard.';
		};

		if (json.length) {
			return navigator.clipboard.writeText(JSON.stringify(json)).then(onClipboardWriteSuccess);
		}
		return axios.get('/api/web/feature-toggles/copy').then((resp) => {
			const data = JSON.stringify(resp.data);

			return navigator.clipboard.writeText(data).then(onClipboardWriteSuccess);
		});
	}

	fetchCopyTogglesBySystem(system) {
		if (!system) {
			return Promise.resolve();
		}

		return axios.get(`/api/feature-toggles-copy?njs=true&environment=${system}`);
	}

	pasteToggles(json) {
		const pasteEndpoint = '/api/web/feature-toggles/paste';

		console.info(json);
		if (typeof json !== 'undefined' && !json.length) {
			return Promise.reject('No toggles to copy.');
		}
		if (json.length) {
			return axios.put(pasteEndpoint, json);
		}
		return navigator.clipboard.readText().then((clipText) => {
			const clipTextJson = JSON.parse(clipText);

			return axios.put(pasteEndpoint, clipTextJson);
		});
	}

	context;

	toggles = [];

	get standardToggles() {
		return this.toggles.filter(toggle => toggle.featureType === 'STANDARD');
	}

	get internalToggles() {
		return this.toggles.filter(toggle => toggle.featureType === 'INTERNAL');
	}

	get supportToggles() {
		return this.toggles.filter(toggle => toggle.featureType === 'SUPPORT');
	}

	get uiDebugToggles() {
		return this.toggles.filter(toggle => toggle.featureType === 'UI_DEBUG');
	}

	hasJiraNumbers(toggles) {
		return toggles.some(toggle => this.jiraNumber(toggle.featureDescription));
	}

	hasActiveLocations(toggles) {
		return toggles.some(toggle => toggle.locations.length);
	}

	jiraPattern = /([A-Z]+-\d+)/g;

	jiraNumber(text) {
		if (!text) {
			return '';
		}
		const match = text.match(this.jiraPattern) || [];

		return [...new Set(match)]?.[0] || '';
	}

	stripJiraNumbers(text) {
		if (!text) {
			return '';
		}
		let result = text.replace(this.jiraPattern, '');
		const doubleSpaces = /\s\s/g;

		while (doubleSpaces.test(result)) {
			result = result.replace(doubleSpaces, '');
		}
		return result.trim();
	}

	showBasicAuthForm = false;

	formError = '';

	magicModal = undefined;

	globalStaticModel = undefined;

	authFormModel = {
		username: '',
		password: '',
		showPassword: false,
	};

	copyTogglesFormModel = {
		copyToggleSystem: '',
	};

	get hostname() {
		const result = window.location.hostname.match(/(.+).roomandboard.com/)?.[1];

		if (!result) {
			return null;
		}
		return result;
	}

	copyTogglesFormSettings = {
		id: 'copyFeatureTogglesForm',
		reactProps: {
			className: 'tw-flex tw-items-center tw-space-x-4',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler: (form) => {
							this.clipboardMessage = '';
							return this.fetchCopyTogglesBySystem(form.model.copyToggleSystem)
								.then((resp = {}) => {
									console.info(resp);
									const { data = [] } = resp;

									return this.pasteToggles(data);
								}, (error) => {
									console.error(error);
								});
						},
						promiseHandler: (promise) => {
							promise
								.then((resp) => {
									console.log('resp:', resp);
									this.clipboardMessage = (
										<div className="toggle-message-text">
											<span className="tw-text-green">Successfully copied! </span>
											<button className="ButtonAnchor" type="button" onClick={() => window.location.reload()}>Refresh page</button>
										</div>
									);
									return resp;
								})
								.catch((error = 'An error occurred trying to copy.') => {
									this.clipboardMessage = (
										<div className="toggle-message-text">
											<span className="tw-text-red">
												{error}
											</span>
										</div>
									);
								});
						}
					},
				}),
			],
		},
		fields: {
			copyToggleSystem: {
				reactProps: {
					className: 'tw-flex tw-items-center tw-mb-0 tw-space-x-4',
				},
				label: {
					reactProps: {
						className: 'tw-p-0',
						children: 'Copy default toggles from',
					},
				},
				control: {
					reactProps: {
						className: 'tw-w-auto',
						type: 'select',
					},
				},
			},
		},
	};

	SYSTEM_NAMES = {
		preprod: null,
		prestaging: null,
		proofing: null,
		staging: null,
		test01: null,
		test02: null,
		test03: null,
		test04: null,
		test05: null,
		test06: null,
		test07: null,
		test08: null,
		test09: null,
		test10: null,
		test11: null,
		training: null,
		webteam01: null,
	};

	authFormSettings = {
		id: 'featureToggleAuthForm',
		reactProps: {
			method: 'POST'
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler: () => {
							this.formError = '';
							return this.fetchToggles(this.authForm.model.username, this.authForm.model.password);
						},
						promiseHandler: (promise) => {
							promise.then((response) => {
								this.toggles = response.data;
								this.showBasicAuthForm = false;
							}, (error) => {
								this.errorHandler(error);
							});
						}
					},
				}),
			],
		},
		fields: {
			username: {
				label: {
					reactProps: {
						children: 'Username',
					},
				},
				control: {
					reactProps: {
						type: 'text',
					},
				},
				settings: {
					validationConstraints: {
						presence: true,
					},
				},
			},
			password: {
				label: {
					reactProps: {
						children: 'Password',
					},
				},
				control: {
					reactProps: {
						type: 'password',
					},
				},
				settings: {
					validationConstraints: {
						presence: true,
					},
				},
			},
			showPassword: {
				label: {
					reactProps: {
						children: 'Show password',
					},
				},
			},
		},
	};

	fetchToggles(username = '', password = '') {
		return new Promise((resolve, reject) => {
			this.link = this.globalStaticModel.featureTogglesLink;
			if (this.link) {
				this.attemptToFetchToggles(resolve, reject, username, password);
			} else {
				this.globalStaticModel.fetchData().then(() => {
					this.attemptToFetchToggles(resolve, reject, username, password);
				});
			}
		});
	}

	attemptToFetchToggles(resolve, reject, username = '', password = '') {
		axios.request({
			url: this.link,
			...(username && password && {
				auth: {
					username,
					password,
				},
			}),
		}).then((response) => {
			this.showBasicAuthForm = false;
			this.toggles = response.data;
			resolve(response);
		}, (error) => {
			this.errorHandler(error);
			reject(error);
		});
	}

	getToggleByName(name) {
		return this.toggles.find(toggle => toggle.featureName === name) || {};
	}

	errorHandler(error) {
		if (error?.response?.status === 401) {
			if (!this.showBasicAuthForm) {
				this.showBasicAuthForm = true;
			} else {
				this.formError = 'Username or password is incorrect. Please try again.';
			}
		} else {
			console.error(error);
		}
	}

	setToggle(toggle, value, options = {}) {
		let promise;
		const config = {
			fetchTogglesOnSuccess: true,
			...options,
		};
		const data = {
			name: toggle.featureName,
			state: value,
		};
		const {
			_links: {
				browserFeatureToggles: {
					href: setBrowserFeatureToggleLink = '',
				} = {},
				removeBrowserFeatureToggle: {
					href: removeBrowserFeatureToggleLink = '',
				} = {},
			} = {},
		} = toggle;

		if (!setBrowserFeatureToggleLink || !removeBrowserFeatureToggleLink) {
			return Promise.reject('Cannot modify toggle. No link found.');
		}
		if (value === toggle.defaultState) {
			promise = axios.post(removeBrowserFeatureToggleLink, data);
		} else {
			promise = axios.put(setBrowserFeatureToggleLink, [data]);
		}

		if (config.fetchTogglesOnSuccess) {
			promise.then(() => {
				this.fetchToggles();
			});
		}

		return promise;
	}

	setDefaultToggle(toggle, value) {
		let promise;
		const data = {
			name: toggle.featureName,
			state: value,
		};
		const {
			_links: {
				defaultFeatureToggles: {
					href: setDefaultFeatureToggleLink = '',
				} = {},
				removeDefaultFeatureToggle: {
					href: removeDefaultFeatureToggleLink = '',
				} = {},
			} = {},
		} = toggle;

		if (!setDefaultFeatureToggleLink || !removeDefaultFeatureToggleLink) {
			return Promise.reject('Cannot modify toggle. No link found.');
		}
		if (value === toggle.defaultState) {
			promise = axios.post(removeDefaultFeatureToggleLink, data);
		} else {
			promise = axios.put(setDefaultFeatureToggleLink, [data]);
		}

		promise.then(() => {
			this.fetchToggles();
		});

		return promise;
	}

	openModal(event) {
		const modalConfig = {
			title: 'Feature Toggles',
			content: {
				children: <MagicSpinner isLoading minHeight="200px" height="200px" />,
			},
			maxWidth: '1008px',
		};

		this.magicModal.openModal(modalConfig, event);
		this.fetchToggles().then(noop, noop).then(() => {
			this.magicModal.alterModal({
				content: {
					children: <FeatureToggles store={this} />,
				},
			});
		});
	}
}

export const FeatureToggleStoreFactory = {
	create(magicModal, globalStaticModel) {
		const store = new FeatureToggleStore();

		store.authForm = new FormBuilder(store.authFormModel, store.authFormSettings);
		store.copyTogglesForm = new FormBuilder(store.copyTogglesFormModel, store.copyTogglesFormSettings);
		store.magicModal = magicModal;
		store.globalStaticModel = globalStaticModel;
		return store;
	}
};
