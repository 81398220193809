'use client';

import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { LoadingSpinner } from '~/util/Components/LoadingSpinner';

export const MagicSpinner = observer((props = {}) => {
	const {
		delay = 1000,
		isLoading = false,
		minHeight = '450px',
	} = props;

	const [isTimeoutComplete, setIsTimeoutComplete] = useState(!isLoading);

	function createLoadingTimeout() {
		return setTimeout(() => {
			setIsTimeoutComplete(true);
		}, delay);
	}

	const timeoutRef = useRef(isLoading && createLoadingTimeout());

	useEffect(() => () => clearTimeout(timeoutRef));

	if (isTimeoutComplete) {
		return (
			<LoadingSpinner {...props} />
		);
	}

	return <div className="magic-spinner-container" style={{ minHeight }} />;
});
