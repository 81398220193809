/* eslint-disable @typescript-eslint/no-unused-vars */
import { IncomingMessage } from 'http';

// @ts-ignore
// import axios, { axiosSsr } from 'axios';
import axios, { AxiosStatic } from 'axios';
import { GetServerSidePropsContext } from 'next/types';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';

import { isOnServer } from '~/global/global.constants';

export interface Context extends GetServerSidePropsContext {
	cookies?: NextApiRequestCookies,
	req: IncomingMessage & {
		cookies: NextApiRequestCookies,
		originalUrl?: string,
	},
}
export type Header = ReturnType<Context['res']['getHeader']>;
interface IpHeaders {
	trueClientIpHeader: Header
	xForwardedForHeader: Header
	xBypassRatelimitingHeader: Header
}

export const getIpHeaders = (ctx: Context | undefined): IpHeaders => {
	if (!ctx) {
		return {
			trueClientIpHeader: '',
			xForwardedForHeader: '',
			xBypassRatelimitingHeader: '',
		};
	}
	return {
		trueClientIpHeader: ctx.req?.headers['true-client-ip'] || '',
		xForwardedForHeader: ctx.req?.headers['x-forwarded-for'] || '',
		xBypassRatelimitingHeader: ctx.req?.headers['x-bypass-ratelimiting'] || '',
	};
};

const setHeader = (
	headerName: string,
	header: Header | undefined,
	axiosClient: AxiosStatic,
) => {
	if (header) {
		axiosClient.defaults.headers.common[headerName] = header;
		// if (isOnServer) console.log(`Forwarding request header: ${headerName}: ${header}`);
	} else {
		delete axiosClient.defaults.headers.common[headerName];
		// if (isOnServer) console.log(`No request header found: ${headerName}: ${header}`);
	}
};

export const getAxiosWithIndividualHeaders = (
	trueClientIpHeader: Header,
	xForwardedForHeader: Header,
	xBypassRatelimitingHeader: Header,
) => {
	const dynaAxios = axios;
	setHeader('True-Client-IP', trueClientIpHeader, dynaAxios);
	setHeader('X-Forwarded-For', xForwardedForHeader, dynaAxios);
	setHeader('X-Bypass-Ratelimiting', xBypassRatelimitingHeader, dynaAxios);

	// if (isOnServer && ctx) {
	// 	const handlerLog = ctx.req ? `Handling request for original URL ${ctx.req.originalUrl}\n` : '';

	// 	console.log(handlerLog);
	// }
	return dynaAxios;
}

export const getAxios = (
	ctx?: Context,
) => {
	const {
		trueClientIpHeader,
		xForwardedForHeader,
		xBypassRatelimitingHeader,
	} = getIpHeaders(ctx);
	return getAxiosWithIndividualHeaders(trueClientIpHeader,xForwardedForHeader,xBypassRatelimitingHeader);
};
