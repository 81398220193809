import { action, observable, makeObservable } from 'mobx';
import signals from 'signals';
import { fromPromise } from 'mobx-utils';

import { safeWrapOrCreate } from '~/util/formz/util/util';

class AjaxSubmitPlugin {
	constructor(form, settings) {
		makeObservable(this, {
			obsPromise: observable,
			formErrorMessage: observable,
			submit: action,
		});

		this.form = form;
		this.settings = {
			validateOnSubmit: true,
			...settings,
		};

		safeWrapOrCreate(this.form.reactPropsMap, 'onSubmit', (event) => {
			event.preventDefault();
			if (this.settings.validateOnSubmit && this?.form?.plugins?.formValidator?.hasErrors) {
				return;
			}
			this.submit();
		});
	}

	obsPromise = {};

	formErrorMessage = null;

	submit() {
		const promise = this.settings.submitHandler(this.form);

		if (promise) {
			promise.then((response) => {
				this.submitSucceeded.dispatch(response);
			}, () => {
				// take care of axios erroring out saying that we aren't catching the error
				// Move the dispatch into here?  I don't want to break anything!
			});
			promise.catch((error) => {
				this.submitFailed.dispatch(error);
			});

			if (this.settings.promiseHandler) {
				this.settings.promiseHandler(promise, this.form);
			}

			// the .then will clone the promise to prevent the mobx stuff
			// from modifying the real promise
			const timeout = window.setTimeout(function () {
				this.obsPromise = fromPromise(promise);
			}, 150);

			promise.then(() => {
				clearTimeout(timeout);
			}, () => {
				// Should we be clearing the timeout on error?
			});
		}

		return promise;
	}

	submitSucceeded = new signals.Signal()

	submitFailed = new signals.Signal()
}

export { AjaxSubmitPlugin };
