import validate from 'validate.js';
import { groupByKey } from '~/util/groupBy';

export const getMessageText = (modelObject, validationSettingsObject, key) => {
	const message = groupByKey(validate(modelObject, validationSettingsObject), 'attribute');

	if (message[key]) {
		return message[key][0].error;
	}
	return null;
};
