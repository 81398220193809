import { action } from 'mobx';

import { inRange } from '~/util/inRange';
import { isEmpty } from '~/util/isEmpty';
import { isFunction } from '~/util/isFunction';
import { wrap } from '~/util/wrap';
import { AjaxSubmitPlugin } from '~/util/formz/plugins/AjaxSubmitPlugin';
import { FormValidatorPlugin } from '~/util/formz/plugins/formValidator/FormValidatorPlugin';
import { SERVER_SIDE_VALIDATION_MESSAGING } from '~/util/formz/util/formz.constants';

const ajaxSubmitErrorProcessor = {
	processFormSubmitErrors: (error) => {
		const responseJson = error?.response?.data;
		const status = error?.response?.status;

		// only deal with 5xx errors and the 429 too many requests
		if (status === 429) {
			return ajaxSubmitErrorProcessor.buildFormErrorMessage(error, {
				error: 'We have received too many error requests from this IP address. Please try again later.',
			});
		} if (!isEmpty(responseJson) || inRange(status, 500, 600)) {
			return ajaxSubmitErrorProcessor.buildFormErrorMessage(error);
		}
		return null;
	},
	buildFormErrorMessage: (error, overrides) => {
		const status = error?.response?.status;
		const statusText = error?.response?.statusText;

		return {
			attribute: 'form',
			value: status,
			error: `We have experienced a server error on this submission.  Please try again later. Details: ${status} ${statusText}`,
			errorType: statusText,
			isServerError: true,
			...overrides,
		};
	},
};

class ValidateAndAjaxSubmitPlugin {
	id = 'validateAndAjaxSubmit'

	constructor(settings) {
		this.settings = {
			validateOnSubmit: true,
			...settings,
		};
	}

	ajaxSubmitPlugin = null

	validatorPlugin = null

	setForm(form) {
		this.form = form;
		this.attemptToFinishSetup();
	}

	attemptToFinishSetup = () => {
		if (!this.form) {
			return;
		}
		const {
			settings: {
				ajaxSubmit: {
					promiseHandler,
					...omittedAjaxSubmit
				},
			} = {},
		} = this;

		this.ajaxSubmitPlugin = new AjaxSubmitPlugin(this.form, {
			promiseHandler: (promise, form2) => {
				if (isFunction(this.settings.ajaxSubmit.promiseHandler)) {
					this.settings.ajaxSubmit.promiseHandler(promise, form2);
				}
				promise.catch((error) => {
					const responseJson = error?.response?.data;
					let mappedErrors;

					if (!isEmpty(responseJson)) {
						if (!isEmpty(responseJson.errors)) {
							mappedErrors = form2.plugins.formValidator.mapErrorsToFieldErrors(responseJson.errors);
							form2.plugins.formValidator.mapServerSideValidationMessages(mappedErrors);
						} else {
							form2.plugins.formValidator.mapServerSideValidationMessages(responseJson.fieldErrors);
						}
					}
					this.ajaxSubmitPlugin.formErrorMessage = ajaxSubmitErrorProcessor.processFormSubmitErrors(error);
				});
			},
			...omittedAjaxSubmit,
			validateOnSubmit: this.settings.validateOnSubmit,
		});

		// add the form level error observable
		this.ajaxSubmitPlugin.formErrorMessage = null;

		// check for validity before submitting the form
		this.form.reactPropsMap.set('onSubmit',
			wrap(this.form.reactPropsMap.get('onSubmit'), (originalSubmitHandler, event) => {
				event.preventDefault();
				if (this.settings.validateOnSubmit) {
					this.form.plugins.formValidator.validateForm();
				}
				originalSubmitHandler(event);
			}));

		this.validatorPlugin = new FormValidatorPlugin(this.form, this.settings.formValidator);

		// TODO(aboyer) [mobx-upgrade] This was causing run-time errors. Find another way to make this work.
		// add the server side capabilities to validate
		// clobber the blur handler with a server side capable one
		// this.validatorPlugin.fieldBlurValidate = action('server error blur handler',
		// 	wrap(this.validatorPlugin.fieldBlurValidate, (originalFieldBlurValidate, name) => {
		// 		// don't validate server side errors on blur only on change
		// 		const currentFieldMessage = this.validatorPlugin.validationMessages.get(name);
		//
		// 		if (isUndefined(currentFieldMessage) || !currentFieldMessage.isServerError) {
		// 			originalFieldBlurValidate(name);
		// 		}
		// 	}));
		this.validatorPlugin.mapErrorsToFieldErrors = action('map errors to fieldErrors', (errors) => {
			return errors.map((error) => {
				return {
					field: error.errorKey,
					defaultMessage: error.errorMessage,
					fieldError: error.fieldError,
				};
			});
		});
		this.validatorPlugin.mapServerSideValidationMessages = action('map server side error messages', (fieldErrors) => {
			fieldErrors?.forEach?.((value) => { // eslint-disable-line no-unused-expressions
				this.validatorPlugin.addServerSideValidationMessage(value);
			});
		});
		this.validatorPlugin.addServerSideValidationMessage = action('Adding server side validation message', (serverSideValidationError) => {
			const { defaultMessage } = serverSideValidationError;
			let validationMessage = SERVER_SIDE_VALIDATION_MESSAGING[defaultMessage] || defaultMessage;

			if (isFunction(validationMessage)) {
				// get some data for the message function
				const messageData = {
					value: this.form.model[serverSideValidationError.field],
					label: this?.form?.fields?.[serverSideValidationError]?.field?.label?.reactProps?.children,
				};

				validationMessage = validationMessage(messageData, this);
			}
			this.validatorPlugin.validationMessages.set(serverSideValidationError.field, {
				attribute: serverSideValidationError.field,
				value: this.form.model[serverSideValidationError.field],
				error: validationMessage,
				errorType: serverSideValidationError.defaultMessage,
				isServerError: true,
				fieldError: serverSideValidationError.fieldError,
			});
		});

		this.form.plugins = this.form.plugins || {};
		this.form.plugins.formValidator = this.validatorPlugin;
		this.form.plugins.ajaxSubmit = this.ajaxSubmitPlugin;
	}
}

export { ValidateAndAjaxSubmitPlugin };
