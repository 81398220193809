import { LegendBuilder } from '~/util/formz/builders/LegendBuilder';
import { LabelBuilder } from '~/util/formz/builders/LabelBuilder';
import { InputControlBuilder } from '~/util/formz/builders/controls/InputControlBuilder';
import { CheckboxControlBuilder } from '~/util/formz/builders/controls/CheckboxControlBuilder';
import { RadiosControlBuilder, RadioControlBuilder } from '~/util/formz/builders/controls/RadiosControlBuilder';
import { SelectControlBuilder } from '~/util/formz/builders/controls/SelectControlBuilder';
import { TextareaControlBuilder } from '~/util/formz/builders/controls/TextareaControlBuilder';

class FormzBuilders {
	builders = [
		CheckboxControlBuilder,
		LabelBuilder,
		LegendBuilder,
		InputControlBuilder,
		RadiosControlBuilder,
		RadioControlBuilder,
		SelectControlBuilder,
		TextareaControlBuilder,
	]

	getBuilderByKey(key, value) {
		const computedKey = this.getBuilderKey(key, value);
		const selectedBuilder = this.builders.filter(builder => builder.BUILDER_KEY === computedKey)[0];

		if (!selectedBuilder) {
			return InputControlBuilder;
		}
		return selectedBuilder;
	}

	getBuilderKey(key, value) {
		const controlType = value?.reactProps?.type || null;

		if (key === 'control' && controlType !== null) {
			return controlType || 'input';
		}
		return key;
	}

	createBuilder(key, ...args) {
		const SelectedBuilder = this.getBuilderByKey(key);

		if (!SelectedBuilder) {
			return null;
		}
		if (key === 'radios') {
			const builder = new SelectedBuilder(...args);
			const { radiosData = [] } = builder;

			radiosData.forEach((radioData) => {
				builder.radios.push(...radioData);
			});
			return builder.radios;
		}
		return new SelectedBuilder(...args);
	}
}

export { FormzBuilders };
