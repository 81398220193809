import {
	action, computed, observe, observable, makeObservable,
} from 'mobx';
import validate from 'validate.js';

import { keyBy } from '~/util/keyBy';
import { sortBy } from '~/util/sortBy';
import { isEmpty } from '~/util/isEmpty';
import { addCustomValidators } from '~/util/formz/util/custom-validators';
import { addFieldForValidation } from '~/util/formz/plugins/formValidator/addFieldForValidation';
import { uniqBy } from '~/util/uniqBy';
import { pick } from '~/util/pick';

class FormValidatorPlugin {
	id = 'formValidator';

	shouldValidate = true;

	validationMessages = new Map();

	form;

	constructor(form, settings = {}) {
		makeObservable(this, {
			shouldValidate: observable,
			validationMessages: observable,
			allErrorMessages: computed,
			formErrorMessages: computed,
			hasOnlyServerErrors: computed,
			fieldBlurValidate: action.bound,
			validateField: action.bound,
			validateForm: action.bound,
		});

		this.form = form;
		this.settings = settings;
		addCustomValidators(validate);

		this.attemptToFinishSetup();
	}

	settings;

	setForm(form) {
		this.form = form;

		this.attemptToFinishSetup();
	}

	attemptToFinishSetup() {
		if (this.form) {
			// watch the model to determine when to validate
			// this is SOOOO COOOOLLLL
			observe(this.form.model, (change) => {
				if (this.validationMessages.has(change.name)) {
					this.validateField(change.name);
				}
			});
			// apply the blur to all the fields
			// add the plugin to each field for display
			Object.entries(this.form.fields).forEach(([name, field]) => {
				addFieldForValidation(field, name, this.form, this);
			});
		}
	}

	get allErrorMessages() {
		return Array.from(this.validationMessages.values());
	}

	get formErrorMessages() {
		return this.allErrorMessages.filter(message => !message.fieldError);
	}

	// Returns true if the field control is disabled.
	// Also returns true if the field is a radio and all the radios are disabled.
	isFieldDisabled(field) {
		const {
			control: {
				reactPropsMap = new Map(),
			} = {},
			radios = [],
		} = field;

		if (radios.length) {
			return radios.every(radio => radio.control.reactPropsMap.get('disabled'));
		}
		return reactPropsMap.get('disabled');
	}

	// Changed this to be non computed because of multi fields.
	// We'll have to deal with this better with maps at some point
	// The computed wouldn't properly re-evaluate when new fields were added
	validationSettings() {
		const acc = (fields, [key, field]) => {
			const {
				settings: {
					shouldValidate: shouldValidateField = true,
					validationConstraints = {},
				} = {},
			} = field;
			const {
				shouldValidate: shouldValidateForm = true,
				settings: {
					validateEnabledFieldsOnly = false,
				} = {},
			} = this;
			const isFieldDisabled = this.isFieldDisabled(field);
			// Multifields are considered zombies which only are used to create generated fields within its plugin.
			// Therefore, we do not want to validate against them.
			const isMultiField = Object.keys(field.plugins || {}).includes('multiField');
			let returnField = {};

			// Disable validation if flagged on the form-level or field-level.
			// Disable validation for only enabled fields
			if (
				!(isFieldDisabled && validateEnabledFieldsOnly)
				&& shouldValidateField
				&& shouldValidateForm
				&& !isMultiField
			) {
				returnField = validationConstraints;
			}
			return { ...fields, [key]: returnField };
		};
		const fieldEntries = Object.entries(this.form.fields);

		if (!fieldEntries.length) {
			return {};
		}
		const mappedFields = fieldEntries.reduce(acc, acc([], fieldEntries[0]));

		return mappedFields;
	}

	get hasErrors() {
		// we may or may not really have errors
		// you shouldn't need to show the messages in order
		// to know if the form is valid
		if (this.validationMessages.size > 0) {
			return true;
		}
		return !isEmpty(this._rawValidationOutput);
	}

	get hasOnlyServerErrors() {
		if (!this.formErrorMessages || !this.formErrorMessages?.length) {
			return false;
		}
		return this.formErrorMessages.every(formErrorMessage => formErrorMessage.isServerError);
	}

	get _rawValidationOutput() {
		const model = this.nullifyingFalseyModel(this.form.model);

		return validate(model, this.validationSettings());
	}

	deleteDisabledFieldValidationMessages() {
		const fieldNames = Object.keys(this.form.fields);
		const disabledFieldNames = fieldNames.filter((fieldName) => {
			return this.form.fields[fieldName].control.reactPropsMap.get('disabled');
		});

		disabledFieldNames.forEach((fieldName) => {
			this.validationMessages.delete(fieldName);
		});
	}

	deleteServerValidationMessages() {
		const serverErrors = this.allErrorMessages.filter(message => message.isServerError);

		serverErrors.forEach((error) => {
			this.validationMessages.delete(error.attribute);
		});
	}

	fieldBlurValidate(name) {
		this.validateField(name);
	}

	nullifyingFalseyModel(model) {
		const modelEntries = Object.entries(model);

		if (!modelEntries.length) {
			return model;
		}
		const reducer = (fields, [key, value]) => {
			let returnedValue = null;
			if (value || value === 0) {
				returnedValue = value;
			}
			// Object.assign to prevent mutating the original object.
			return { ...fields, [key]: returnedValue };
		};

		const mappedValues = modelEntries.reduce(reducer, reducer([], modelEntries[0]));
		return mappedValues;
	}

	validateField(name, { silent } = { silent: false }) {
		this.validationMessages.delete(name);
		if (!this.form.fields[name]?.settings?.shouldValidate) {
			return {};
		}
		const model = this.nullifyingFalseyModel(this.form.model);
		const validatedResults = validate(model, pick(this.validationSettings(), [name]));

		// Sort by an optional sort property if there are multiple validation errors.
		const sortedValidatedResults = sortBy(validatedResults, result => result?.options?.sort || 100);
		const validationMessage = sortedValidatedResults[0];

		if (validationMessage) {
			if (silent) {
				// Do not surface validation messaging to the component, but return what was validated silently.
				return validationMessage;
			}
			this.validationMessages.set(name, validationMessage);
		}
		return {};
	}

	validateForm() {
		this.validationMessages.clear();
		if (!this.shouldValidate) {
			return;
		}
		const validationMessages = uniqBy(this._rawValidationOutput, 'attribute');

		this.validationMessages.merge(keyBy(validationMessages, 'attribute'));

		const fieldDomElements = this.allErrorMessages.map((error) => {
			const field = this.form.fields[error.attribute];

			if (field) {
				return field?.control?.controlRef;
			}
			return null;
		}).sort(function (a, b) {
			if (a === b) return 0;
			if (!a.compareDocumentPosition) {
				// support for IE8 and below
				return a.sourceIndex - b.sourceIndex;
			}
			// eslint-disable-next-line no-bitwise
			if (a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_PRECEDING) {
				// b comes before a
				return 1;
			}
			return -1;
		});

		if (fieldDomElements.length) {
			fieldDomElements[0].focus?.();
		}
	}
}

export { FormValidatorPlugin };
