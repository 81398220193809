/* eslint-disable import/no-relative-packages */
import type {
	AxiosError, AxiosPromise, AxiosResponse, AxiosInstance, InternalAxiosRequestConfig,
} from '../../node_modules/axios';
import axios from '../../node_modules/axios';

import { unauthorizedRedirect } from '~/util/unauthorizedRedirect';
import { apiUrl } from '~/global/global.constants';

export const unauthorizedErrorHandler = (error: AxiosError) => {
	// Redirect to login page if you are not authenticated.
	if (error.response?.status === 401) {
		unauthorizedRedirect(error.response?.data);
	}
	// Keep the promise alive but in a rejected state so that other callbacks will act upon it correctly.
	// This is to prevent false positives. More info: https://github.com/axios/axios/issues/266
	return Promise.reject(error);
};

axios.defaults.withCredentials = true;
axios.defaults.baseURL = apiUrl;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.response.use(response => response, unauthorizedErrorHandler);

// adding back the version 0.X default params serializer
// https://github.com/axios/axios/issues/5630#issuecomment-1828448874
axios.defaults.paramsSerializer = (params) => {
	return Object.keys(params)
		.filter(key => params[key] !== undefined)
		.map((key) => {
			if (params[key] != null && typeof params[key] === 'object') {
				params[key] = JSON.stringify(params[key]);
			}

			if (Array.isArray(params[key])) {
				return params[key].map((v: any) => `${key}[]=${encodeURIComponent(v)}`).join('&');
			}

			return `${key}=${encodeURIComponent(params[key])}`;
		})
		.join('&');
};

export const axiosSsr = () => {
	const axiosInstance = axios.create();

	axiosInstance.defaults.baseURL = apiUrl;
	return axiosInstance;
};

export {
	AxiosError, AxiosPromise, AxiosResponse, AxiosInstance, InternalAxiosRequestConfig,
};

// eslint-disable-next-line import/no-default-export
export default axios;
