import { isOnServer } from '~/global/global.constants';

class FormReactionsPlugin {
	id = 'formReactions';

	form;

	settings;

	constructor(settings) {
		this.settings = settings;
	}

	setForm(form) {
		this.form = form;

		this.settings.reaction(this.form, this.settings);
		if (!isOnServer) {
			console.info(`formReactions plugin has been applied to ${this.form.id}`);
		}
	}
}

export { FormReactionsPlugin };
