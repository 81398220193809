import { action, observable, makeObservable } from 'mobx';
import axios from 'axios';

import { getAxios } from '~/global/app-config/ipHeaders';
import { apiUrl, isOnServer } from '~/global/global.constants';
import { GlobalStatic } from '~/global/global-static/Models/GlobalStatic.model';

class GlobalStaticStore {
	constructor(model) {
		makeObservable(this, {
			model: observable.ref,
			cookies: observable.ref,
			hasInitiallyLoaded: observable,
			fetchData: action,
			processData: action,
		});

		this.model = model;
	}

	functionQueue = [];

	model = {};

	cookies = {};

	hasInitiallyLoaded = false;

	registerCallbackFunction(func) {
		this.functionQueue.push(func);
	}

	fetchData(pageProps, ctx) {
		if (isOnServer && (!pageProps || !ctx)) {
			return Promise.reject();
		}
		const headers = {};
		let dynaAxios = axios;

		if (isOnServer) {
			dynaAxios = getAxios(ctx);
			headers.Cookie = `SESSION=${this.cookies.SESSION}; featureTogglesBrowserId=${this.cookies.featureTogglesBrowserId};`;
		}

		return dynaAxios.request({
			url: `${apiUrl}/api/global-static`,
			method: 'get',
			headers,
		}).then((response) => {
			this.processData(response.data);
			this.hasInitiallyLoaded = true;
		}, (error) => {
			console.error(error);
		});
	}

	processData(data) {
		if (!data) {
			return;
		}
		if (data?._links) {
			this.model.links.replace(data._links);
		}

		if (this.functionQueue.length) {
			let callback = this.functionQueue.pop();
			while (typeof callback !== 'undefined') {
				callback(this.model);
				callback = this.functionQueue.pop();
			}
		}
	}
}

const GlobalStaticFactory = {
	create(cookies) {
		const instance = new GlobalStaticStore(new GlobalStatic());
		instance.cookies = cookies;
		return instance;
	},
};
// const globalStatic = GlobalStaticFactory.create();
// // Hydrate with data here. For now, this should only load once per page. Cacheing will come in the future.
// if (!isOnServer) {
// 	globalStatic.fetchData();
// }

export {
	GlobalStaticFactory,

	// Don't use this
	// globalStatic,
};
