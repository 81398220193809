import {
	observable, computed, action, makeObservable,
} from 'mobx';

class BuildInfoModel {
	data = new Map();

	showInfo = false;

	constructor() {
		makeObservable(this, {
			data: observable,
			showInfo: observable,
			update: action.bound,
			revision: computed,
			buildNumber: computed,
			buildTimestamp: computed,
			buildUrl: computed,
		});
	}

	update(newData) {
		if (newData) {
			this.data.replace(newData);
		} else {
			this.data.clear();
		}
		this.showInfo = true;
	}

	get revision() {
		if (this.data.has('Revision')) {
			return this.data.get('Revision');
		}
		return 'unknown';
	}

	get buildNumber() {
		if (this.data.has('Build-Number')) {
			return this.data.get('Build-Number');
		}
		return 'unknown';
	}

	get buildTimestamp() {
		if (this.data.has('Build-Timestamp')) {
			return this.data.get('Build-Timestamp');
		}
		return 'unknown';
	}

	get buildUrl() {
		if (this.data.has('Build-Url') && this.data.get('Build-Url') !== 'unknown') {
			return this.data.get('Build-Url');
		}
		return null;
	}
}

export { BuildInfoModel };
