import { computed, observable, makeObservable } from 'mobx';

class GlobalStatic {
	links = new Map();

	constructor() {
		makeObservable(this, {
			links: observable,
			cartPageLink: computed,
			customerSearchLink: computed,
			customersToolbarLink: computed,
			featureTogglesLink: computed,
			listMyOpportunitiesLink: computed,
			orderStatusLink: computed,
			recentMoreCustomersLink: computed,
			setTransZoneLink: computed,
			shopWithNewGuestLink: computed,
			wishlistPageLink: computed,
			buildInfoLink: computed,
			recommendationsAccessTokenLink: computed,
			searchAccessTokenLink: computed,
			sendPasswordResetEmailLink: computed,
			validatePasswordResetLink: computed,
			verifyAccountLink: computed,
		});
	}

	get cartPageLink() {
		return this.links.get('cartPage')?.href || '/shop/shoppingcart/cartDetails.do';
	}

	get customerSearchLink() {
		return this.links.get('customerSearch')?.href;
	}

	get customersToolbarLink() {
		return this.links.get('customersToolbar')?.href;
	}

	get featureTogglesLink() {
		return this.links.get('featureToggles')?.href;
	}

	get listMyOpportunitiesLink() {
		return this.links.get('listMyOpportunities')?.href;
	}

	get orderStatusLink() {
		return this.links.get('orderStatusPage')?.href;
	}

	get recentMoreCustomersLink() {
		return this.links.get('recentMoreCustomers')?.href;
	}

	get setTransZoneLink() {
		return this.links.get('setTransportationZone')?.href;
	}

	get shopWithNewGuestLink() {
		return this.links.get('shopWithNewGuest')?.href;
	}

	get wishlistPageLink() {
		return this.links.get('wishlistPage')?.href;
	}

	get buildInfoLink() {
		return this.links.get('buildInfo')?.href;
	}

	get sendPasswordResetEmailLink() {
		return this.links.get('sendPasswordResetEmail')?.href;
	}

	get recommendationsAccessTokenLink() {
		return this.links.get('recommendationsAccessToken')?.href || this.searchAccessTokenLink;
	}

	get searchAccessTokenLink() {
		return this.links.get('searchAccessToken')?.href || '/api/search/token';
	}

	get validatePasswordResetLink() {
		return this.links.get('validatePasswordReset')?.href;
	}

	get verifyAccountLink() {
		return this.links.get('verifyAccount')?.href;
	}
}

export { GlobalStatic };
