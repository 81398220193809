import axios from 'axios';
import { GetServerSidePropsContext } from 'next';

import { apiUrl } from '~/global/global.constants';
import {
	ICreateSubscriptionPayload, IEmailSubscriptionErrorResponse, IEmailSubscriptionPayload, IEmailSubscriptionResponse, IGetSubscriptionResponse, IUpdateSubscriptionPayload,
} from '~/email-subscription/Types/EmailSubscription.type';
import { getAxios } from '~/global/app-config/ipHeaders';
import { setCookieHeader } from '~/util/setCookieHeader';
import { AppConfigPageProps } from '~/global/app-config/AppConfig.types';

export const createSubscriptionUrl = `${apiUrl}/api/email-preferences/create-subscription`;
export const updateSubscriptionUrl = `${apiUrl}/api/email-preferences/update-subscription`;
export const unsubscribeUrl = `${apiUrl}/api/email-preferences/unsubscribe`;
export const startBreakUrl = `${apiUrl}/api/email-preferences/start-break`;
export const getSubscriptionUrl = `${apiUrl}/api/email-preferences/get-subscription`;
export const endBreakUrl = `${apiUrl}/api/email-preferences/end-break`;

export const emailSubscriptionRequest = async (url: string, data?: any): Promise<IEmailSubscriptionResponse | IEmailSubscriptionErrorResponse> => {
	try {
		const response = await axios.request({ url, method: 'post', data });

		return { data: response.data };
	} catch (error) {
		console.error(error);

		return { error };
	}
};

export const createSubscription = async (payload: ICreateSubscriptionPayload): Promise<IEmailSubscriptionResponse | IEmailSubscriptionErrorResponse> => {
	const response = await emailSubscriptionRequest(createSubscriptionUrl, payload);

	return response;
};

export const updateSubscription = async (payload: IUpdateSubscriptionPayload) => {
	const response = await emailSubscriptionRequest(updateSubscriptionUrl, payload);

	return response;
};

export const unsubscribe = async (payload: IEmailSubscriptionPayload) => {
	await emailSubscriptionRequest(unsubscribeUrl, payload);
};

export const startBreak = async (payload: IEmailSubscriptionPayload) => {
	await emailSubscriptionRequest(startBreakUrl, payload);
};

export const endBreak = async (payload: IEmailSubscriptionPayload) => {
	await emailSubscriptionRequest(endBreakUrl, payload);
};

export const getSubscription = async (
	payload: IEmailSubscriptionPayload,
	pageProps?: AppConfigPageProps,
	context?: GetServerSidePropsContext,
): Promise<IGetSubscriptionResponse | undefined> => {
	const dynaAxios = getAxios(context);

	try {
		const headers = setCookieHeader(pageProps);
		const response = await dynaAxios.request({
			data: payload,
			method: 'post',
			url: getSubscriptionUrl,
			...(headers) && { headers },
		});

		return { data: response.data };
	} catch (error: any) {
		console.error(error);

		return undefined;
	}
};

export const formatDate = (date: string): string => {
	return new Intl.DateTimeFormat('en-US').format(new Date(date));
};
