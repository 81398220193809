import React from 'react';

export const shouldIgnoreBlurValidation = (event: React.FocusEvent<HTMLInputElement>) => {
	const {
		relatedTarget,
		type,
	} = event;
	const shouldIgnoreBlurEventToAvoidValidation = (
		type === 'blur'
		&& relatedTarget
		&& relatedTarget.hasAttribute('data-ignore-blur-validation')
	);

	return shouldIgnoreBlurEventToAvoidValidation;
};
