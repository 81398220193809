import { observer } from 'mobx-react';
import React from 'react';
import classNames from 'classnames';

import { CheckboxField } from '~/util/formz/Components/fields/CheckboxField';
import { EmailField } from '~/util/formz/Components/fields/EmailField';
import { Field } from '~/util/formz/Components/fields/Field';
import { MaskedField } from '~/util/formz/Components/fields/MaskedField';
import { SelectField } from '~/util/formz/Components/fields/SelectField';
import { TextareaField } from '~/util/formz/Components/fields/TextareaField';
import { ViewState } from '~/util/viewState/Components/ViewState';
import { isOnServer } from '~/global/global.constants';

import styles from '#/contact-us/contact-us.module.scss';

export const ContactUsFormViewState = observer(({ contactUsFormStore: { viewState } }) => <ViewState viewState={viewState} />);

export const ContactUsForm = observer((props) => {
	const {
		contactUsFormStore: {
			form: {
				fields: {
					email,
					name,
					phone,
					yourmessage,
					sendCopy,
					emailList,
					sourceKey,
					inquiryType,
				},
				reactProps,
			},
			mappedSubjects,
		},
	} = props;

	let inquiryTypes = null;
	if (mappedSubjects) {
		inquiryTypes = (
			<SelectField field={inquiryType}>
				{
					mappedSubjects.map((subject, index) => {
						return <option key={index} value={subject.value}>{subject.text}</option>;
					})
				}
			</SelectField>
		);
	}
	const {
		key,
		...reactPropsWithoutKey
	} = reactProps;

	return (
		<div className={classNames(styles['contactUs-form'], 'formz')}>
			<form {...reactPropsWithoutKey}>
				<p className="tw-heading-5 tw-mb-6 tw-font-normal">Information is required unless noted.</p>
				<Field field={name} />
				<Field field={sourceKey} />
				<EmailField field={email} />
				{isOnServer && <Field field={phone} />}
				{!isOnServer && <MaskedField field={phone} />}
				{inquiryTypes}
				<TextareaField field={yourmessage} />
				<CheckboxField field={sendCopy} />
				<CheckboxField field={emailList} />
				<button className="Button">Submit</button>
			</form>
		</div>
	);
});
