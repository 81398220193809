import { PROMOTIONALFLEX_ID } from '~/homepage/Components/PromotionalFlex/PromotionalFlex.type';
import { personalzationModalSettings } from '~/personalization/Components/PersonalizationModal.constants';

export enum PERSONALIZATION_SCOPE {
    FLEX_1 = 'flex1',
    FLEX_2 = 'flex2',
	HERO = 'hero',
	MODAL = 'modal',
	PAGE_VIEW = 'pageView',
}

export interface IPersonalizationDecision {
	detail: IPersonalizationEvent,
}

export interface IPropositionItem {
	id: string,
	data: {
		id: string,
		content: {
			component: IPersonalizationComponentKey,
		},
	},
}

export interface IPropositions {
	id: string,
	items: IPropositionItem[]
	scope: PERSONALIZATION_SCOPE[],
	scopeDetails: {
		correlationID: string,
	},
	mockDecision?: boolean,
}

export interface IPersonalizationEvent {
	propositions: IPropositions[],
}

export type IPersonalizationComponentKey = PROMOTIONALFLEX_ID | keyof typeof personalzationModalSettings;
