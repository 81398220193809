import { action, makeObservable } from 'mobx';

import { BROKEN_UI, LAUNCH_PLACEHOLDER_TEXT, PAGE_CONTENT_PLACEHOLDER_TEXT } from '~/util/launchUtils';
import { LinkEventModel } from '~/tracking/link-event/Models/LinkEvent.model';
import { TrackingDebugModeFactory } from '~/tracking/debug/Tracking.debugMode';
import { isEngage } from '~/global/global.constants';

export class LinkEventStore {
	model

	debugMode

	cleanup() {
		document.removeEventListener('click', this.clickHandler, true);
		this.model.hasInitialized = false;
	}

	clearModel() {
		this.model.href = '';
		this.model.linkType = null;
		this.model.wasSuccessful = null;
		this.model.name = '';
		this.model.clickEvent = {};
		this.model.componentPosition = '';
		this.model.componentName = '';
		this.model.componentType = '';
	}

	clickHandler(event = {}) {
		const {
			target,
		} = event;

		// if you click on a link or a button we have a clickEvent
		if (['A', 'BUTTON'].includes(target.tagName)) {
			this.model.clickEvent = event.target;
			if (!this.model.isPartOfForm) {
				this.trackLinkEvent();
			}
			// or you may have clicked on something inside of a link or button, grab the closest a or button
		} else if (target.closest('a') || target.closest('button')) {
			const closestElem = target.closest('a') || target.closest('button');
			this.model.clickEvent = closestElem;
			if (!this.model.isPartOfForm) {
				// look at the closestElems children to roundup useful data attributes
				this.getDecendentAttributes(this.model.clickEvent);
				this.trackLinkEvent();
			}
		} else if (['INPUT'].includes(target.tagName)) {
			this.model.clickEvent = target;
			if (!this.model.isPartOfForm) {
				this.getDecendentAttributes(this.model.clickEvent);
				this.trackFormEvent(event);
			}
		}

		this.clearModel();
	}

	getDecendentAttributes(node) {
		const interestedDataAttributes = ['[data-tr-link-event-name], [data-tr-link-event-type], [data-tr-link-event-track]'];
		const hasAttrs = node.querySelectorAll(interestedDataAttributes);

		if (hasAttrs.length > 0) {
			Array.from(hasAttrs).forEach((nodeAttr) => {
				this.manuallySetModelProperties(nodeAttr.dataset);
			});
		}
	}

	init() {
		// AL-408, we are not tracking checkout or account until they have been re-built
		if (this.model.hasInitialized) {
			return;
		}
		document.addEventListener('click', this.clickHandler, true);
		this.model.hasInitialized = true;
	}

	manuallySetModelProperties(data) {
		Object.entries(data).forEach((attr) => {
			const key = attr[0];
			const value = attr[1];
			if (this.model.manualDataAttributes[key] && value) {
				this.model.manualDataAttributes[key].setModelVal.call(this.model, value);
			}
		});
	}

	trackVideo({
		id = null,
		name = '',
		url = '',
		linkText = 'play',
		componentType = 'vimeo player',
	}) {
		if (isEngage) {
			return;
		}

		this.model.componentType = componentType;
		this.model.linkType = 'VIDEO';
		this.model.componentName = name;
		this.model.name = linkText;
		this.model.href = url;
		this.model.wasSuccessful = 'pass';

		this.trackLinkEvent();
		this.clearModel();
	}

	trackFailedLinkEvent({
		id = '',
		trLinkEventCompType = null,
		type = null,
	}) {
		if (isEngage) {
			return;
		}

		const val = `${BROKEN_UI}: ${id}`;

		this.model.componentName = val;
		this.model.componentType = trLinkEventCompType;
		this.model.href = val;
		this.model.linkType = type;
		this.model.name = val;
		this.model.wasSuccessful = 'fail';

		this.trackLinkEvent();
		this.clearModel();
	}

	trackFormEvent(event = {}) {
		const { target } = event;
		const type = this.model.componentType === PAGE_CONTENT_PLACEHOLDER_TEXT ? 'form' : this.model.componentType;

		// do not track form input events inside a modal by default (AL-320)
		if (type !== 'modal' || this.model.isForceTrackModalLink) {
			this.model.componentType = type;

			if (target.type === 'checkbox') {
				if (target.checked) {
					this.model.name = `apply selection: ${this.model.name}`;
				} else {
					this.model.name = `remove selection: ${this.model.name}`;
				}

				this.trackLinkEvent();
			}

			if (target.type === 'radio') {
				this.trackLinkEvent();
			}
		}

		this.clearModel();
	}

	trackLinkEvent(data) {
		// sometimes we send data directly without using a click event
		if (data) {
			this.manuallySetModelProperties(data);
		}

		if (this.model.shouldTrack !== true) {
			return;
		}

		const {
			componentName,
			componentPosition,
			componentType,
			name,
			pageID,
			type,
			href,
			wasSuccessful,
		} = this.model;

		// only if we know the type write to the data layer
		if (type) {
			const eventJson = {
				'event': 'linkClick',
				'content': {
					'compName': componentName,
					'compPosition': componentPosition,
					'compType': componentType,
					'dURL': href || LAUNCH_PLACEHOLDER_TEXT,
					'name': name || LAUNCH_PLACEHOLDER_TEXT,
					'pageID': pageID || LAUNCH_PLACEHOLDER_TEXT,
					'type': type?.label || LAUNCH_PLACEHOLDER_TEXT,
				},
			};
			if (typeof wasSuccessful !== 'undefined' && wasSuccessful !== null) {
				eventJson.successful = wasSuccessful;
			}

			window.eventDataLayer = window.eventDataLayer || [];
			window.eventDataLayer.push(eventJson);

			if (this.debugMode.shouldLogLinkEvents) {
				console.log(eventJson);
			}
		}

		this.clearModel();
	}

	constructor() {
		makeObservable(this, {
			cleanup: action.bound,
			clickHandler: action.bound,
			init: action.bound,
			manuallySetModelProperties: action.bound,
			trackLinkEvent: action.bound,
		});
	}
}

export const LinkEventStoreFactory = {
	create(featureTogglesModel) {
		const store = new LinkEventStore();
		store.model = new LinkEventModel(featureTogglesModel);
		store.debugMode = TrackingDebugModeFactory.create(featureTogglesModel);

		return store;
	},
};
