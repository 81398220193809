import {
	action, computed, toJS, makeObservable,
} from 'mobx';

import { BaseControlBuilder } from '~/util/formz/builders/controls/BaseControlBuilder';

export class TextareaControlBuilder extends BaseControlBuilder {
	constructor(name, controlData, form) {
		super(name, controlData, form);

		makeObservable(this, {
			reactProps: computed,
		});

		this.name = name;
		this.controlData = controlData;
		this.form = form;

		// default control events
		this.addOrWrapControlEventHandler('onChange', action(`${this.name} onChange`, (event) => {
			this.form.updateModelValue(this.name, event.target.value);
		}));
	}

	get reactProps() {
		return {
			value: this.value,
			...Object.fromEntries(toJS(this.reactPropsMap)),
		};
	}

	static BUILDER_KEY = 'textarea'
}
