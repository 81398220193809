import { intercept } from 'mobx';

import { isNumber } from '~/util/isNumber';

class NumberPlugin {
	constructor(name, fieldObs, form) {
		this.name = name;
		this.fieldObs = fieldObs;
		this.form = form;

		intercept(this.form.model, this.name, (change) => {
			if (!isNumber(change.newValue)) {
				change.newValue = change.newValue.replace((/[^0-9.]+/g), '');
			}
			return change;
		});
	}
}

export { NumberPlugin };
