import {
	autorun, computed, intercept, makeObservable,
} from 'mobx';
import AutoNumeric from 'autonumeric';

import { isNumber } from '~/util/isNumber';
import { noop } from '~/util/noop';
import { isOnServer } from '~/global/global.constants';
import { unformatCurrency } from '~/util/unformatCurrency';
import { safeWrapOrCreate } from '~/util/formz/util/util';
import { shouldIgnoreBlurValidation } from '~/util/formz/util/shouldIgnoreBlurValidation';

class UsCurrencyPlugin {
	constructor(name, fieldObs, form, settings) {
		makeObservable(this, {
			usCurrencyReactProps: computed,
		});

		this.name = name;
		this.fieldObs = fieldObs;
		this.form = form;
		this.settings = settings;

		const onBlurDebounceWait = this?.fieldObs?.settings?.onBlurDebounceWait;

		if (typeof onBlurDebounceWait === 'undefined') {
			/*
			The Formz "UsCurrencyPlugin" works best with a debounced onBlur event to prevent field validation render
			flickering. Since no "onBlurDebounceWait" setting was found on the field, "${this.name}", this plugin has
			set it to "100". To override this, please add the setting to your field if you need the wait time longer
			or set it to "0" to have the validation happen immeidately.
			 */
			this.fieldObs = this.fieldObs || {};
			this.fieldObs.settings = this.fieldObs.settings || {};
			this.fieldObs.settings.onBlurDebounceWait = 100;
		}
	}

	autorunDisposer = noop

	interceptDisposer = noop

	autoNumeric = null

	get usCurrencyReactProps() {
		const alteredProps = {
			...this.fieldObs.control.reactProps,
			defaultValue: this.fieldObs.control.reactProps.value,
		};

		delete alteredProps.value;
		delete alteredProps.onChange;

		return alteredProps;
	}

	applyUsCurrencyPlugin() {
		const self = this;
		const control = this.fieldObs.control.controlRef;

		this.autoNumeric = new AutoNumeric(control, {
			...AutoNumeric.getPredefinedOptions().NorthAmerican,
			maximumValue: this.settings.maxPayment,
			...(this.settings.fullDollarAmounts) && {
				decimalPlaces: 0,
			},
		});

		if (!isOnServer) {
			safeWrapOrCreate(this.fieldObs.control.reactPropsMap, 'onChange', (event) => {
				if (shouldIgnoreBlurValidation(event)) {
					return;
				}
				self.changeHandler();
			});
			// Ensures value is formatted from outside changes.
			this.autorunDisposer = autorun(() => {
				const newValue = this.form.model[this.name];

				if (newValue === null || newValue === undefined) {
					return;
				}
				if (document.activeElement !== this.fieldObs.control.controlRef) {
					this.autoNumeric.set(newValue);
				}
			});
			// Ensure the value is always a number before updating.
			this.interceptDisposer = intercept(this.form.model, this.fieldObs.name, (change) => {
				const unformattedValue = isNumber(change.newValue) ? change.newValue : unformatCurrency(change.newValue);

				if (!Number.isNaN(unformattedValue)) {
					change.newValue = unformattedValue;
				}
				return change;
			});
		}
	}

	removeUsCurrencyPlugin() {
		this.fieldObs.control.controlRef.removeEventListener('change', this.changeHandler);
		// iPad bugfix - need to be defensive here when removing autoNumeric as iPad has trouble unmounting sometimes.
		this.autoNumeric?.remove?.();
		this.autorunDisposer();
		this.interceptDisposer();
	}

	changeHandler() {
		this.form.model[this.name] = this.autoNumeric.getNumber();
	}
}

export { UsCurrencyPlugin };
