export class ContentPromo {
	data: any

	constructor(data: any = {}) {
		this.data = data;
	}

	get height() {
		return this.data.imgHeightSm;
	}

	get heightLg() {
		return this.data.imgHeight;
	}

	get id() {
		return this.data.id;
	}

	get link() {
		return this.data.url;
	}

	get linkText() {
		return this.data.promoTitle;
	}

	get srcLg() {
		return this.data.imgSrc;
	}

	get srcSetLg() {
		return this.data.imgSrcSet;
	}

	get src() {
		return this.data.imgSrcSm;
	}

	get srcSet() {
		return this.data.imgSrcSetSm;
	}

	get url() {
		return this.data.url;
	}

	get widthLg() {
		return this.data.imgWidth;
	}

	get width() {
		return this.data.imgWidthSm;
	}
}

export const ContentPromoFactory = {
	create(contentPromoData: any) {
		const contentPromo = new ContentPromo(contentPromoData);

		return contentPromo;
	},
};
