/* eslint jsx-a11y/label-has-associated-control: "off", jsx-a11y/label-has-for: "off", jsx-a11y/anchor-has-content: "off" */

import React from 'react';
import { observer } from 'mobx-react';

export const Label = observer(({ field = {}, hideLabel = false }) => {
	const { key, ...labelProps } = field.label?.reactProps || {};

	if (hideLabel) {
		return null;
	}
	if (field.label && field.label.linkProps) {
		return (
			<div className="label-group">
				<label key={key} {...labelProps} />
				<a {...field.label.linkProps} />
			</div>
		);
	}
	if (field.label) {
		return <label key={key} {...labelProps} />;
	}
	return null;
});
