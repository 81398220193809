import { isOnServer } from '~/global/global.constants';
import { SignInEvent } from '~/tracking/account/access-events/Models/SignInEvent.tracking.model';
import { TrackingDebugMode, TrackingDebugModeFactory } from '~/tracking/debug/Tracking.debugMode';
import { FeatureTogglesModel } from '~/util/feature-toggles/Models/FeatureToggles.model';

export class AccountAccessEventsTrackingStore {
	debugMode!: TrackingDebugMode

	trackSignInEvent(customerTrackingKey: string = '') {
		const event = new SignInEvent(customerTrackingKey);
		this.pushToEventDataLayer(event);
	}

	pushToEventDataLayer(event: any) {
		const tsWindow = window as any;
		const {
			trackingJson = '',
		} = event;

		if (!isOnServer && tsWindow?.eventDataLayer) {
			if (this.debugMode && this.debugMode.shouldLogLinkEvents) {
				console.info(trackingJson);
			}
			tsWindow.eventDataLayer.push(trackingJson);
		}
	}
}

export const AccountAccessEventsTrackingStoreFactory = {
	create: (featureTogglesModel: FeatureTogglesModel) => {
		const store = new AccountAccessEventsTrackingStore();
		store.debugMode = TrackingDebugModeFactory.create(featureTogglesModel);

		return store;
	},
};
