import { observable, makeObservable } from 'mobx';

export class GlobalDynamicAnalytics {
	analytics

	constructor(analytics) {
		makeObservable(this, {
			analytics: observable,
		});

		this.analytics = analytics;
	}

	get adobeLaunchIpAddressClassificationType() {
		return this.analytics.adobeLaunchIpAddressClassificationType;
	}

	get employeeName() {
		return this.analytics.employeeName ? this.analytics.employeeName : null;
	}

	get employeeNumber() {
		return this.analytics.employeeNumber ? this.analytics.employeeNumber : null;
	}

	get isGuest() {
		return this.analytics.isGuest;
	}

	get isLoggedIn() {
		return this.analytics.isLoggedIn;
	}

	get locationName() {
		return this.analytics.locationName ? this.analytics.locationName : null;
	}

	get locationNumber() {
		return this.analytics.locationNumber ? this.analytics.locationNumber : null;
	}

	get sapCustomerNumber() {
		return this.analytics.sapCustomerNumber ? this.analytics.sapCustomerNumber : null;
	}
}
