import { isObservableArray } from 'mobx';

import { groupByKey } from '~/util/groupBy';
import { isEmpty } from '~/util/isEmpty';
import { isFunction } from '~/util/isFunction';

export const addCustomValidators = (validate) => {
	const getMessageText = function (modelObject, validationSettingsObject, key) {
		const message = groupByKey(validate(modelObject, validationSettingsObject), 'attribute');

		if (message[key] && message[key].length) {
			const headMessage = message[key][0];
			return headMessage.error;
		}
		return null;
	};

	validate.validators.dependentPresence = function (value, optionsArray, key, attributes) {
		let validationMessage = null;
		const theOptionsArray = Array.isArray(optionsArray) ? optionsArray : [optionsArray];

		theOptionsArray.forEach((options) => {
			const { dependentModelKey } = options;
			const dependentModelValue = attributes[dependentModelKey];
			const testModelValue = options.dependentModelValue;
			const { validateIfDependentModelEmpty } = options;
			let shouldValidate = false;
			const modelObject = {};
			const validationSettingsObject = {};

			if (validateIfDependentModelEmpty) {
				// if we set this option to true, we're validating one field if the dependant field IS empty
				if (isEmpty(testModelValue)) {
					shouldValidate = isEmpty(dependentModelValue);
				}
			} else if (isEmpty(testModelValue)) {
				// we aren't checking for a specific testModelValue, just presence
				shouldValidate = !isEmpty(dependentModelValue);
			} else if (testModelValue === dependentModelValue) {
				// check the testModelValue for a specific value
				shouldValidate = true;
			}
			if (shouldValidate) {
				modelObject[key] = value;
				if (options.message) {
					validationSettingsObject[key] = {
						presence: {
							message: options.message,
						},
					};
				} else {
					validationSettingsObject[key] = {
						presence: true,
					};
				}
				validationMessage = getMessageText(modelObject, validationSettingsObject, key);
				validationMessage = validationMessage && `^${validationMessage}`;
			}
		});
		return validationMessage;
	};

	validate.validators.dependentLength = function (value, optionsArray, key, attributes) {
		let validationMessage = null;
		const modelObject = {};
		const validationSettingsObject = {};
		const theOptionsArray = Array.isArray(optionsArray) ? optionsArray : [optionsArray];

		theOptionsArray.forEach((options) => {
			const { dependentModelKey } = options;
			const dependentModelValue = attributes[dependentModelKey];
			const testModelValue = options.dependentModelValue;
			let shouldValidate = false;

			if (isEmpty(testModelValue)) {
				// we aren't checking for a specific testModelValue, just presence
				shouldValidate = !isEmpty(dependentModelValue);
			} else if (testModelValue === dependentModelValue) {
				// check the testModelValue for a specific value
				shouldValidate = true;
			}
			if (shouldValidate) {
				modelObject[key] = value;
				if (options.message) {
					validationSettingsObject[key] = {
						length: {
							is: options.is,
							message: options.message,
						},
					};
				} else {
					validationSettingsObject[key] = {
						length: {
							is: options.is,
						},
					};
				}
				validationMessage = getMessageText(modelObject, validationSettingsObject, key);
				validationMessage = validationMessage && `^${validationMessage}`;
			}
		});
		return validationMessage;
	};

	validate.validators.dependentPhoneUS = function (value, optionsArray, key, attributes) {
		let validationMessage = null;
		let theOptionsArray = optionsArray;

		if (!Array.isArray(theOptionsArray) && !isObservableArray(theOptionsArray)) {
			theOptionsArray = [theOptionsArray];
		}
		theOptionsArray.forEach((options) => {
			const { dependentModelKey } = options;
			const dependentModelValue = attributes[dependentModelKey];
			const testModelValue = options.dependentModelValue;
			let shouldValidate = false;
			const modelObject = {};
			const validationSettingsObject = {};

			if (isFunction(testModelValue)) {
				shouldValidate = testModelValue(dependentModelValue);
			} else if (isEmpty(testModelValue)) {
				// we aren't checking for a specific testModelValue, just presence
				shouldValidate = !isEmpty(dependentModelValue);
			} else if (testModelValue === dependentModelValue) {
				// check the testModelValue for a specific value
				shouldValidate = true;
			}
			if (shouldValidate) {
				modelObject[key] = value;
				if (options.message) {
					validationSettingsObject[key] = {
						phoneUS: true,
					};
				} else {
					validationSettingsObject[key] = {
						phoneUS: true,
					};
				}
				validationMessage = getMessageText(modelObject, validationSettingsObject, key);
				validationMessage = validationMessage && `^${validationMessage}`;
			}
		});
		return validationMessage;
	};

	validate.validators.match = function (value, options, key, attributes) {
		const { matchModelName } = options;
		const { matchModelKey } = options;
		const matchModelValue = attributes[matchModelKey];

		if (matchModelValue !== value) {
			if (options.message) {
				return options.message;
			}
			return `^Please match ${matchModelName}`;
		}
		return null;
	};

	validate.validators.deliveryAddress = function (value) {
		let validationMessage = null;
		const poBoxValues = ['po ', 'po.', 'p.o ', 'p.o. ', 'pobox ', 'po box ', 'pob ', 'box ', 'post office box '];
		const theValue = value ? value.toLowerCase().trim() : '';

		poBoxValues.forEach((poBoxValue) => {
			if (theValue.startsWith(poBoxValue)) {
				validationMessage = '^We do not ship or deliver to post office boxes.';
			}
		});
		return validationMessage;
	};

	validate.validators.zipCode = function (value, options) {
		const shouldTest = options.required !== false || (value && value.length > 0);

		if (shouldTest && !(/^\d{5}([-]\d{4})?$/).test(value)) {
			if (options.message) {
				return options.message;
			}
			return '^Please enter a valid US ZIP code';
		}
		return null;
	};

	/**
	 * Just like zipCode only it accommodates a wildcard at the end.
	 * @param value
	 * @param options
	 */
	validate.validators.zipCodeWildcard = function (value, options) {
		const theValue = value || '';
		let wildcardIdx;
		let paddedZip;
		let replacedVal = theValue.replace('*', '0');

		if (theValue.endsWith('*')) {
			wildcardIdx = theValue.indexOf('*');
			if (wildcardIdx === 5) {
				// The wildcard was in the position of where the hyphen should go. Replace it accordingly.
				replacedVal = theValue.replace('*', '-');
			}
			// Fill in with fake digits in place of the wildcard to pass to zipCode validation.
			paddedZip = replacedVal.padEnd(10, '00000-0000'.substring(wildcardIdx + 1));
			return validate.validators.zipCode(paddedZip, options);
		}
		return validate.validators.zipCode(theValue, options);
	};

	/**
	 * Length validation while ignoring a range of characters.
	 * @param value
	 * @param options
	 * @returns {*}
	 */
	validate.validators.lengthIgnoredChars = function (value, options) {
		const theValue = value || '';
		const pureValueLength = theValue.replace(options.ignoredChars, '').length;
		const includeMinMaxInError = options.includeMinMaxInError !== undefined ? options.includeMinMaxInError : true;

		if (Number.isFinite(options.minimum) && pureValueLength < options.minimum) {
			if (includeMinMaxInError) {
				return ` is too short (minimum ${options.minimum} characters)`;
			}
			return ' is too short';
		}
		if (Number.isFinite(options.maximum) && pureValueLength > options.maximum) {
			if (includeMinMaxInError) {
				return ` is too long (maximum ${options.maximum} characters)`;
			}
			return ' is too long';
		}
		if (Number.isFinite(options.minimumWithIgnoredChars) && theValue.length < options.minimumWithIgnoredChars) {
			if (includeMinMaxInError) {
				return ` is too short (minimum ${options.minimum} characters)`;
			}
			return ' is too short';
		}
		return null;
	};

	validate.validators.phoneUS = function (value, options) {
		const theValue = value ? value.replace(/\s+/g, '') : null;

		if (!(/^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/).test(theValue)) {
			if (options.message) {
				return options.message;
			}
			return '^Please enter a valid phone number';
		}
		return null;
	};

	// Check if value contains any unprintable ascii characters.
	// Allow tab characters and line breaks. These will be converted to spaces on submit.
	// If this is causing some issues, it's because of the regex for whiteList. Try /(\t|\r\n|\r|\n)/g instead.
	validate.validators.printableAscii = function (value) {
		const unprintable = /[^\x20-\x7e]/g;
		// Tabs, line breaks
		const whiteList = /[\t\r\n]/g;
		// Tabs, line breaks
		const whiteListCharCodes = [9, 10];
		let invalids;
		const message = '^Invalid character(s) found: ';
		const moreInvalids = [];

		if (value) {
			invalids = (value.match(unprintable) || []).filter(function (char) {
				const match = char.match(whiteList);
				return match !== null && match.length === 0;
			});
			// the whiteList regex isn't enough, return and tab unicode characters still came through as invalid
			// let's double down with charCodes
			invalids.forEach((char) => {
				if (whiteListCharCodes.includes(char.charCodeAt(0))) {
					moreInvalids.push(char);
				}
			});

			if (moreInvalids.length) {
				return message + moreInvalids.join(',');
			}
		}

		return null;
	};

	validate.validators.creditCard = function (value, options) {
		const defaultFormat = /(\d{1,4})/g;
		const cards = [
			{
				type: 'maestro',
				pattern: /^(5018|5020|5038|6304|6759|676[1-3]|6768|5612|5893|6304|6759|0604|6390)/,
				format: defaultFormat,
				length: [12, 13, 14, 15, 16, 17, 18, 19],
				cvcLength: [3],
				luhn: true,
			},
			{
				type: 'diners_club',
				pattern: /^(36|38|30[0-5])/,
				format: defaultFormat,
				length: [14],
				cvcLength: [3],
				luhn: true,
			},
			{
				type: 'laser',
				pattern: /^(6706|6771|6709)/,
				format: defaultFormat,
				length: [16, 17, 18, 19],
				cvcLength: [3],
				luhn: true,
			},
			{
				type: 'jcb',
				pattern: /^35/,
				format: defaultFormat,
				length: [16],
				cvcLength: [3],
				luhn: true,
			},
			{
				type: 'china_union',
				pattern: /^62/,
				format: defaultFormat,
				length: [16, 17, 18, 19],
				cvcLength: [3],
				luhn: false,
			},
			{
				type: 'discover',
				pattern: /^(6011|65|64[4-9]|622)/,
				format: defaultFormat,
				length: [16],
				cvcLength: [3],
				luhn: true,
			},
			{
				type: 'mastercard',
				pattern: /^5[1-5]/,
				format: defaultFormat,
				length: [16],
				cvcLength: [3],
				luhn: true,
			},
			{
				type: 'amex',
				pattern: /^3[47]/,
				format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
				length: [15],
				cvcLength: [3, 4],
				luhn: true,
			},
			{
				type: 'visa',
				pattern: /^4/,
				format: defaultFormat,
				length: [13, 14, 15, 16],
				cvcLength: [3],
				luhn: true,
			},
		];
		let cardType;
		const theValue = (`${value}`).replace(/D/g, '');

		for (let i = 0; i < cards.length; i++) {
			const card = cards[i];
			if (card.pattern.test(theValue)) {
				cardType = card.type;
				break;
			}
		}

		if (!cardType) {
			if (options.message) {
				return options.message;
			}
			return '^Enter a valid credit card number';
		}
		return null;
	};

	// Only validate the field if shouldValidate returns truthy.
	validate.validators.toggler = (value, options, name, model) => {
		const {
			validationConstraints = {},
			shouldValidate = () => true,
		} = options;
		const validatorKeys = Object.keys(validationConstraints) || [];
		const matchedValidatorKeys = validatorKeys.filter(key => isFunction(validate.validators[key]));
		const validatorResults = [];

		if (!shouldValidate(model)) {
			return null;
		}
		matchedValidatorKeys.forEach((key) => {
			validatorResults.push(
				validate.validators[key](value, options.validationConstraints[key]),
			);
		});
		return validatorResults.filter(Boolean)[0];
	};
};
