import { computed, observable, makeObservable } from 'mobx';

class TransZone {
	constructor(setTransZoneLink, currentTransZoneLink) {
		makeObservable(this, {
			hasTransZone: observable,
			isLoaded: observable,
			setTransZoneLink: observable,
			currentTransZoneLink: observable,
			transportationZoneKey: observable,
			deliveryPrice: observable,
			contentAreaKey: observable,
			contentAreaValue: observable,
			zipCode: observable,
			nationalUnconfirmedContentArea: observable,
			forbidden: observable,
			transZoneTrackingObject: computed,
		});

		this.setTransZoneLink = setTransZoneLink;
		this.currentTransZoneLink = currentTransZoneLink;
	}

	// If you want to properly check if the user has a trans zone, you cannot use falsey (e.g. !hasTransZone), you must check for the Boolean false.
	// This field is undefined from the outset until global dynamic data has initially been hydrated from the server.
	hasTransZone = undefined;

	isLoaded = undefined;

	setTransZoneLink = undefined;

	currentTransZoneLink = undefined;

	transportationZoneKey = undefined;

	deliveryPrice = undefined;

	contentAreaKey = undefined;

	contentAreaValue = undefined;

	zipCode = undefined;

	nationalUnconfirmedContentArea = undefined;

	forbidden = undefined;

	get transZoneTrackingObject() {
		return {
			contentArea: this.contentAreaValue,
			zipCode: this.zipCode,
		};
	}
}

export { TransZone };
