import React from 'react';
import { observer } from 'mobx-react';

import { Label } from '~/util/formz/Components/Label';
import { Validation } from '~/util/formz/Components/Validation';

export const BaseField = observer((props) => {
	const {
		field,
		field: {
			field: {
				reactProps: divProps = {}
			} = {},
		} = {},
	} = props;

	// First apply afterLabel from props directly, then check if it's on the labelData.
	// This is used for radio buttons where you need to put something after a specific radio within its field.
	// Most likely, you just need to use afterLabel on the field prop.
	const afterLabel = props.afterLabel || field?.label?.labelData?.afterLabel || null;
	const {
		key: labelKey,
		...labelProps
	} = props;

	const {
		key: divKey,
		...restOfDivProps
	} = divProps;

	if (props.reverseOrder) {
		return (
			<div key={divKey} { ...restOfDivProps }>
				{props.beforeControl}
				{props.children}
				{props.afterControl}
				{props.beforeLabel}
				<Label key={labelKey} {...labelProps} />
				{
					Boolean(afterLabel) &&
					afterLabel
				}
				<Validation {...props} />
				{props.afterValidation}
			</div>
		);
	}
	return (
		<div key={divKey} { ...restOfDivProps }>
			{props.beforeLabel}
			<Label key={labelKey} {...labelProps} />
			{
				Boolean(afterLabel) &&
				afterLabel
			}
			{props.beforeControl}
			{props.children}
			{props.afterControl}
			<Validation {...props} />
			{props.afterValidation}
		</div>
	);
});
