import {
	computed, observable, toJS, makeObservable, isObservable,
} from 'mobx';

class LabelBuilder {
	constructor(name, labelData, form) {
		makeObservable(this, {
			reactPropsMap: observable,
			linkProps: observable,
			controlId: computed,
			reactProps: computed,
		});

		this.name = name;
		this.labelData = labelData;
		this.form = form;
		// toJS needed for IE.
		this.labelId = `${this.name}-label-${toJS(this.form.id)}`;
		this.reactPropsMap = observable.map({
			'id': this.labelId,
			'key': this.labelId,
			'data-qa': this.labelId,
			'className': this.labelId,
			...labelData.reactProps,
		});
		const children = labelData.reactProps?.children || null;

		this.reactPropsMap.set('children', children);
		this.children = children;
		this.linkProps = this.labelData.link;
	}

	labelId = ''

	reactPropsMap = null;

	linkProps = '';

	get controlId() {
		return this?.form?.fields?.[this?.name]?.control?.reactPropsMap?.get?.('id') || null;
	}

	get reactProps() {
		const obj = Object.fromEntries(toJS(this.reactPropsMap));
		const children = this.reactPropsMap.get('children');

		// console.info(isObservable(children), isObservable(this.children));
		if (!isObservable(children)) {
			obj.children = children;
		} else {
			obj.children = this.children;
		}
		if (this.controlId) {
			return {
				htmlFor: this.controlId,
				...obj,
			};
		}
		return obj;
	}

	static BUILDER_KEY = 'label'
}

export { LabelBuilder };
