'use client';

import classNames from 'classnames';
import React, { AnchorHTMLAttributes } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';

export interface IProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	href?: string;
	printAsLink?: boolean;
	linkExternal?: boolean;
	clickHandler?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const LinkBase = (props: IProps) => {
	const {
		printAsLink = true,
		className = '',
		linkExternal = false,
		...anchorProps
	} = props;

	const {
		magicModal,
		featureTogglesModel: {
			isOn = () => false,
		} = {},
	} = useGlobalContext();

	function openThirdPartyModal(e: React.MouseEvent<HTMLAnchorElement>) {
		e.preventDefault();
		props.onClick?.(e);
		magicModal.openModal({
			id: 'third-party-exit-modal',
			showHeader: true,
			showCloseButton: true,
			title: 'You are entering a partner’s website',
			maxWidth: '680px',
			width: 'auto',
			role: 'alert',
			content: {
				children: (
					<>
						<p>
							Please refer to our <a target="_blank" rel="noopener noreferrer" href="/terms-of-use">Terms of Use</a> to learn how we use Third Party providers to deliver content and functionality and what this means for you.						</p>
						<a
							{...anchorProps}
							data-tr-link-event-name={'continue to partner site'}
							className="tw-font-semibold tw-mb-4 tw-text-gray tw-no-underline tw-border tw-border-gray tw-py-1 tw-px-2 hover:tw-text-white hover:tw-bg-gray"
						>
							Continue to Partner Site
						</a>
					</>
				),
			},
		});
	}

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<a
			{...anchorProps}
			onClick={isOn('THIRD_PARTY_LINK_MODAL') && linkExternal ? openThirdPartyModal : e => props.onClick?.(e) }
			className={classNames(
				className,
				{
					'no-print-underline': !printAsLink,
				}
			)}
		>
			{props.children}
		</a>
	);
};
