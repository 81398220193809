import {
	action,
	makeObservable,
} from 'mobx';

import { AccountLink } from '~/global/header-mobile/Models/AccountLink.model';
import { HeaderMobile } from '~/global/header-mobile/Models/HeaderMobile.model';
import { TopMenuMobileStore } from '~/global/top-menu/Stores/TopMenuMobile.store';

import type { SiteMarketingMessaging } from '~/site-marketing-messaging/Models/SiteMarketingMessaging.model';

export class HeaderMobileStore {
	accountLink: AccountLink;

	headerMobile: HeaderMobile;

	siteMarketingMessagingModel: SiteMarketingMessaging;

	topMenuMobileStore: TopMenuMobileStore | any;

	constructor({
		accountLink,
		headerMobile,
		siteMarketingMessagingModel,
		topMenuMobileStore,
	}: { accountLink: AccountLink, headerMobile: HeaderMobile, siteMarketingMessagingModel: SiteMarketingMessaging, topMenuMobileStore: TopMenuMobileStore | any}) {
		makeObservable(this, {
			setHeaderHeight: action.bound,
			setIsAccountOpen: action.bound,
			setIsMenuOpen: action.bound,
			setIsSearchOpen: action.bound,
			setScrollY: action.bound,
			toggleIsAccountOpen: action.bound,
			toggleIsMenuOpen: action.bound,
			toggleIsSearchOpen: action.bound,
		});
		this.accountLink = accountLink;
		this.headerMobile = headerMobile;
		this.siteMarketingMessagingModel = siteMarketingMessagingModel;
		this.topMenuMobileStore = topMenuMobileStore;
	}

	setHeaderHeight(height: number) {
		this.headerMobile.headerHeight = height;
	}

	setIsAccountOpen(isAccountOpen: boolean) {
		this.headerMobile.isAccountOpen = isAccountOpen;

		if (isAccountOpen) {
			this.setIsMenuOpen(false);
			this.setIsSearchOpen(false);
		}
	}

	setIsMenuOpen(isMenuOpen: boolean) {
		if (isMenuOpen) {
			this.topMenuMobileStore.open();
			this.setIsAccountOpen(false);
			this.setIsSearchOpen(false);
			this.headerMobile.isScrollingUp = false;
		} else {
			this.topMenuMobileStore.close();
		}

		this.headerMobile.isMenuOpen = isMenuOpen;
	}

	setIsSearchOpen(isSearchOpen: boolean) {
		this.headerMobile.isSearchOpen = isSearchOpen;

		if (isSearchOpen) {
			this.setIsAccountOpen(false);
			this.setIsMenuOpen(false);
		}
	}

	setScrollY(scrollY: number) {
		this.setIsScrollingUp(scrollY);
		this.headerMobile.scrollY = scrollY;
	}

	setIsScrollingUp(scrollY: number) {
		const headerScrollYThresholdStart = this.siteMarketingMessagingModel?.hasSiteMarketingMessages ? 40 : 0;
		const headerScrollYThresholdEnd = headerScrollYThresholdStart + this.headerMobile.headerHeight;
		const scrollYIsIncreasing = scrollY > this.headerMobile.scrollY;
		const scrollYIsDecreasing = scrollY < this.headerMobile.scrollY;

		// if the new scrollY position is less than the last scrollY while the header is scrolled out of view, we are scrolling up
		if (scrollYIsIncreasing || scrollY <= headerScrollYThresholdStart) {
			this.headerMobile.isScrollingUp = false;
		} else if (scrollYIsDecreasing && scrollY > headerScrollYThresholdEnd) {
			this.headerMobile.isScrollingUp = true;
		}
	}

	toggleIsAccountOpen() {
		this.setIsAccountOpen(!this.headerMobile.isAccountOpen);
	}

	toggleIsMenuOpen() {
		this.setIsMenuOpen(!this.headerMobile.isMenuOpen);
	}

	toggleIsSearchOpen() {
		this.setIsSearchOpen(!this.headerMobile.isSearchOpen);
	}
}

export const HeaderMobileStoreFactory = {
	create: ({
		globalDynamic = {},
		isAccountOpen = false,
		isMenuOpen = false,
		isSearchOpen = false,
		scrollY = 0,
		siteMarketingMessagingModel,
		topMenuMobileStore = {},
		HREF = {},
		featureTogglesModel = {},
	}: any = {}) => {
		const accountLink = new AccountLink({ globalDynamic, HREF, featureTogglesModel });

		const headerMobile = new HeaderMobile({
			globalDynamic,
			isAccountOpen,
			isMenuOpen,
			isSearchOpen,
			scrollY,
		});

		const headerMobileStore = new HeaderMobileStore({
			accountLink,
			headerMobile,
			siteMarketingMessagingModel,
			topMenuMobileStore,
		});

		return headerMobileStore;
	},
};
