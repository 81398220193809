import React from 'react';

export const ContactUsSuccess = () => {
	return (
		<div role={'status'}>
			<p className="Heading Heading--4 tw-text-brown tw-mb-1">Thank you for contacting us</p>
			<p className="Heading Heading--5 tw-mb-6 tw-text-gray">Your email has been sent and will be answered as soon as possible.</p>
		</div>
	);
};
