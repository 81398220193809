import React, { useEffect } from 'react';

import smoothscroll from 'smoothscroll-polyfill';
import '~/lib/mobx.config';
import { AppProps } from 'next/app';

import { useRNBRouter } from '~/hooks/useRNBRouter';
import type { AppConfigPageProps } from '~/global/app-config/AppConfig.types';
import { GlobalContextProvider } from '~/global/Contexts/Global.context';
import { isOnServer } from '~/global/global.constants';
import { getAxiosWithIndividualHeaders } from '~/global/app-config/ipHeaders';
import { apiConsoleLogging } from '~/global/app-config/apiConsoleLogging';
import { useLogoutRedirect } from '~/hooks/useLogoutRedirect';
import { PageTransition } from '~/global/PageTransition';

import '#/global/tailwind.scss';
import '#/global/main.scss';

declare global {
	interface Window {
		rnbRouter: any;
	}
}

export default function MyApp({ Component, pageProps, router }: AppProps<AppConfigPageProps>) {
	const {
		authorization,
		globalDynamicData: {
			enabledFeatures = [],
		} = {},
		trueClientIpHeader = '',
		xBypassRatelimitingHeader = '',
		xForwardedForHeader = '',
	} = pageProps;

	// Cypress fast-forward page. Used for Engage headless login.
	// Return early with no wrappers to prevent unauthroized redirects.
	if (router.route === '/automation') {
		return <Component {...pageProps} />;
	}
	useLogoutRedirect(Boolean(authorization));
	if (!isOnServer) {
		getAxiosWithIndividualHeaders(trueClientIpHeader, xForwardedForHeader, xBypassRatelimitingHeader);
		smoothscroll.polyfill();
	}
	apiConsoleLogging(
		enabledFeatures,
		pageProps,
		{ title: `pageProps for route, "${router.asPath}" (${router.route})` },
	);

	const rnbRouter = useRNBRouter();

	useEffect(() => {
		if (typeof window.rnbRouter === 'undefined') {
			window.rnbRouter = rnbRouter;
		}
	}, []);

	return (
		<GlobalContextProvider pageProps={pageProps}>
			{
				!isOnServer &&
				<PageTransition />
			}
			<Component {...pageProps} />
		</GlobalContextProvider>
	);
}
