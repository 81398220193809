import React from 'react';

import {
	EditAdditionalInfoFormFields, EmailSignupFormFields, EmailSubscriptionCheckboxFields, Subscriptions, UnsubscribeFormFields
} from '~/email-subscription/Types/EmailSubscription.type';
import { SubscriptionCheckboxLabel } from '~/email-subscription/Components/SubscriptionCheckboxLabel';
import { EmailPreferencesRadioLabel } from '~/email-subscription/Components/EmailPreferencesRadioLabel';
import { unsubscribe, updateSubscription } from '~/email-subscription/Utils/EmailSubscription.utils';
import { EmailSignupPageStore } from '~/email-subscription/Stores/EmailSignupPage.store';
import { FieldSettings } from '~/util/formz/Interfaces/FormSettings.interface';

export const emailSignupFormId = 'email-opt-in';
export const emailPreferencesFormId = 'email-preferences-manage';
export const emailUnsubscribeFormId = 'email-unsubscribe';

export enum EmailSignupPageViewState {
	Loading = 'Loading',
	NotSubscribed = 'NotSubscribed',
	OnABreak = 'OnABreak',
	Preferences = 'Preferences',
	Signup = 'Signup',
}

export enum UnsubscribePageViewState {
	UnsubscribeForm = 'UnsubscribeForm',
	UnsubscribeConfirmation = 'UnsubscribeConfirmation'
}

export enum EmailPreferenceOption {
	TakeABreak = 'TakeABreak',
	Unsubscribe = 'Unsubscribe',
	Update = 'Update',
}

export enum PreferencesView {
	Account = 'Account',
	Standalone = 'Standalone',
}

export enum EmailSubscriptionSource {
	Footer = 'FOOTER',
	PreferenceCenter = 'PREFERENCE_CENTER',
}

export enum EmailSubscriptionError {
	InvalidEmail = 'salesforceInvalidEmail'
}

export const navToView: Record<PreferencesView, (
	store: EmailSignupPageStore,
	isNotSubscribed: boolean,
	breakEndDate: string,
	email: string,
) => void> = {
	[PreferencesView.Account]: (store: EmailSignupPageStore, isNotSubscribed: boolean, breakEndDate: string, email: string) => {
		if (isNotSubscribed) {
			store.viewState?.goTo(EmailSignupPageViewState.NotSubscribed);

			return;
		}

		if (breakEndDate) {
			store.navToOnABreak(breakEndDate, email);

			return;
		}

		store.navToPreferencesForm();
		store.setShouldHideSuccessMessage(true);
	},
	[PreferencesView.Standalone]: (store: EmailSignupPageStore) => {
		if (!store.emailToUpdate || store.routerQuery?.isNewSubscription) {
			store.viewState?.goTo(EmailSignupPageViewState.Signup);

			return;
		}

		store.navToPreferencesForm();
		store.setShouldHideSuccessMessage(true);
	},
};

export const updateEmailPreferences: Record<EmailPreferenceOption, (email: string, subscriptions: Subscriptions, store: EmailSignupPageStore, zipCode: string) => Promise<void>> = {
	[EmailPreferenceOption.TakeABreak]: async (email: string, _, store: EmailSignupPageStore) => {
		await store.startBreak(email);
	},
	[EmailPreferenceOption.Unsubscribe]: async (email: string, _, store: EmailSignupPageStore) => {
		await unsubscribe({ email });
		store.viewState?.goTo(EmailSignupPageViewState.NotSubscribed);
	},
	[EmailPreferenceOption.Update]: async (email: string, subscriptions: Subscriptions, _, zipCode?: string): Promise<any> => {
		const result = await updateSubscription({
			email, zipCode, source: EmailSubscriptionSource.PreferenceCenter, subscriptions
		});
		return result;
	},
};

export enum PreferencesFormState {
	Initial = 'Initial',
	Saved = 'Saved',
	Submitting = 'Submitting',
}

export const emailSubscriptionCheckboxFields: EmailSubscriptionCheckboxFields = {
	clearanceEvents: {
		control: {
			reactProps: {
				type: 'checkbox',
			},
		},
		label: {
			reactProps: {
				children: (
					<SubscriptionCheckboxLabel
						subscriptionFrequencyText={'twice per year'}
						subscriptionTypeText={'Clearance events'}
					/>
				)
			},
		},
		name: 'clearanceEvents'
	},
	commercialProductsAndEvents: {
		control: {
			reactProps: {
				type: 'checkbox',
			},
		},
		label: {
			reactProps: {
				children: (
					<SubscriptionCheckboxLabel
						subscriptionFrequencyText={'once per week'}
						subscriptionTypeText={'Commercial products and events'}
					/>
				)
			},
		},
		name: 'commercialProductsAndEvents'
	},
	designIdeas: {
		control: {
			reactProps: {
				type: 'checkbox',
			},
		},
		label: {
			reactProps: {
				children: (
					<SubscriptionCheckboxLabel
						subscriptionFrequencyText={'3 to 7 per week'}
						subscriptionTypeText={'Design ideas, inspiration and new products'}
					/>
				)
			},
		},
		name: 'designIdeas'
	},
	storeEvents: {
		control: {
			reactProps: {
				type: 'checkbox',
			},
		},
		label: {
			reactProps: {
				children: (
					<SubscriptionCheckboxLabel
						subscriptionFrequencyText={'varies by location, based on your ZIP code'}
						subscriptionTypeText={'Store events'}
					/>
				)
			},
		},
		name: 'storeEvents'
	},
};

export const radioClassName = 'tw-flex tw-items-start tw-pb-2';
export const radioLabelClassName = 'tw-pt-1';

export const emailPreferencesRadioFields = [
	{
		reactProps: {
			className: radioClassName
		},
		control: {
			reactProps: {
				'value': EmailPreferenceOption.Unsubscribe,
				'data-qa': 'unsubscribe-radio',
			},
		},
		label: {
			reactProps: {
				children: (
					<EmailPreferencesRadioLabel
						preferencesTypeText={'Unsubscribe'}
						preferencesDescriptionText={"We're sorry to see you go, but we're here if you change your mind"}
					/>
				),
				className: radioLabelClassName
			},
		},
	},
	{
		reactProps: {
			className: radioClassName
		},
		control: {
			reactProps: {
				'value': EmailPreferenceOption.TakeABreak,
				'data-qa': 'take-a-break-radio',
			},
		},
		label: {
			reactProps: {
				children: (
					<EmailPreferencesRadioLabel
						preferencesTypeText={'Take a four-month break'}
						preferencesDescriptionText={"We'll contact you when your break is ending"}
					/>
				),
				className: radioLabelClassName
			},
		},
	},
	{
		reactProps: {
			className: radioClassName
		},
		control: {
			reactProps: {
				'value': EmailPreferenceOption.Update,
				'data-qa': 'update-radio',
			},
		},
		label: {
			reactProps: {
				children: (
					<EmailPreferencesRadioLabel
						preferencesTypeText={'Update my subscription'}
						preferencesDescriptionText={"Choose what you'd like to receive"}
					/>
				),
				className: radioLabelClassName
			},
		},
	},
];

export const emailSettings: FieldSettings = {
	validationConstraints: {
		presence: {
			message: '^Enter your email address',
		},
		email: {
			message: '^Please enter a valid email address.',
		},
		length: {
			maximum: 241,
		},
	},
};

export const unsubscribeFormFields: UnsubscribeFormFields = {
	email: {
		label: {
			reactProps: {
				children: 'Email Address',
			},
		},
		control: {
			reactProps: {
				'type': 'email',
				'className': 'tw-w-full',
				'required': true,
			},
		},
		name: 'email',
		settings: emailSettings
	}
};

export const editAdditionalInfoFormFields: EditAdditionalInfoFormFields = {
	firstName: {
		label: {
			reactProps: {
				children: 'First Name',
			},
		},
		control: {
			reactProps: {
				'autoComplete': 'given-name',
				'className': 'tw-w-full',
				'maxLength': 40,
			},
		},
		name: 'firstName',
		settings: {
			validationConstraints: {
				length: {
					maximum: 40,
				},
			},
		},
	},
	lastName: {
		label: {
			reactProps: {
				children: 'Last Name',
			},
		},
		control: {
			reactProps: {
				'autoComplete': 'family-name',
				'className': 'tw-w-full',
				'maxLength': 40,
			},
		},
		name: 'lastName',
		settings: {
			validationConstraints: {
				length: {
					maximum: 40,
				},
			},
		},
	},
	zipCode: {
		label: {
			reactProps: {
				children: (
					<span>
						ZIP Code
						<br />
						<span className={'tw-text-gray-300 tw-font-normal'}>Used for sending you localized emails</span>
					</span>
				)
			},
		},
		control: {
			reactProps: {
				'autoComplete': 'postal-code',
				'className': 'tw-max-w-[128px]',
				'maxLength': 10,
			},
		},
		name: 'zipCode',
		settings: {
			validationConstraints: {
				zipCode: {
					required: false,
				},
				length: {
					minimum: 0,
					maximum: 10,
					message: '^Enter a valid ZIP code',
				},
			},
		},
	},
};

export const emailSignupFormFields: EmailSignupFormFields = {
	...emailSubscriptionCheckboxFields,
	...editAdditionalInfoFormFields,
	email: {
		label: {
			reactProps: {
				children: 'Email Address (required)',
			},
		},
		control: {
			reactProps: {
				'type': 'email',
				'className': 'tw-w-full',
				'maxLength': 241,
				'required': true,
			},
		},
		name: 'email',
		settings: emailSettings
	},
};
