import { ComponentProps, HTMLProps } from 'react';
import { LinkProps as NextLinkProps } from 'next/link';

import { ButtonBase } from '~/components/Buttons/Components/ButtonBase';

export enum ButtonVariant {
	Dark = 'ButtonDark',
	Hollow = 'HollowButton',
	HollowSupport = 'HollowButtonSupport',
	HollowTiny = 'HollowButtonTiny',
	Primary = 'Button',
	PrimaryOutline = 'ButtonOutline',
	Secondary = 'ButtonSecondary',
	Support = 'ButtonSupport',
	Tertiary = 'ButtonTertiary',
	ArrowDown = 'ButtonArrowDown',
	ArrowLeft = 'ButtonArrowLeft',
	ArrowRight = 'ButtonArrowRight',
	ArrowUp = 'ButtonArrowUp',
	Arrow = 'ButtonArrow',
	Icon = 'ButtonIcon',
}

export enum ButtonColor {
	Gray = 'gray',
	White = 'white',
	Red = 'red',
}

export enum TextLinkColor {
	Blue = 'blue',
	Red = 'red',
	Gray = 'gray',
}

export enum ArrowButtonDirection {
	Up = 'Up',
	Down = 'Down',
	Left = 'Left',
	Right = 'Right',
}

export enum ArrowButtonSize {
	Small = 'Small',
	Medium = 'Medium',
}
export interface ArrowButtonProps {
	direction: ArrowButtonDirection;
	size: ArrowButtonSize;
}

type AdditionalProps<V> = V extends ButtonVariant.Arrow ? ArrowButtonProps : {};

export type ButtonProps<V extends ButtonVariant = ButtonVariant> = {
	variant?: V;
	color?: ButtonColor;
	isTransparent?: boolean;
} & AdditionalProps<V> & Omit<ComponentProps<typeof ButtonBase>, 'color'>;

export type ButtonLinkProps<V extends ButtonVariant = ButtonVariant> = {
	variant?: V;
	color?: ButtonColor;
	isTransparent?: boolean;
} & NextLinkProps<V> & Omit<HTMLProps<HTMLAnchorElement>, 'ref'>;
