import {
	autorun, computed, observable, makeObservable,
} from 'mobx';

import { getAccessibleReactProps } from '~/util/formz/util/util';
import { isOnServer } from '~/global/global.constants';

let Inputmask;
const loadInputMask = async function () {
	if (!isOnServer && !Inputmask) {
		Inputmask = (await import('inputmask')).default;
	}
	return Inputmask;
};

class MaskPlugin {
	constructor(name, fieldObs, form, settings) {
		makeObservable(this, {
			twoWayAutoRun: observable,
			jqMaskReactProps: computed,
			applyMaskPlugin: observable.ref,
			removeMask: observable.ref,
		});

		this.name = name;
		this.fieldObs = fieldObs;
		this.form = form;
		this.settings = settings;

		if (!this.settings.enableTwoWayData) {
			// this will clobber any existing onChange handlers!!!!
			this.fieldObs.control.reactPropsMap.delete('onChange');
		}
	}

	twoWayAutoRun = null

	get jqMaskReactProps() {
		const reactProps = getAccessibleReactProps({ field: this.fieldObs });

		const alteredProps = {
			defaultValue: this.fieldObs.control.reactProps.value,
			...reactProps,
		};

		delete alteredProps.value;
		return alteredProps;
	}

	async applyMaskPlugin() {
		const InputMaskPlugin = await loadInputMask();
		const input = this.fieldObs.control.controlRef;
		const {
			placeholder,
			onBeforeWrite,
		} = this.settings.maskOverrides || {};
		const maskSettings = {
			mask: this.settings.mask,
			showMaskOnFocus: false,
			showMaskOnHover: false,
			...(!this.settings.enableTwoWayData) && {
				onBeforeWrite: () => {
					this.form.updateModelValue(this.name, input.value);
				},
			},
		};

		if (placeholder !== undefined) {
			maskSettings.placeholder = placeholder;
		}
		if (typeof onBeforeWrite === 'function') {
			// Pass in the form for convenience.
			maskSettings.onBeforeWrite = (event, ...args) => {
				onBeforeWrite(event, this.form, ...args);
			};
		}
		const mask = new InputMaskPlugin(maskSettings);

		if (input) {
			mask.mask(input);
		}

		if (!isOnServer) {
			// Enable two way communication
			if (this.settings.enableTwoWayData) {
				this.twoWayAutoRun = autorun(() => {
					const newValue = this.form.model[this.name];

					if (newValue === null || typeof newValue === 'undefined') {
						return;
					}
					const formattedValue = Inputmask ? Inputmask.format(newValue, { inputFormat: this.settings.mask }) : null;

					if (formattedValue === null || !input || !input.inputmask) {
						return;
					}
					input.inputmask.setValue(formattedValue);
				});
			}
		}
	}

	removeMask() {
		const input = this.fieldObs.control.controlRef;

		if (input.inputmask) {
			input.inputmask.remove();
		}

		if (this.twoWayAutoRun) {
			this.twoWayAutoRun();
		}
	}

	// Helper method to update both the model and the input mask on the DOM element.
	updateModelValue(newValue) {
		if (newValue === null || typeof newValue === 'undefined') {
			return;
		}
		const input = this.fieldObs.control.controlRef;
		const formattedValue = Inputmask ? Inputmask.format(newValue, { inputFormat: this.settings.mask }) : null;

		this.form.model[this.fieldObs.name] = newValue;
		if (formattedValue === null || !input || !input.inputmask) {
			return;
		}
		input.inputmask.setValue(formattedValue);
	}
}

export { MaskPlugin };
