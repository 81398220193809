export class SubMenuItem {
	data: any

	constructor(data: any = {}) {
		this.data = data;
	}

	get id() {
		return this.data.id;
	}

	get columnKey() {
		return this.data.columnKey;
	}

	get title() {
		return this.data.title;
	}

	get url() {
		return this.data.url;
	}

	get linkMobile() {
		return this.data.linkMobile;
	}

	get classes() {
		return this.data.classes;
	}

	get hiddenOnFull() {
		return this.data.hideFull;
	}

	get hiddenOnMobile() {
		return this.data.hideMobile;
	}

	get isSubMenuVertical() {
		return this.data.submenuVertical;
	}
}
