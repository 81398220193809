export class TrackingDebugMode {
	featureTogglesModel

	constructor(featureTogglesModel) {
		this.featureTogglesModel = featureTogglesModel;
	}

	get isDebugModeOn() {
		return this.featureTogglesModel.isOn('DEBUG_MODE');
	}

	get shouldLogLinkEvents() {
		return this.isDebugModeOn && this.featureTogglesModel.isOn('LINK_EVENT_DATA_LAYER_LOGGING');
	}
}

export const TrackingDebugModeFactory = {
	create(featureTogglesModel) {
		const debugMode = new TrackingDebugMode(featureTogglesModel);
		return debugMode;
	},
};
