import React from 'react';
import { observer } from 'mobx-react';

import { getAccessibleReactProps } from '~/util/formz/util/util';

export const Select = observer((props) => {
	const reactProps = getAccessibleReactProps(props);
	const {
		key: selectKey,
		...selectReactProps
	} = reactProps;

	return <select key={selectKey} {...selectReactProps}>{props.children || null}</select>;
});
