import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { magicOverlayMessages } from '~/components/magic-overlay/magicOverlayMessages';
import { isString } from '~/util/isString';

export const OverlayMessage = observer(class OverlayMessage extends React.Component {
	render() {
		const overlayMessageStyleProps = {
			opacity: this.props.store.model.shouldShowMessage ? 1 : 0.001,
			...(this.props.store.model.messageCssPosY !== null && {
				top: this.props.store.model.messageCssPosY,
			}),
		};
		const {
			props: {
				store: {
					model: {
						message,
					},
				},
			},
		} = this;

		return (
			<div
				className={cn('MagicOverlay-message', {
					[this.props.className]: Boolean(this.props.className),
					'no-spinner': !this.props.store.model.useSpinner,
					'is-global': this.props.store.model.isPositionGlobal,
				})}
				ref={(elem) => { this.elem = elem; }}
				style={overlayMessageStyleProps}
			>
				{
					message === true &&
					<span className="MagicOverlay-messageText">{magicOverlayMessages.DEFAULT}</span>
				}
				{
					isString(message) &&
					<span className="MagicOverlay-messageText">{message}</span>
				}
				{
					// message is a component - go completely custom
					typeof message !== 'boolean' && !isString(message) && message !== null &&
					message
				}
			</div>
		);
	}
});
