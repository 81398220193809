import { observable, toJS, makeObservable } from 'mobx';

export class FieldValidationMessage {
	constructor(name, formValidatorPlugin, form) {
		makeObservable(this, {
			reactPropsMap: observable,
		});

		this.name = name;
		this.formValidatorPlugin = formValidatorPlugin;
		this.form = form;
		this.validationMessageId = `${this.name}-validationMessage-${this.form.id}`;
		this.reactPropsMap = observable.map({
			'id': this.validationMessageId,
			'key': this.validationMessageId,
			'className': `${this.validationMessageId} validation`,
			'aria-live': 'polite',
		});
	}

	validationMessageId = null

	reactPropsMap = null;

	get reactProps() {
		return Object.fromEntries(toJS(this.reactPropsMap));
	}

	get errorMessage() {
		if (this.rawError) {
			return this.rawError.error;
		}
		return null;
	}

	get rawError() {
		return this.formValidatorPlugin.validationMessages.get(this.name);
	}
}
