import { SubMenuItem } from '~/global/top-menu/Models/SubMenuItem.model';

export class SubMenu {
	data: any

	subMenuItems: Array<SubMenuItem>

	constructor(data: any = {}, subMenuItems: Array<SubMenuItem> = []) {
		this.data = data;
		this.subMenuItems = subMenuItems;
	}

	get id() {
		return this.data.id;
	}

	get title() {
		return this.data.title;
	}

	get columnKey() {
		return this.data.columnKey;
	}

	get url() {
		return this.data.url;
	}

	get classes() {
		return this.data.classes;
	}

	get promoClasses() {
		return this.data.promoClasses;
	}

	get hiddenOnFull() {
		return this.data.hideFull;
	}

	get hiddenOnMobile() {
		return this.data.hideMobile;
	}

	get isSubMenuVertical() {
		return this.data.submenuVertical;
	}
}
export const SubMenuFactory = {
	create(subMenuData: any, subMenuItems: Array<any>) {
		const subMenu = new SubMenu(subMenuData, subMenuItems);

		return subMenu;
	},
};
