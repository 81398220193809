import { action } from 'mobx';

import { isEmpty } from '~/util/isEmpty';
import { isFunction } from '~/util/isFunction';

const getAccessibleReactProps = (props) => {
	const {
		field,
		controlReactPropsOverride,
	} = props;
	const hasError = !isEmpty(field?.plugins?.validationMessage?.errorMessage);
	const validationId = field?.plugins?.validationMessage?.reactProps?.id;
	let reactProps = {
		...field.control.reactProps,
		'aria-invalid': hasError,
	};

	if (hasError) {
		reactProps = {
			...reactProps,
			'aria-describedby': validationId,
		};
	}

	if (controlReactPropsOverride) {
		reactProps = {
			...reactProps,
			...controlReactPropsOverride,
		};
	}

	return reactProps;
};
const safeFunctionWrapper = (functionToWrap, options) => {
	return action('safe wrap', (...args) => {
		if (isFunction(options.before)) {
			options.before.apply(this, args);
		}

		functionToWrap.apply(this, args);

		if (isFunction(options.after)) {
			options.after.apply(this, args);
		}
	});
};
const safeWrapOrCreate = (mapToModify, eventName, functionToExecute, options) => {
	const settings = {
		executeBefore: true,
		...options,
	};
	const safeEventOptions = {};

	// the event already exists!
	if (mapToModify.has(eventName)) {
		// wrap the current event handler
		if (settings.executeBefore) {
			safeEventOptions.before = functionToExecute;
		} else {
			safeEventOptions.after = functionToExecute;
		}
		mapToModify.set(eventName, safeFunctionWrapper(mapToModify.get(eventName), safeEventOptions));
	} else {
		// no event was found, just set it
		mapToModify.set(eventName, functionToExecute);
	}
};

export {
	getAccessibleReactProps,
	safeWrapOrCreate,
	safeFunctionWrapper,
};
