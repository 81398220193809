'use client';

import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';

export const ViewState = observer(({ viewState, classNames = '' }: { viewState: ViewStateStore, classNames?: string }) => {
	if (!viewState) {
		return null;
	}
	const currentViewKey = viewState.currentView?.key || 'default';
	const ViewStateComponent = viewState.currentView?.component as React.ComponentClass;

	if (!ViewStateComponent) {
		return null;
	}
	return (
		<div
			className={cn(`view-state-container view-state-container-${currentViewKey}`, {
				[classNames]: Boolean(classNames),
			})}
			data-qa={`view-state-${currentViewKey}`}
		>
			<ViewStateComponent {...viewState.viewProps} />
		</div>
	);
});
