import { action, makeObservable } from 'mobx';

import { IVisitorJSON } from '~/tracking/visitor-event/Interfaces/IVisitorEvent.tracking';
import { isEngage, isOnServer } from '~/global/global.constants';
import { getParameterByName } from '~/util/getParameterByName';
import { LAUNCH_PLACEHOLDER_TEXT } from '~/util/launchUtils';
import { sanitize } from '~/util/sanitizeString';

export class VisitorEventModel {
	extQueryParams = ['et_cid', 'camp'];

	globalDynamicStore;

	intQueryParams = ['icamp', 'icid'];

	queryParams: any;

	constructor(globalDynamicStore: any) {
		makeObservable(this, {
			getUrlParams: action.bound,
		});

		this.globalDynamicStore = globalDynamicStore;
	}

	getUrlParams(params: Array<string>): string | null {
		const paramValues = params.map((param = '') => getParameterByName(param)).filter(Boolean);

		const [firstParamValue = ''] = paramValues;

		return firstParamValue || null;
	}

	get visitorData() {
		return this.globalDynamicStore?.model?.analytics || {};
	}

	get da() {
		return sanitize(`${this.visitorData.employeeNumber}|${this.visitorData.employeeName}`);
	}

	get extCamp(): string | null {
		const extCampResult = this.getUrlParams([...this.extQueryParams]);
		return extCampResult ? sanitize(extCampResult) : null;
	}

	get group(): string {
		return sanitize(this.visitorData.adobeLaunchIpAddressClassificationType);
	}

	get intCamp(): string | null {
		const intCampResult = this.getUrlParams(this.intQueryParams);
		return intCampResult ? sanitize(intCampResult) : null;
	}

	get locationName() {
		return sanitize(this.visitorData?.locationName || '');
	}

	get locationNumber() {
		return sanitize(this.visitorData?.locationNumber || '');
	}

	get location() {
		return `${this.locationNumber}|${this.locationName}`;
	}

	get member() {
		return this.visitorData.isGuest ? 'guest' : 'member';
	}

	get sapID(): number | string {
		return this.visitorData.sapCustomerNumber || LAUNCH_PLACEHOLDER_TEXT;
	}

	get site(): string {
		return sanitize(window?.location?.hostname?.replace?.('www.', ''));
	}

	get status(): string {
		if (isEngage) {
			return this.visitorData.sapCustomerNumber ? 'proxy' : 'guest';
		}
		return this.visitorData.isLoggedIn ? 'logged in' : 'logged out';
	}

	get engageJson() {
		const json = {
			location: this.location,
			da: this.da,
		};
		return json;
	}

	get trackingJson(): IVisitorJSON {
		const json: IVisitorJSON = {
			visitor: {
				'group': this.group,
				'id': this.visitorId,
				'member': this.member,
				'sapID': this.sapID,
				'site': this.site,
				'status': this.status,
			},
		};

		if (this.extCamp) {
			json.visitor.extCamp = this.extCamp;
		}

		if (this.intCamp) {
			json.visitor.intCamp = this.intCamp;
		}

		if (isEngage) {
			json.engage = {
				...this.engageJson,
			};
		}

		return json;
	}

	get eventTrackingJson() {
		const json = {
			'event': 'visitor',
			...this.trackingJson,
		};

		return json;
	}

	get visitorId(): number | string {
		try {
			if (this.globalDynamicStore.model.customerId) {
				return this.globalDynamicStore.model.customerId;
			}
			return LAUNCH_PLACEHOLDER_TEXT;
		} catch (error: unknown) {
			return LAUNCH_PLACEHOLDER_TEXT;
		}
	}

	track(forceTrack = false) {
		if (!isOnServer) {
			const json = this.eventTrackingJson;
			if (!window.upcomingPagesEventDataLayer) {
				window.upcomingPagesEventDataLayer = [];
			}

			if (!window.eventDataLayer) {
				window.eventDataLayer = [];
			}

			if (!window.routeChangeStarted) {
				const pageViewExists = window.eventDataLayer.some(pageView => pageView.event === json.event);
				if (!pageViewExists || forceTrack) {
					window.eventDataLayer.push(json);
				}
			} else {
				window.upcomingPagesEventDataLayer.push(json);
			}
		}
	}
}

export const VisitorEventModelFactory = {
	create: (globalDynamicStore?: any) => {
		const model = new VisitorEventModel(globalDynamicStore);

		return model;
	},
};
