import { SyncRedactor } from 'redact-pii-simple';

import { LAUNCH_PLACEHOLDER_TEXT, PAGE_CONTENT_PLACEHOLDER_TEXT } from '~/util/launchUtils';
import { sanitize } from '~/util/sanitizeString';

const EventTypes = {
	SITE_ACTION: {
		label: 'site action',
	},
	EMAIL: {
		label: 'email link',
	},
	DOWNLOAD: {
		label: 'file download',
	},
	SOCIAL: {
		label: 'social link',
	},
	INTERNAL: {
		label: 'internal navigation link',
	},
	EXIT: {
		label: 'exit link',
	},
	VIDEO: {
		label: 'video',
	},
};

export const LinkEventTypes = Object.keys(EventTypes).reduce((a, v) => ({ ...a, [v]: v }), {});

export class LinkEventModel {
	clickEvent = {};

	// AL-416 third party script downloadjs that we don't want to track
	excludeClasses = ['download-js-link'];

	featureTogglesModel;

	fileDownloadExtensions = [
		'doc', 'docx', 'eps', 'jpg', 'png', 'svg', 'xls', 'ppt', 'pptx', 'pdf', 'xlsx', 'tab', 'csv', 'zip', 'txt',
		'vsd', 'vxd', 'xml', 'js', 'css', 'rar', 'exe', 'wma', 'mov', 'avi', 'wmv', 'mp3', 'wav', 'm4v',
	];

	hasInitialized = false;

	wasSuccessful;

	_componentName;

	_componentType;

	_componentPosition;

	_linkType;

	_name;

	_href;

	_shouldTrack = true;

	_shouldUsePageComponentName = false;

	socialDomains = [
		'facebook.com', 'instagram.com', 'pinterest.com', 'tiktok.com', 'youtube.com',
	];

	manualDataAttributes = {
		trLinkEventCompName: {
			setModelVal(val) {
				this.componentName = val;
			},
		},
		trLinkEventCompPosition: {
			setModelVal(val) {
				this.componentPosition = val;
			},
		},
		trLinkEventCompType: {
			setModelVal(val) {
				this.componentType = val;
			},
		},
		href: {
			setModelVal(val) {
				this.href = val;
			},
		},
		trLinkEventHref: {
			setModelVal(val) {
				this.href = val;
			},
		},
		trLinkEventName: {
			setModelVal(val) {
				this.name = val;
			},
		},
		trLinkEventTrack: {
			setModelVal(val) {
				this.shouldTrack = val;
			},
		},
		trLinkEventType: {
			setModelVal(val) {
				this.linkType = val;
			},
		},
		trLinkEventUseCompNameH1: {
			setModelVal(val) {
				this.shouldUsePageComponentName = val;
			},
		},
	};

	linkTypes = {
		SITE_ACTION: {
			label: 'site action',
			isType: () => this.isSiteAction,
		},
		INTERNAL: {
			label: 'internal navigation link',
			isType: () => this.isInternalNavigationLink,
		},
		EMAIL: {
			label: 'email link',
			isType: () => this.isEmailLink,
		},
		EXIT: {
			label: 'exit link',
			isType: () => this.isExitLink,
		},
		DOWNLOAD: {
			label: 'file download',
			isType: () => this.isDownloadLink,
		},
		SOCIAL: {
			label: 'social link',
			isType: () => this.isSocialLink,
		},
		VIDEO: {
			label: 'video',
			isType: () => this.isVideoLink,
		},
	};

	constructor(featureTogglesModel) {
		if (!featureTogglesModel) {
			throw new Error('featureTogglesModel not found.');
		}
		this.featureTogglesModel = featureTogglesModel;
		this.redactor = new SyncRedactor({
			builtInRedactors: {
				digits: {
					enabled: false,
				},
				names: {
					enabled: false,
				},
			},
		});
	}

	//------------------
	// Utility Functions
	//------------------
	matchingAttribute(attrName = '') {
		// sometimes we don't have a clickEvent, were manually sending in data
		if (!this.hasClickEvent) {
			return null;
		}
		return this.clickEvent?.attributes?.getNamedItem(attrName)?.value || null;
	}

	matchingParentAttribute(attrName = '', targetElement = undefined) {
		let element = targetElement;

		if (!element && this.hasClickEvent) {
			element = this.clickEvent;
		}

		// sometimes we don't have a clickEvent, were manually sending in data
		if (!element) {
			return null;
		}
		return element.closest(`[${attrName}]`)?.attributes?.getNamedItem(attrName)?.value || null;
	}

	//--------------------
	// Getters and Setters
	//--------------------
	get isPartOfForm() {
		// sometimes we don't have a clickEvent, were manually sending in data
		if (!this.clickEvent) {
			return false;
		}

		return this.clickEvent.closest('form') !== null && !this.clickEvent.closest('form').getAttribute('data-allow-tr-link-event');
	}

	get componentName() {
		if (this.shouldUsePageComponentName) {
			const h1Element = document.querySelector('h1:first-of-type');
			if (h1Element) {
				return this.cleanText(h1Element.innerText);
			}
		}
		return this.cleanText(this._componentName || this.matchingParentAttribute('data-tr-link-event-comp-name') || LAUNCH_PLACEHOLDER_TEXT);
	}

	set componentName(name) {
		this._componentName = this.cleanText(name);
	}

	get componentPosition() {
		const {
			clickEvent: {
				dataset: {
					trLinkEventCompPosition = null,
				} = {},
			} = {},
		} = this;
		return this._componentPosition || trLinkEventCompPosition || this.matchingParentAttribute('data-tr-link-event-comp-position') || LAUNCH_PLACEHOLDER_TEXT;
	}

	set componentPosition(position) {
		this._componentPosition = position;
	}

	get componentType() {
		return this.cleanText(this._componentType || this.matchingAttribute('data-tr-link-event-comp-type') || this.matchingParentAttribute('data-tr-link-event-comp-type') || PAGE_CONTENT_PLACEHOLDER_TEXT);
	}

	set componentType(name) {
		this._componentType = this.cleanText(name);
	}

	get hasClickEvent() {
		// sometimes we just pass in data and no clickEvent is set
		return this.clickEvent instanceof Node;
	}

	get hasExcludedClass() {
		return this.excludeClasses.includes(this.clickEvent.className);
	}

	get href() {
		return this._href || this.clickEvent?.href;
	}

	set href(href) {
		this._href = href;
	}

	get isAccount() {
		return window.location.pathname.includes('/account/');
	}

	get isDownloadLink() {
		return this.normalizedHref && this.fileDownloadExtensions.some(extension => this.normalizedHref.endsWith(`.${extension}`));
	}

	get isEmailLink() {
		return this.normalizedHref?.startsWith('mailto:');
	}

	get isExitLink() {
		if (!this.isHtmlLink || this.isDownloadLink) {
			return false;
		}

		const { hostname } = new URL(this.normalizedHref);

		return window.location.hostname !== hostname && !this.isSocialLink;
	}

	get isForceTrackModalLink() {
		const {
			clickEvent: {
				dataset: {
					trLinkEventTrackModalForce = null,
				} = {},
			} = {},
		} = this;
		return Boolean(trLinkEventTrackModalForce || this.matchingParentAttribute('data-tr-link-event-track-modal-force'));
	}

	get isHtmlLink() {
		// In Adobe terminology, "link" means any interaction/interactable in the website (including buttons, links, selects, radios, etc)
		return this.clickEvent.href;
	}

	get isHtmlPageLink() {
		const {
			hash = false,
			host,
		} = new URL(this.clickEvent.href);

		return !!hash && window.location.host === host;
	}

	get isInternalNavigationLink() {
		if (!this.isHtmlLink || this.isDownloadLink || this.isHtmlPageLink) {
			return false;
		}

		const { hostname = '' } = new URL(this.normalizedHref);

		return window.location.hostname === hostname;
	}

	get isProtocolRelative() {
		return this.clickEvent?.href.startsWith('//');
	}

	get isRootRelative() {
		return /^\/[^/]?/.test(this.clickEvent.href);
	}

	get isSiteAction() {
		return this.clickEvent?.dataset?.trLinkEventType || !this.isHtmlLink || this.isHtmlPageLink;
	}

	get isSocialLink() {
		if (!this.isHtmlLink || this.isHtmlPageLink) {
			return false;
		}

		const { hostname } = new URL(this.normalizedHref);

		return this.socialDomains.includes(hostname);
	}

	get name() {
		const {
			clickEvent: {
				innerText = null,
				text = null,
				dataset: {
					trLinkEventName = null,
				} = {},
				labels = [],
			} = {},
		} = this;

		let label;
		if (labels.length) {
			const {
				innerText: labelInnerText = null,
				text: labelText = null,
				dataset: {
					trLinkEventName: labelTrLinkEventName = null,
				} = {},
			} = labels[0];
			label = labelTrLinkEventName || labelInnerText || labelText;
		}
		return this.cleanText(this._name || trLinkEventName || label || innerText || text || this.matchingParentAttribute('data-tr-link-event-name') || LAUNCH_PLACEHOLDER_TEXT);
	}

	set name(name) {
		this._name = name;
	}

	get normalizedHref() {
		const {
			hostname,
			protocol,
		} = new URL(window.location);

		if (!this.isHtmlLink || this.isHtmlPageLink) {
			return this.clickEvent.href;
		}

		if (this.isProtocolRelative) {
			return protocol + this.clickEvent.href;
		}
		if (this.isRootRelative) {
			return `${protocol}//${hostname}${this.clickEvent.href}`;
		}
		return this.clickEvent.href;
	}

	get pageID() {
		const parsedUrl = new URL(window.location);
		const { pathname } = parsedUrl;
		const normalizedPathname = pathname.replace(/^\/m/, '');

		if (normalizedPathname === '/' || normalizedPathname.length === 0) {
			return 'homepage';
		}
		return normalizedPathname.replace(/\/(?!$)/g, ':').replace(/\/|(\..+$)/g, '');
	}

	set linkType(type) {
		if (type === null || !this.linkTypes[type]) {
			this._linkType = null;

			if (type !== null) {
				console.warn('Link Tracking Type not found: ', type);
			}
		} else {
			this._linkType = type;
		}
	}

	get shouldTrack() {
		const {
			clickEvent: {
				className = '',
				dataset: {
					trLinkEventTrack = true,
				} = {},
			} = {},
		} = this;

		const isStyliticsNavigationClickEvent = className.includes('stylitics-arrow-btn');

		if (isStyliticsNavigationClickEvent) {
			return false;
		}

		if (this.hasClickEvent && this.hasExcludedClass) {
			return false;
		}

		const parentDoNotTrack = this.matchingParentAttribute('data-tr-link-event-track');

		let trackIt = null;

		if (this.hasClickEvent) {
			trackIt = trLinkEventTrack;

			// if we are tracking the link, do a check for a no track on the container
			if (trackIt && parentDoNotTrack !== null) {
				trackIt = parentDoNotTrack;
			}
		}

		// if there is no clickEvent that means we are triggering the trackLinkEvent with data
		if (!this.hasClickEvent) {
			trackIt = this._shouldTrack;
		}
		return trackIt === true || trackIt === 'true';
	}

	set shouldTrack(val) {
		this._shouldTrack = val;
	}

	get shouldUsePageComponentName() {
		return this._shouldUsePageComponentName || this.componentType === PAGE_CONTENT_PLACEHOLDER_TEXT || Boolean(this.matchingParentAttribute('data-tr-link-event-use-comp-name-h1'));
	}

	set shouldUsePageComponentName(val) {
		this._shouldUsePageComponentName = val;
	}

	get type() {
		if (this._linkType) {
			return this.linkTypes[this._linkType];
		}

		const linkType = Object.entries(this.linkTypes).filter(typeEntry => typeEntry[1].isType()).map(type => type[1]);

		if (linkType.length === 0) {
			console.warn('Tracking issue: element has no interaction type (link type)');
		}
		if (linkType.length > 1) {
			console.warn('Tracking issue: element qualifies for more than one interaction type (link type). Should have only one.');
		}

		return linkType[0];
	}

	cleanText(text) {
		if (typeof text !== 'string') {
			return false;
		}
		return sanitize(this.redactor.redact(text));
	}
}
