type RedirectOptions = {
	replace?: boolean
}

const replaceUrl = (url: string) => {
	// if (window.rnbRouter) {
	// 	window.rnbRouter.replace(url);
	// } else {
	window.location.replace(url);
	// }
};

const changeUrl = (url: string) => {
	// if (window.rnbRouter) {
	// 	window.rnbRouter.push(url);
	// } else {
	window.location.href = url;
	// }
};

export const redirect = (url: string, options: RedirectOptions = {}) => {
	const config = {
		replace: false,
		...options,
	};

	// console.info(`Redirecting to ${url}`);
	if (config.replace && typeof url) {
		replaceUrl(url);
	} else {
		changeUrl(url);
	}
};

export const redirectToLogin = () => {
	redirect('/account/sign-in/');
};
