import { isEmpty } from '~/util/isEmpty';
import { isString } from '~/util/isString';
import { redirect } from '~/util/redirect';

// Redirect to login page if you are not authenticated.
export const unauthorizedRedirect = (xhrResponseData) => {
	try {
		const responseData = isString(xhrResponseData) ? JSON.parse(xhrResponseData) : xhrResponseData;
		const links = responseData?.errors?.[0]?._links;
		const redirectUrl = links?.loginPage?.href;

		if (!isEmpty(redirectUrl) && redirectUrl !== window.location.pathname) {
			redirect(redirectUrl);
		}
		return true;
	} catch (error) {
		return new Error(error);
	}
};

const getJSON = (value) => {
	try {
		return JSON.parse(value);
	} catch {
		return value;
	}
};

export const unauthorizedGOTRedirect = (response) => {
	const responseData = getJSON(response?.body);
	const links = responseData?.errors?.[0]?._links;
	const redirectUrl = links?.loginPage?.href;

	if (!isEmpty(redirectUrl)) {
		response.headers['Location'] = redirectUrl;
		return redirectUrl;
	}
};
