'use client';

import React, { useTransition } from 'react';
import { observer } from 'mobx-react';

import { MagicOverlay } from '~/components/magic-overlay/Components/MagicOverlay';
import { useMagicOverlayPageTransition } from '~/hooks/useMagicOverlayPageTransition';

export const PageTransition = observer(() => {
	const { clientSidePageTransition, transitionReady } = useMagicOverlayPageTransition();

	if (!transitionReady || !clientSidePageTransition.enabled.get()) {
		return null;
	}
	return (
		<>
			<MagicOverlay store={clientSidePageTransition.store} />
		</>
	);
});
