import { IFormSubmitJSON } from '~/tracking/form-submit-event/Interfaces/IFormSubmitEvent.tracking';

import { sanitize } from '~/util/sanitizeString';

export class FormSubmitEvent {
	id: string

	constructor(id: string) {
		this.id = id;
	}

	get trackingJson(): IFormSubmitJSON {
		return {
			event: 'formsubmit',
			form: {
				name: sanitize(this.id),
			},
		};
	}
}
