import React from 'react';
import { observer } from 'mobx-react';

import { isEmpty } from '~/util/isEmpty';
import { Input } from '~/util/formz/Components/controls/Input';
import { Label } from '~/util/formz/Components/Label';

const CheckboxField = observer(class CheckboxField extends React.Component {
	render() {
		const { props } = this;
		const { field } = props;
		const { reactProps } = field.field;
		const className = reactProps?.className;

		if (!isEmpty(className) && !((/checkbox-field-group/)).test(reactProps.className)) {
			reactProps.className += ' checkbox-field-group';
		}

		const { key: divKey, ...divReactProps } = reactProps;

		return (
			<div key={divKey} {...divReactProps}>
				{props.beforeLabel}
				{props.beforeControl}
				<Input {...props} />
				<Label {...props} />
				{props.afterLabel}
				{props.afterControl}
			</div>
		);
	}
});

export { CheckboxField };
