import { action, observable, makeObservable } from 'mobx';
import buildMediaQuery from 'tailwindcss/lib/util/buildMediaQuery';
import { normalizeScreens } from 'tailwindcss/lib/util/normalizeScreens';

import { isOnServer } from '~/global/global.constants';
import themeConfig from '~/lib/tailwind.config.json';

class PageStore {
	isLoading = undefined;

	isMediaQueryMd = undefined;

	matchMdMq;

	pagePromise;

	pageProps;

	constructor() {
		makeObservable(this, {
			isLoading: observable,
			isMediaQueryMd: observable,
			createMediaQueryListeners: action.bound,
			setIsLoading: action,
			setIsMediaQueryMd: action.bound,
		});
	}

	createMediaQueryListeners() {
		if (isOnServer) {
			return;
		}
		const screens = normalizeScreens(themeConfig.theme.screens);
		const screenDefinition = screens.find(({ name }) => name === 'md');
		const mdMediaQueryStr = buildMediaQuery(screenDefinition);

		this.matchMdMq = window.matchMedia(mdMediaQueryStr);
		this.setIsMediaQueryMd(this.matchMdMq);
		this.matchMdMq.addListener(this.setIsMediaQueryMd);
	}

	setIsLoading(isLoading) {
		this.isLoading = isLoading;
	}

	setIsMediaQueryMd(mql) {
		this.isMediaQueryMd = mql.matches;
	}
}

export const PageStoreFactory = {
	create: ({
		pageProps = {},
	} = {}) => {
		const { pagePromise = {} } = pageProps;
		const isLoading = typeof pagePromise.then === 'function';
		const pageStore = new PageStore();

		Object.assign(pageStore, {
			isLoading,
			pagePromise,
			pageProps,
		});

		if (!isOnServer) {
			pageStore.createMediaQueryListeners();
		}
		return pageStore;
	},
	destroy() {
		this.matchMdMq.removeListener(this.setIsMediaQueryMd);
	},
};
