import React from 'react';
import { observer } from 'mobx-react';

import { Label } from '~/util/formz/Components/Label';
import { Input } from '~/util/formz/Components/controls/Input';
import { Validation } from '~/util/formz/Components/Validation';

const EmailField = observer(class EmailField extends React.Component {
	render() {
		const { props } = this;
		const {
			field,
			field: {
				control: {
					reactProps: inputProps = {}
				} = {},
				field: {
					reactProps: divProps = {}
				} = {},
			} = {},
		} = props;

		const {
			key: labelKey,
			...labelProps
		} = props;

		const {
			key: inputKey,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			...restOfInputProps
		} = inputProps;

		const {
			key: divKey,
			...restOfDivProps
		} = divProps;

		if (props.reverseOrder) {
			return (
				<div key={divKey} { ...restOfDivProps }>
					{props.beforeControl}
					<Input key={inputKey} {...labelProps} />
					{props.afterControl}
					{props.beforeLabel}
					<Label key={labelKey} {...labelProps} />
					{props.afterLabel}
					<Validation {...props} />
					{props.afterValidation}
				</div>
			);
		}
		return (
			<div key={divKey} { ...restOfDivProps }>
				{props.beforeControl}
				{props.beforeLabel}
				<Label key={labelKey} {...labelProps} />
				{props.afterLabel}
				<Input key={inputKey} {...labelProps} />
				{props.afterControl}
				<Validation {...props} />
				{props.afterValidation}
			</div>
		);
	}
});

export { EmailField };
