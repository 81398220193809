import React from 'react';

import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

import styles from '#/layout/email-signup.module.scss';

const formModel = (settings = {}) => {
	return {
		name: '',
		email: '',
		phone: '',
		yourmessage: '',
		sendCopy: true,
		inquiryType: 'generalInquiry',
		emailList: false,
		// hidden
		sourceKey: 'CONTACT_US',
		...settings,
	};
};

const formSettings = (submitHandler, processForm, viewState, isModal) => {
	const id = 'contact-us-form';
	const obj = {
		id,
		reactProps: {
			'method': 'POST',
			'className': '',
			'data-tr-link-event-comp-name': isModal ? 'were here to help' : 'email us',
			'data-tr-link-event-comp-type': isModal ? 'modal' : 'form',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler(form) {
							return submitHandler(form);
						},
					},
				}),
			],
		},
		fields: {
			name: {
				label: {
					reactProps: {
						children: 'Name',
					},
				},
				control: {
					reactProps: {
						type: 'text',
						required: true,
						maxLength: 100,
						autoComplete: 'name',
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Enter your Name',
						},
					},
				},
			},
			email: {
				label: {
					reactProps: {
						children: 'Email Address',
					},
				},
				control: {
					reactProps: {
						type: 'email',
						autoComplete: 'email',
						required: true,
						maxLength: 250,
						className: styles['email-control'],
					},
				},
				settings: {
					renderTypeOverride: 'EmailFieldRenderer',
					validationConstraints: {
						presence: {
							message: '^Enter your Email Address',
						},
						email: {
							message: '^Please enter a valid email address.',
						},
						length: {
							maximum: 241,
						},
					},
				},
			},
			phone: {
				label: {
					reactProps: {
						children: 'Phone (optional)',
					},
				},
				control: {
					reactProps: {
						type: 'text',
						autoComplete: 'phone',
					},
				},
				settings: {
					plugins: [{
						pluginId: 'maskJQ',
						settings: {
							mask: '999-999-9999',
							pattern: '[0-9]*',
						},
					}],
					validationConstraints: {
						length: {
							is: 12,
							message: '^Enter a valid phone number.',
						},
						presence: false,
					},
					autoClear: true,
				},
			},
			yourmessage: {
				label: {
					reactProps: {
						children: 'Your Message (optional)',
					},
				},
				control: {
					reactProps: {
						maxLength: 2000,
					},
				},
				settings: {
					plugins: [{ pluginId: 'maxlengthRemainingCountdown' }],
				},
			},
			sendCopy: {
				label: {
					reactProps: {
						className: 'tw-font-normal',
						children: 'Send me a copy of this email.',
					},
				},
				control: {
					reactProps: {
						type: 'checkbox',
					},
				},
			},
			emailList: {
				label: {
					reactProps: {
						children: (
							<>
								{'Please send me email updates about products and special events. We will not share, sell or trade your email. '}
								<a href="/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
							</>
						),
					},
				},
				control: {
					reactProps: {
						type: 'checkbox',
					},
				},
			},
			sourceKey: {
				reactProps: {
					style: { display: 'none' },
				},
				control: {
					reactProps: { type: 'hidden' },
				},
			},
		},
	};

	obj.fields.inquiryType = {
		label: {
			reactProps: {
				children: 'Subject',
			},
		},
		control: {
			reactProps: {
				'type': 'select',
				'data-tr-link-event-track': false,
			},
		},
	};

	return obj;
};

export {
	formModel,
	formSettings,
};
