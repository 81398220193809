import React from 'react';
import { observer } from 'mobx-react';

const Mask = observer(class Mask extends React.Component {
	componentDidMount() {
		this.props.field.plugins.maskJQ.applyMaskPlugin();
	}

	componentWillUnmount() {
		this.props.field.plugins.maskJQ.removeMask();
	}

	render() {
		const {
			props: {
				field: {
					plugins: {
						maskJQ: {
							jqMaskReactProps,
						},
					},
				},
			},
		} = this;
		const {
			key,
			...props
		} = jqMaskReactProps;

		return <input {...props} />;
	}
});

export { Mask };
