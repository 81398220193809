import React from 'react';
import { observer } from 'mobx-react';

import { getAccessibleReactProps } from '~/util/formz/util/util';

const Input = observer(class Input extends React.Component {
	render() {
		const reactProps = getAccessibleReactProps(this.props);

		const {
			key,
			...props
		} = reactProps;

		return <input key={key} {...props} />;
	}
});

export { Input };
