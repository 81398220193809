import React from 'react';

interface IProps {
	preferencesTypeText: string;
	preferencesDescriptionText: string;
}

export const EmailPreferencesRadioLabel = (props: IProps) => {
	return (
		<span className={'tw-font-semibold'}>
			{props.preferencesTypeText}. <span className={'tw-font-normal'}> {props.preferencesDescriptionText}.</span>
		</span>
	);
};
