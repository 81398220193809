import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { Spinner } from '~/components/magic-overlay/Components/Spinner';
import { OverlayMessage } from '~/components/magic-overlay/Components/OverlayMessage';

export const MagicOverlay = observer(class MagicOverlay extends React.Component {
	constructor(props) {
		super(props);
		this.rootRef = React.createRef();
		this.spinnerRef = React.createRef();
		this.messageRef = React.createRef();
		this.transitionDivRef = React.createRef();
		this.state = {
			mounted: true,
		};
	}

	mountedTimeout;

	componentDidMount() {
		const { containerSelector } = this.props;
		// Grab the container that will be used to calculate positioning if provided.
		// Otherwise, just grab the immediate parent.
		this.props.store.model.setContainerElem(containerSelector ? document.querySelector(containerSelector) : this.rootRef?.current?.parentNode);
		this.props.store.model.setSpinnerElem(this.spinnerRef?.current?.elem);
		this.props.store.model.setMessageElem(this.messageRef?.current?.elem);
	}

	render() {
		const shouldTransition = (
			this.props.store.model.shouldShowOverlay
			|| this.props.store.model.shouldShowSpinner
			|| this.props.store.model.shouldShowMessage
		);

		return (
			<div
				suppressHydrationWarning
				data-qa="magic-overlay"
				className={cn({
					'MagicOverlay': !this.props.store.model.useOpaqueFrosty,
					'MagicOverlay--opaqueFrosty': this.props.store.model.useOpaqueFrosty,
					'is-global': this.props.store.model.isPositionGlobal,
				})}
				ref={this.rootRef}
			>
				<CSSTransition
					timeout={{
						enter: 200,
						exit: 0,
					}}
					classNames="MagicOverlayFade"
					data-qa="magic-overlay-fade"
					in={shouldTransition}
					nodeRef={this.transitionDivRef}
					mountOnEnter={true}
					unmountOnExit={false}
					onEnter={() => this.setState({ mounted: true })}
					onExited={() => {
						// These values are based on the CSS animation.
						const transitionSpeed = 200;
						const transitionDelay = 500;
						// The frosty will go away sooner, but the DOM will be intact as it completes transition.
						const delay = (transitionSpeed + transitionDelay);

						clearTimeout(this.mountedTimeout);
						this.mountedTimeout = setTimeout(() => this.setState({ mounted: false }), delay);
					}}
				>
					<div ref={this.transitionDivRef}>
						<div
							className={cn('MagicOverlay-frosty', {
								'is-global': this.props.store.model.isPositionGlobal,
								'tw-hidden': !this.state.mounted,
							})}
							key="MagicOverlay-frosty"
						/>
						{
							this.state.mounted &&
							<div className="MagicOverlay-spinnerContainer" key="MagicOverlay-spinnerContainer">
								<Spinner
									className={this.props.store.model.spinnerClassName}
									key="Spinner"
									ref={this.spinnerRef}
									store={this.props.store}
								/>
								<OverlayMessage
									className={this.props.store.model.messageClassName}
									key="OverlayMessage"
									ref={this.messageRef}
									store={this.props.store}
								/>
							</div>
						}
					</div>
				</CSSTransition>
			</div>
		);
	}
});
