import { isEmpty } from '~/util/isEmpty';
import { getMessageText } from '~/lib/validatejs/custom-validators/getMessageText';

export const dependentLength = (value, optionsArray, key, attributes) => {
	let validationMessage = null;
	const modelObject = {};
	const validationSettingsObject = {};
	const theOptions = !Array.isArray(optionsArray) ? [optionsArray] : optionsArray;

	theOptions.forEach((options) => {
		const {
			dependentModelKey,
			dependentModelValue: testModelValue,
		} = options;
		const dependentModelValue = attributes[dependentModelKey];
		let shouldValidate = false;

		if (isEmpty(testModelValue)) {
			// we aren't checking for a specific testModelValue, just presence
			shouldValidate = !isEmpty(dependentModelValue);
		} else if (testModelValue === dependentModelValue) {
			// check the testModelValue for a specific value
			shouldValidate = true;
		}
		if (shouldValidate) {
			modelObject[key] = value;
			if (options.message) {
				validationSettingsObject[key] = {
					length: {
						is: options.is,
						message: options.message,
					},
				};
			} else {
				validationSettingsObject[key] = {
					length: {
						is: options.is,
					},
				};
			}
			validationMessage = getMessageText(modelObject, validationSettingsObject, key);
			validationMessage = validationMessage && `^${validationMessage}`;
		}
	});
	return validationMessage;
};
