import type { ISiteMarketingMessageData } from '~/site-marketing-messaging/Types/SiteMarketingMessage.types';
import { TEMPLATE } from '~/site-marketing-messaging/SiteMarketingMessaging.constants';

export class SiteMarketingMessage {
	_startDate = '';

	displayOn = '';

	message = '';

	name = '';

	template = TEMPLATE.BANNER_BLACK;

	constructor({
		displayOn,
		message,
		name,
		startDate,
		template,
	}: ISiteMarketingMessageData) {
		this._startDate = startDate;
		this.displayOn = displayOn;
		this.name = name;
		this.message = message;
		this.template = template;
	}

	static create(siteMarketingMessageData: ISiteMarketingMessageData) {
		return new SiteMarketingMessage(siteMarketingMessageData);
	}

	get startDate() {
		const startDate = new Date(this._startDate);

		return new Intl.DateTimeFormat('en-US', {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric',
		}).format(startDate).replaceAll('/', '');
	}
}
