import { emailSignUpSources } from '~/email-signup/emailSignUp.constants';
import { isMobile } from '~/global/global.constants';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

import stylesEmail from '#/layout/email-signup.module.scss';

const formModel = (settings = {}) => {
	return {
		email: '',
		source: emailSignUpSources.DEFAULT,
		isMobile,
		...settings,
	};
};

const formSettings = (submitHandler, processForm, locationId, hideLabel) => {
	return {
		id: `email-signup-form-${locationId}`,
		reactProps: {
			className: `${stylesEmail['email-signup']} ${stylesEmail['email-signup-form']} email-signup-form-${locationId}`,
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler: () => submitHandler(),
						promiseHandler: promise => processForm(promise),
					},
				}),
			],
		},
		fields: {
			email: {
				label: {
					reactProps: {
						children: 'Email Address',
						className: hideLabel && 'tw-sr-only',
					},
				},
				control: {
					reactProps: {
						type: 'email',
						autoComplete: 'email',
						required: true,
						maxLength: 250,
						placeholder: 'Type your email',
						className: stylesEmail['email-control'],
					},
				},
				settings: {
					renderTypeOverride: 'EmailFieldRenderer',
					validationConstraints: {
						presence: {
							message: '^Enter your Email Address',
						},
						email: {
							message: '^Please enter a valid email address.',
						},
						length: {
							maximum: 241,
						},
					},
				},
			},
			source: {
				reactProps: {
					style: { display: 'none' },
				},
				control: {
					reactProps: { type: 'hidden' },
				},
			},
			isMobile: {
				reactProps: {
					style: { display: 'none' },
				},
				control: {
					reactProps: { type: 'hidden' },
				},
			},
		},
	};
};

export {
	formModel,
	formSettings,
};
