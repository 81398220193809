import {
	action, observable, makeObservable,
} from 'mobx';
import axios from 'axios';

import { formModel, formSettings } from '~/contact-us/contactUs-settings';
import { FormSubmitEventTrackingStore } from '~/tracking/form-submit-event/Stores/FormSubmitEvent.tracking.store';
import { MagicSpinner } from '~/components/MagicSpinner';
import { ContactUsForm } from '~/contact-us/Components/ContactUsForm';
import { ContactUsSuccess } from '~/contact-us/Components/ContactUsSuccess';
import { ContactUsError } from '~/contact-us/Components/ContactUsError';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { apiUrl } from '~/global/global.constants';
import { EmailSubscriptionError } from '~/email-subscription/Types/EmailSubscription.constants';

export class ContactUsFormStore {
	contactUsResults = null;

	showLoadingSpinner = false;

	subjects = undefined;

	mappedSubjects = undefined;

	contactUsFormSuccessCallback = null;

	form = null;

	magicSpinnerProps = null;

	viewState = null;

	viewStateDisposer = null;

	constructor() {
		makeObservable(this, {
			contactUsResults: observable,
			showLoadingSpinner: observable,
			subjects: observable,
			mappedSubjects: observable,
			loadSubjects: action,
			mapSubjectsToFormOptions: action.bound,
			submitHandler: action.bound,
			processForm: action.bound,
			onProcessFormSuccess: action.bound,
			onProcessFormFailure: action.bound,
		});
	}

	loadSubjects() {
		axios.get(`${apiUrl}/api/web/contact-us/subjects`)
			.then((response) => {
				this.subjects = response.data;
				this.mapSubjectsToFormOptions(this.subjects);
			})
			.catch((error) => {
				console.log('error loading subjects', error.message);
			});
	}

	init(isModal = false) {
		this.form = new FormBuilder(
			formModel(),
			formSettings(this.submitHandler, this.processForm, this.viewState, isModal),
		);
		this.loadSubjects();
		this.viewState.goTo('form');
	}

	mapSubjectsToFormOptions(subjects) {
		this.mappedSubjects.replace(subjects.map((subject) => {
			const {
				label: text,
				name: value,
			} = subject.inquiryType;
			return { text, value };
		}));
		if (this.mappedSubjects.length) {
			this.form.model.inquiryType = this.mappedSubjects[0].value;
		}
	}

	submitHandler() {
		const {
			plugins: {
				formValidator,
				formValidator: { hasErrors },
			},
		} = this.form;

		formValidator.validateForm();

		if (!hasErrors) {
			return this.processForm(this.form);
		}

		return false;
	}

	processForm() {
		// console.info('process ', toJS(this.form.model));
		return axios.post(`${apiUrl}/api/web/contact-us`, this.form.model)
			.then(this.onProcessFormSuccess)
			.catch((error) => {
				this.onProcessFormFailure(error);
			});
	}

	onProcessFormSuccess(resp) {
		this.trackFormSubmission();
		this.viewState.goTo('success');
		return resp;
	}

	onProcessFormFailure(error) {
		const errors = error?.response?.data?.errors || [];
		const isInvalidEmailError = errors.some(formError => formError?.errorKey === EmailSubscriptionError.InvalidEmail);

		if (isInvalidEmailError) {
			this.viewState.goTo('error', { error: { message: 'Please enter a valid email address' } });
		} else {
			this.viewState.goTo('error', { error });
		}

		return error;
	}

	trackFormSubmission() {
		const formSubmitTracking = new FormSubmitEventTrackingStore();
		formSubmitTracking.trackFormSubmit(this.form.id.value_);
	}
}

export const ContactUsFormStoreFactory = {
	create: ({
		contactUsFormSuccessCallback,
		magicSpinnerProps = { isLoading: true },
		subjects,
	} = {}) => {
		const contactUsFormStore = new ContactUsFormStore();

		contactUsFormStore.viewState = new ViewStateStore(contactUsFormStore, {
			loading: {
				component: MagicSpinner,
				onEnter: () => {
					return contactUsFormStore.magicSpinnerProps;
				},
			},
			form: {
				component: ContactUsForm,
				onEnter: () => {
					return { contactUsFormStore };
				},
			},
			success: {
				component: ContactUsSuccess,
				onEnter: () => {
					return { contactUsFormStore };
				},
			},
			error: {
				component: ContactUsError,
				onEnter: () => {
					return { contactUsFormStore };
				},
			},
		});

		Object.assign(contactUsFormStore, {
			contactUsFormSuccessCallback,
			magicSpinnerProps,
		});
		contactUsFormStore.subjects = subjects;
		contactUsFormStore.mappedSubjects = [];
		contactUsFormStore.viewState.goTo('loading');
		// global.store = contactUsFormStore;
		return contactUsFormStore;
	},
};
