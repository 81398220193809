// Prevents key prop from being spread into React props.
// @example const [propsWithoutKey, key] = useSafeSpread(reactProps)
export const useSafeSpread = <TProps extends Record<string, any>>(props: TProps | null): [Omit<TProps, 'key'> | Record<string, never>, string?] => {
	if (!props) {
		return [{}, undefined];
	}
	if (!('key' in props)) {
		return [props];
	}
	const {
		key,
		...propsWithoutKey
	} = props;

	return [propsWithoutKey, key];
};
