import {
	model, Model, idProp,
} from 'mobx-keystone';
import DOMPurify from 'dompurify';

import {
	type SiteMessageTypeKey, type LocationKey, SiteMessageModel, type SiteMessageData,
} from '~/global/site-messaging/SiteMessage.model';
import { modelNamespace } from '~/util/modelNamespace';
import { isOnServer } from '~/global/global.constants';
import { uniqBy } from '~/util/uniqBy';

@model(`${modelNamespace.SITE_MESSAGING}/SiteMessagingStore`)
export class SiteMessagingStore extends Model({
	id: idProp,
}) {
	globalDynamic: any;

	get hasOutageMessage() {
		return this.siteMessages.some(message => message.type === 'OUTAGE');
	}

	get isOnAccountPage() {
		if (isOnServer) {
			return false;
		}
		return window.location.pathname.startsWith('/account');
	}

	get isOnCartPage() {
		if (isOnServer) {
			return false;
		}
		return window.location.pathname.startsWith('/cart');
	}

	get isOnCheckoutPage() {
		if (isOnServer) {
			return false;
		}
		const {
			location: {
				pathname,
			},
		} = window;

		return pathname.startsWith('/checkout') || pathname.startsWith('/add-to-delivery');
	}

	get isOnClearancePage() {
		if (isOnServer) {
			return false;
		}
		const {
			location: {
				pathname,
			},
		} = window;

		return pathname.startsWith('/clearance');
	}

	get leadingSiteMessages() {
		return this.locationSpecificSiteMessages.filter((siteMessage) => {
			const allowedTypes: SiteMessageTypeKey[] = ['OUTAGE', 'PROMOTION', 'CLEARANCE', 'WARNING'];

			return allowedTypes.includes(siteMessage.type);
		});
	}

	get locationSpecificSiteMessages(): SiteMessageModel[] {
		let allowedLocations: LocationKey[];
		let result: SiteMessageModel[] = this.siteMessages;

		if (this.isOnAccountPage) {
			allowedLocations = ['SITEWIDE', 'ACCOUNT'];
		} else if (this.isOnCartPage) {
			allowedLocations = ['SITEWIDE', 'CART'];
		} else if (this.isOnCheckoutPage) {
			allowedLocations = ['SITEWIDE', 'CHECKOUT'];
		} else if (this.isOnClearancePage) {
			allowedLocations = ['SITEWIDE'];
			result = result.filter(msg => msg.type !== 'CLEARANCE');
		} else {
			allowedLocations = ['SITEWIDE'];
		}
		result = result.filter(msg => msg.locations.some(loc => allowedLocations.includes(loc)));
		return uniqBy(result, 'name');
	}

	get siteMessages(): SiteMessageModel[] {
		if (!this.globalDynamic?.siteMessages) {
			return [];
		}
		return this.globalDynamic.siteMessages.map((siteMessage: SiteMessageData) => {
			return new SiteMessageModel({
				name: siteMessage.name,
				locations: siteMessage.locations,
				message: isOnServer ? siteMessage.message : DOMPurify.sanitize(siteMessage.message),
				template: siteMessage.template,
				type: siteMessage.type,
			});
		});
	}
}
