import React from 'react';
import { observer } from 'mobx-react';

import { BaseField } from '~/util/formz/Components/fields/BaseField';
import { Select } from '~/util/formz/Components/controls/Select';

const SelectField = observer(class SelectField extends React.Component {
	render() {
		return (
			<BaseField {...this.props}>
				<Select {...this.props} />
			</BaseField>
		);
	}
});

export { SelectField };
