export const siteMessageTypes = {
	CLEARANCE: 'CLEARANCE',
	HOLIDAY_HOURS: 'HOLIDAY_HOURS',
	OUTAGE: 'OUTAGE',
	PROMOTION: 'PROMOTION',
	WARNING: 'WARNING',
} as const;
const locations = {
	ACCOUNT: 'ACCOUNT',
	CART: 'CART',
	CHECKOUT: 'CHECKOUT',
	SITEWIDE: 'SITEWIDE',
} as const;
const templates = {
	BANNER_GREY: 'BANNER_GREY',
	BANNER_RED: 'BANNER_RED',
	CUSTOM: 'CUSTOM',
} as const;

type Template = typeof templates;
type TemplateKey = keyof Template;
type Location = typeof locations;
export type LocationKey = keyof Location;

type SiteMessageType = typeof siteMessageTypes;
export type SiteMessageTypeKey = keyof SiteMessageType;
export type SiteMessageTemplate = ''

export type SiteMessageData = {
	name: string
	locations: LocationKey[]
	message: string
	template: TemplateKey
	type: SiteMessageTypeKey
};

export class SiteMessageModel {
	constructor(data: SiteMessageData) {
		this.name = data.name;
		this.locations = data.locations;
		this.message = data.message;
		this.template = data.template;
		this.type = data.type;
	}

	name: string;

	locations: LocationKey[];

	message: string;

	template: TemplateKey;

	type: SiteMessageTypeKey;
}
