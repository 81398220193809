import {
	extendObservable, observable, toJS, makeObservable,
} from 'mobx';

import { safeWrapOrCreate } from '~/util/formz/util/util';
import { shouldIgnoreBlurValidation } from '~/util/formz/util/shouldIgnoreBlurValidation';

export class BaseControlBuilder {
	constructor(name, controlData, form) {
		const {
			value,
			className,
			reactProps,
			...omittedControlData
		} = controlData;

		makeObservable(this, {
			controlRef: observable,
		});

		// toJS needed for IE.
		this.inputId = `${name}-control-${toJS(form.id)}`;

		extendObservable(this, {
			...omittedControlData,
			reactPropsMap: new Map(Object.entries({
				name,
				'noValidate': true,
				'data-qa': this.inputId,
				'id': this.inputId,
				'key': this.inputId,
				'className': this.inputId,
				...reactProps,
				'ref': (control) => {
					this.controlRef = control;

					controlData.reactProps.ref?.(control);
				},
			})),
			addOrWrapControlEventHandler: (eventName, functionToExec) => {
				safeWrapOrCreate(this.reactPropsMap, eventName, (event) => {
					if (shouldIgnoreBlurValidation(event)) {
						return;
					}
					functionToExec(event, {
						form,
						name,
						control: this,
					});
				});
			},
		});
	}

	get value() {
		return this.form.model[this.name];
	}

	inputId = ''

	controlRef = null;
}
