import { extendObservable } from 'mobx';

import { formModel, formSettings } from '~/account/sign-in/Models/signIn.model';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';

const SignInStore = (callback, contextImports, defaults, formSettingsOverrides = {}, oneTrustStore = {}) => {
	const loginStore = extendObservable({}, {
		// [mobx-upgrade] Having as observable ref was causing runtime errors. Seems to not be necessary.
		// form: observable.ref,
		hasSubmitError: false,
		isSubmitProcessing: false,
		isSubmitted: false,
		isAuthenticated: false,
		isInModal: defaults && defaults.isInModal,
		callback,
		oneTrustStore,
	});

	loginStore.contextImports = contextImports;
	loginStore.defaults = defaults;

	loginStore.form = new FormBuilder(
		formModel(),
		{
			...formSettings(loginStore, contextImports),
			...formSettingsOverrides,
		},
	);

	loginStore.oneTrustStore = oneTrustStore;

	return loginStore;
};

export { SignInStore };
