import { action, when, makeObservable } from 'mobx';

import { MagicOverlayModel } from '~/components/magic-overlay/Models/MagicOverlay.model';
import { isOnServer } from '~/global/global.constants';

export class MagicOverlayStore {
	constructor() {
		makeObservable(this, {
			destroy: action.bound,
			startLoading: action.bound,
			stopLoading: action.bound,
			updateContainerHeight: action.bound,
		});
	}

	destroy() {
		this.model.disposers?.forEach?.(interceptor => interceptor());
	}

	model

	startLoading(trigger, options = { containerHeight: null }) {
		// Get the most current container element height to calculate accurate positioning.
		this.updateContainerHeight(options.containerHeight);
		this.model.state = this.model.STATES.LOADING_BUFFER;
		if (typeof trigger === 'undefined') {
			return Promise.resolve();
		}
		this.model.loadingPromise = trigger;
		this.model.loadingPromise.then(resp => resp).catch(resp => resp).then(this.model.loadingPromiseCallback);
		return new Promise((resolve) => {
			when(() => this.model.isIdle, () => resolve());
		});
	}

	stopLoading() {
		this.model.reset();
	}

	updateContainerHeight(height = null) {
		if (height !== null) {
			this.model.containerHeight = height;
		} else if (this.model.containerElem) {
			this.model.containerHeight = this.model.containerElem.offsetHeight;
		} else {
			when(() => typeof this.model.containerHeight === 'undefined' && this.model.containerElem !== null, () => {
				this.model.containerHeight = this.model.containerElem?.offsetHeight || 0;
			});
		}
	}
}

export const MagicOverlayStoreFactory = {
	create(options = {}) {
		if (isOnServer) {
			return null;
		}
		const store = new MagicOverlayStore(options);

		store.model = new MagicOverlayModel(options);
		store.model.disposers = [
			store.model.autoScrollSpinnerIntoView,
			store.model.autoState,
			store.model.stateInterceptor,
			store.model.containerHeightInterceptor,
		];
		// global.store = store;
		return store;
	},
};
