import type { AppConfigPageProps } from '~/global/app-config/AppConfig.types';

export const setCookieHeader = (
	pageProps: AppConfigPageProps | undefined, extraCookies: { [key: string]: string } | {} = {},
) => {
	if (!pageProps) {
		return null;
	}
	const {
		cookies: {
			featureTogglesBrowserId = '',
			SESSION = '',
		} = {},
	} = pageProps;
	const cookieList = {
		featureTogglesBrowserId,
		SESSION,
		...extraCookies,
	};
	const cookieHeader = Object.entries(cookieList).map(([key, val]) => {
		if (!val) {
			return null;
		}
		return `${key}=${val}`;
	}).filter(Boolean);

	if (cookieHeader.length > 0) {
		return {
			'Cookie': cookieHeader.join('; '),
		};
	}
	return null;
};
