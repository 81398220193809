export const sortBy = <T>(array: T[], propertyName: keyof T, direction: 'ASCENDING' | 'DESCENDING' = 'ASCENDING') => {
	try {
		if (!array || !array.length) {
			return [];
		}

		// spread the original array because Array.sort() mutates it
		return [...array].sort((a, b) => {
			if (a[propertyName] > b[propertyName]) {
				return direction === 'ASCENDING' ? 1 : -1;
			}
			if (b[propertyName] > a[propertyName]) {
				return direction === 'ASCENDING' ? -1 : 1;
			}
			return 0;
		});
	} catch (error) {
		console.error('Error sorting array:', error, array);
		return array;
	}
};
