import {
	action, autorun, observe, observable, makeObservable,
} from 'mobx';
import axios from 'axios';

import { apiUrl, isOnServer } from '~/global/global.constants';
import { GlobalDynamic } from '~/global/global-dynamic/Models/GlobalDynamic.model';
import { TransZoneStoreFactory } from '~/global/global-dynamic/Stores/TransZone.store';
import { loggedInAction } from '~/account/sign-in/Actions/loggedIn.action';
import { loggedOutAction } from '~/account/sign-in/Actions/loggedOut.action';
import { getAxios } from '~/global/app-config/ipHeaders';

export class GlobalDynamicStore {
	model = {};

	cookies = {};

	globalStaticModel = undefined;

	transZoneStore = TransZoneStoreFactory.create();

	hasInitiallyLoaded = false;

	observers = undefined;

	clientSideInitialLoadCallbacks = [];

	constructor(model, cookies, globalStaticModel) {
		makeObservable(this, {
			model: observable.ref,
			cookies: observable,
			globalStaticModel: observable,
			hasInitiallyLoaded: observable,
			observers: observable,
			setHasInitiallyLoaded: action.bound,
			fetchData: action.bound,
			processData: action.bound,
			setTransZone: action.bound,
			init: action.bound,
		});

		this.model = model;
		this.cookies = cookies;
		this.globalStaticModel = globalStaticModel;
	}

	registerClientSideInitialLoadCallback(callback) {
		if (this.hasInitiallyLoaded) {
			callback(this.model);
		} else {
			this.clientSideInitialLoadCallbacks.push(callback);
		}
	}

	fetchData(pageProps, ctx) {
		let dynaAxios = axios;
		const headers = {};

		if (pageProps && ctx) {
			const {
				trueClientIpHeader,
				xForwardedForHeader,
				xBypassRatelimitingHeader,
			} = pageProps;

			dynaAxios = getAxios(
				trueClientIpHeader,
				xForwardedForHeader,
				xBypassRatelimitingHeader,
				ctx,
			);
		}
		if (isOnServer) {
			headers.Cookie = `SESSION=${this.cookies.SESSION}; featureTogglesBrowserId=${this.cookies.featureTogglesBrowserId};`;
		}

		return dynaAxios.request({
			url: `${apiUrl}/api/global-dynamic`,
			method: 'get',
			maxRedirects: 0,
			headers,
		}).then((response) => {
			this.processData(response.data);
			if (!this.hasInitiallyLoaded) {
				this.setHasInitiallyLoaded(true);
				let callback = this.clientSideInitialLoadCallbacks.pop();
				while (typeof callback !== 'undefined') {
					callback(this.model);
					callback = this.clientSideInitialLoadCallbacks.pop();
				}
			}
			return this;
		});
	}

	processData(data) {
		this.model.update(data);

		if (this.transZoneStore) {
			this.transZoneStore.model.hasTransZone = this.model.hasTransZone;
		}
	}

	setHasInitiallyLoaded(value) {
		this.hasInitiallyLoaded = value;
	}

	setTransZone(zipCode) {
		if (!this.transZoneStore) {
			return Promise.reject();
		}

		return this.transZoneStore.setTransZone(zipCode).then((response) => {
			this.fetchData();
			return response;
		});
	}

	init() {
		if (!isOnServer) {
			this.fetchData();

			this.observers = [
				observe(this.model.links, (change) => {
					if (!this.hasInitiallyLoaded) {
						return;
					}
					// We don't want to observe initial values loaded from promise, only subsequent changes.
					// Thus, hasLoggedIn is with all intents and purposes, an action.
					if (change.name === 'logout') {
						if (change.type === 'add') {
							loggedInAction.dispatch();
						} else if (change.type === 'delete') {
							loggedOutAction.dispatch();
						}
					} else if (change.name === 'currentTransportationZone') {
						if (change.type === 'add') {
							this.transZoneStore.model.hasTransZone = true;
						} else if (change.type === 'delete') {
							this.transZoneStore.model.hasTransZone = false;
						}

						this.transZoneStore.model.isLoaded = true;
					}
				}),
				autorun(() => {
					this.transZoneStore.model.currentTransZoneLink = this.model.currentTransZoneLink;
					this.transZoneStore.model.setTransZoneLink = this.globalStaticModel.setTransZoneLink;
				}),
			];
		}
	}
}

export const GlobalDynamicFactory = {
	create(cookies, globalStaticModel, rawData = {}, disableGlobalDynamicRequest = false) {
		const store = new GlobalDynamicStore(new GlobalDynamic(rawData), cookies, globalStaticModel);

		if (!disableGlobalDynamicRequest) {
			store.init();
		}

		return store;
	},
	destroy(store) {
		// Dispose of any observers.
		store.observers?.forEach?.(dispose => dispose());
	},
};
