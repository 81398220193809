import {
	model, Model, prop, idProp, modelAction,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';

@model(`${modelNamespace.ACCOUNT_SIGN_IN}/OneTrustStore`)
export class OneTrustStore extends Model({
	id: idProp,
	gpcSignal: prop<boolean | undefined>(false).withSetter(),
}) {
	@modelAction
	handleGPCSignal(hasGpcSignal: boolean) {
		if (hasGpcSignal === this.gpcSignal) {
			return;
		}

		this.setGpcSignal(hasGpcSignal);

		if (typeof window !== 'undefined') {
			const tsWindow: any = window;
			const {
				eventDataLayer = [],
				OneTrust,
			} = tsWindow;

			if (OneTrust && this.gpcSignal) {
				OneTrust?.RejectAll();
			}
			if (OneTrust && !this.gpcSignal) {
				OneTrust?.AllowAll();
			}

			// push the event to the dataLayer to trigger firing rules
			if (OneTrust) {
				eventDataLayer.push({
					event: 'onetrustconsent',
				});
			}
		}
	}
}
