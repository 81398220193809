import { AjaxSubmitPlugin } from '~/util/formz/plugins/AjaxSubmitPlugin';
import { FieldInterceptorPlugin } from '~/util/formz/plugins/FieldInterceptorPlugin';
import { FormValidatorPlugin } from '~/util/formz/plugins/formValidator/FormValidatorPlugin';
import { FormReactionsPlugin } from '~/util/formz/plugins/FormReactionsPlugin';
import { MaskPlugin } from '~/util/formz/plugins/MaskPlugin';
import { MaxLengthRemainingCountdownPlugin } from '~/util/formz/plugins/MaxLengthRemainingCountdownPlugin';
import { NumberPlugin } from '~/util/formz/plugins/NumberPlugin';
import { UsCurrencyPlugin } from '~/util/formz/plugins/UsCurrencyPlugin';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { MultiFieldPlugin } from '~/util/formz/plugins/MultiFieldPlugin';

export const formzPlugins = {
	ajaxSubmit: AjaxSubmitPlugin,
	fieldInterceptor: FieldInterceptorPlugin,
	formReactions: FormReactionsPlugin,
	formValidator: FormValidatorPlugin,
	maskJQ: MaskPlugin,
	maxlengthRemainingCountdown: MaxLengthRemainingCountdownPlugin,
	multiField: MultiFieldPlugin,
	number: NumberPlugin,
	usCurrency: UsCurrencyPlugin,
	validateAndAjaxSubmit: ValidateAndAjaxSubmitPlugin,
};
