import { computed, makeObservable } from 'mobx';
import cn from 'classnames';

import { isNumber } from '~/util/isNumber';

import styles from '#/formz/field.module.scss';

class MaxLengthRemainingCountdownPlugin {
	constructor(name, fieldObs, form) {
		makeObservable(this, {
			remainingCharacterCount: computed,
			classNames: computed,
		});

		this.name = name;
		this.fieldObs = fieldObs;
		this.form = form;

		if (!isNumber(this.fieldObs.control.reactPropsMap.get('maxLength'))) {
			console.error(`Please be sure to set a maxLength on the ${this.name} control.`);
		}
	}

	get remainingCharacterCount() {
		const maxlength = this.fieldObs.control.reactPropsMap.get('maxLength');

		if (isNumber(maxlength) && this.form.model[this.name]) {
			return maxlength - this.form.model[this.name].length;
		}
		return maxlength;
	}

	get classNames() {
		return cn('tw-text-gray-300 text-area-countdown', styles['text-area-countdown'], `text-area-countdown-${this.name}`, {
			'text-area-countdown-space-low': this.remainingCharacterCount <= 0,
		});
	}
}

export { MaxLengthRemainingCountdownPlugin };
