import {
	action, computed, isObservableArray, toJS, makeObservable,
} from 'mobx';

import { BaseControlBuilder } from '~/util/formz/builders/controls/BaseControlBuilder';
import { duplicates } from '~/util/duplicates';

export class SelectControlBuilder extends BaseControlBuilder {
	constructor(name, controlData, form) {
		super(name, controlData, form);

		makeObservable(this, {
			hasDuplicateValues: computed,
			reactProps: computed,
			selectValue: computed,
			selectedOption: computed,
			selectedOptionIndex: computed,
		});

		this.name = name;
		this.controlData = controlData;
		this.form = form;

		// default control events
		this.addOrWrapControlEventHandler('onChange', action(`${this.name} onChange`, (event) => {
			if (isObservableArray(this.value)) {
				// multi select
				const selectedOptionValues = event.target.options.filter(option => option.selected).map(selectedOption => selectedOption.value);

				this.form.updateModelValue(this.name, selectedOptionValues);
			} else {
				// single select
				this.form.updateModelValue(this.name, event.target.value);
			}
		}));
	}

	get hasDuplicateValues() {
		const { controlRef } = this;

		if (!controlRef) {
			return false;
		}
		const optionValues = Array.from(controlRef.options || []).map(option => option.value);

		return Boolean(duplicates(optionValues).length);
	}

	get selectValue() {
		if (isObservableArray(this.value)) {
			return this.value.slice();
		}
		if (this.hasDuplicateValues) {
			console.info(`Formz select field, "${this.reactPropsMap.get('name')}", has options with duplicate values. Therefore, the value of the select field will always be undefined and the select DOM element will no longer be changed by model updates.`);
			return undefined;
		}
		return this.value;
	}

	get selectedOption() {
		const { controlRef } = this;

		if (!controlRef) {
			return {};
		}
		const {
			text = '',
			value = '',
		} = controlRef.options[controlRef.selectedIndex];

		return {
			text,
			value,
		};
	}

	get selectedOptionIndex() {
		const { controlRef } = this;

		if (!controlRef) {
			return -1;
		}
		return controlRef.selectedIndex;
	}

	get reactProps() {
		return {
			value: this.selectValue,
			...Object.fromEntries(toJS(this.reactPropsMap)),
		};
	}

	static BUILDER_KEY = 'select'
}
